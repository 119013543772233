import RootStoreModel from '../_root/RootStoreModel';
import CompaniesApi from 'src/api/CompaniesApi';
import ICompanyDto from 'src/api/_common/dto/company/ICompanyDto';
import CompanyMembersTabViewModel from './tab/CompanyMembersTabViewModel';
import {action, computed, decorate, observable} from 'mobx';
import IUserCompanyDto from 'src/api/_common/dto/company/IUserCompanyDto';
import {IResponse} from 'src/api/ServerApi';
import getFilePath from 'src/utils/FileUtil';
import IEditCompanyRequestDto from 'src/api/CompaniesApi/request/IEditCompanyRequesDtot';
import CompanySettingTabViewModel from './tab/CompanySettingTabViewModel';
import IEditWorkTimeRequestDto from 'src/api/_common/request/IEditWorkTimeRequestDto';
import CompanyServiceFieldTabViewModel from "./tab/CompanyServiceFieldTabViewModel";
import CompanyReviewTabViewModel from "./tab/CompanyReviewViewModel";

class CompanyProfileStore extends RootStoreModel {
  loadingError = null;

  company: ICompanyDto;
  canEdit = false;

  members: CompanyMembersTabViewModel;
  settings = new CompanySettingTabViewModel();
  serviceField: CompanyServiceFieldTabViewModel;
  review: CompanyReviewTabViewModel;

  get background(): string {
    return getFilePath(this.company && this.company.background);
  }
  get icon(): string {
    return getFilePath(this.company && this.company.icon);
  }

  initialize = async (id: number): Promise<void> => {
    const userCompaniesRes = await CompaniesApi.getCompanyWithMember(id);
    if (!userCompaniesRes.success) {
      this.loadingError = 'Компания не найдена';
      return;
    }
    if ((userCompaniesRes.data as IUserCompanyDto).company) {
      const data = userCompaniesRes.data as IUserCompanyDto;
      this.company = data.company;
      this.canEdit = data.role === 'ADMIN';
    } else {
      this.company = userCompaniesRes.data as ICompanyDto;
      this.canEdit = false;
    }
    this.loadingError = null;
    this.members = new CompanyMembersTabViewModel(this.root, this.company.id);
    if (this.root().sessionStore.isSysAdmin) {
      this.serviceField = new CompanyServiceFieldTabViewModel(this.root, this.company.id);
    }
    this.review = new CompanyReviewTabViewModel(this.root, this.company.id);
  };

  editBackground = async (file: File): Promise<IResponse> => {
    const id = this.company.id;
    const res = await CompaniesApi.changeCompanyBackground(
      this.company.id,
      file
    );
    if (res.success && id === this.company.id) {
      this.company.background = res.data;
    }
    return res;
  };

  editIcon = async (file: File): Promise<IResponse> => {
    const id = this.company.id;
    const res = await CompaniesApi.changeCompanyIcon(this.company.id, file);
    if (res.success && id === this.company.id) {
      this.company.icon = res.data;
      const { company, updateCompany } = this.getRootStore().sessionStore;
      if (company && company.id === id) updateCompany(this.company);
    }
    return res;
  };

  editDescription = async (description: string): Promise<IResponse> => {
    const id = this.company.id;
    const res = await CompaniesApi.changeCompanyDescription(id, description);
    if (res.success && id === this.company.id) {
      this.company.description = res.data.description;
    }
    return res;
  };

  editCompany = async (body: IEditCompanyRequestDto): Promise<IResponse> => {
    const id = this.company.id;
    const res = await CompaniesApi.changeCompany(id, body);
    if (res.success && id === this.company.id) {
      this.company = res.data;
      const { company, updateCompany } = this.getRootStore().sessionStore;
      if (company && company.id === id) updateCompany(this.company);
    }
    return res;
  };

  editCompanyWorkTime = async (
    body: IEditWorkTimeRequestDto
  ): Promise<IResponse> => {
    const id = this.company.id;
    const res = await CompaniesApi.changeCompanyWorkTime(id, body);
    if (res.success && id === this.company.id) {
      this.company = res.data;
    }
    return res;
  };

  removeMember = async (memberId: number): Promise<IResponse> => {
    const res = await this.members.removeMember(this.company.id, memberId);
    if (res.success) {
      this.company = res.data;
    }
    return res;
  };

  setMemberAsContact = async (memberId: number, newVal: boolean): Promise<IResponse> =>  {
    const res = await CompaniesApi.setMemberAsContact(this.company.id, memberId, newVal);
    if (res.success) {
      if (newVal) {
        const usr = this.members.users.filter(x => x.id === memberId)[0];
        if (usr) {
          this.company.contactPerson = {id: null, company: null, user: usr.user, role: usr.role, status: usr.status};
        } else {
          this.company.contactPerson = null;
        }
      } else {
        this.company.contactPerson = null;
      }
    }
    return res;
  }

  get contactPersonId(): number {
    return this.company && this.company.contactPerson && this.company.contactPerson.user && this.company.contactPerson.user.id;
  }
}

decorate(CompanyProfileStore, {
  company: observable,
  members: observable,
  background: computed,
  icon: computed,
  contactPersonId: computed,
  editBackground: action,
  editIcon: action,
  editDescription: action,
  editCompany: action,
  editCompanyWorkTime: action,
  setMemberAsContact: action,
});

export default CompanyProfileStore;
