import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {observer} from "mobx-react";
import useStore from "../../context/RootStoreContext";
import LoadingScreen from "../../components/LoadingScreen";

function InfoDialog() {
  const infoModalStore = useStore().infoModalStore;
  const {open, title, body, url, isLoading, htmlContent} = infoModalStore;

  const handleClose = () => {
    infoModalStore.closeModal();
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
        <Dialog
            maxWidth={"lg"}
            open={open}
            onClose={handleClose}
            scroll={'paper'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
            >
              {body && <div>{body}</div>}
              {htmlContent && (
                  <>
                    {isLoading ?
                        (<LoadingScreen/>)
                        :
                        (<div dangerouslySetInnerHTML={{__html: htmlContent}}/>)
                    }
                  </>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              Закрыть
            </Button>
          </DialogActions>
        </Dialog>
  );
}

export default observer(InfoDialog);