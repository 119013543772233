import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  TextField,
  Typography,
  Link,
  makeStyles,
  Grid
} from '@material-ui/core';
import ISignUpPartnerRequestDto from 'src/api/SignInApi/request/ISignUpPartnerRequestDto';
import SignInApi from 'src/api/SignInApi';
import useStore from '../../../context/RootStoreContext';
import InputMask from 'react-input-mask';
import DictionaryStore from 'src/store/DictionaryStore';

const useStyles = makeStyles(() => ({
  root: {}
}));

interface IRegisterFormProps {
  onSubmitSuccess: () => void;
  className?: string;
  [key: string]: any;
}

interface IFormikRegisterFormValues {
  firstName: string;
  secondName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  inn: string;
  ogrn: string;
  ownershipType: number;
  isAgree: boolean;
  submit?: string;
}

const RegisterForm: FunctionComponent<IRegisterFormProps> = ({
  className,
  onSubmitSuccess,
  ...rest
}) => {
  const classes = useStyles();
  const { infoModalStore } = useStore();

  return (
    <Formik<IFormikRegisterFormValues>
      initialValues={{
        firstName: '',
        secondName: '',
        lastName: '',
        email: '',
        phone: '',
        companyName: '',
        inn: '',
        ogrn: '',
        ownershipType: DictionaryStore.ownershipTypes[0].id,
        isAgree: false
      }}
      validationSchema={null}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const body: ISignUpPartnerRequestDto = {
          user: {
            firstName: values.firstName,
            secondName: values.secondName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone
          },
          company: {
            companyName: values.companyName,
            inn: values.inn,
            ogrn: values.ogrn,
            ownershipType: { id: values.ownershipType }
          },
          isAgree: values.isAgree
        };
        const res = await SignInApi.signUpPartner(body);
        if (res.success) {
          setErrors({});
          onSubmitSuccess();
        } else {
          setStatus({ success: false });
          setErrors(res.details || { submit: 'Не удалось создать юзера' });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        values
      }) => {
        return (
          <form
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
          >
            <Box my={2}>
              <Box mb={1}>
                <Typography variant="subtitle1" color="textPrimary">
                  Контактное лицо
                </Typography>
              </Box>
              <TextField
                error={
                  Boolean(errors['user.lastName']) || Boolean(errors.lastName)
                }
                fullWidth
                helperText={errors['user.lastName'] || errors.lastName}
                label="Фамилия"
                margin="normal"
                name="lastName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                variant="outlined"
              />
              <TextField
                error={
                  Boolean(errors['user.firstName']) || Boolean(errors.lastName)
                }
                fullWidth
                helperText={errors['user.firstName'] || errors.lastName}
                label="Имя"
                margin="normal"
                name="firstName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                variant="outlined"
              />
              <TextField
                error={
                  Boolean(errors['user.secondName']) ||
                  Boolean(errors.secondName)
                }
                fullWidth
                helperText={errors['user.secondName'] || errors.secondName}
                label="Отчество"
                margin="normal"
                name="secondName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.secondName}
                variant="outlined"
              />
              <TextField
                error={Boolean(errors['user.email']) || Boolean(errors.email)}
                fullWidth
                helperText={errors['user.email'] || errors.email}
                label="Email"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
              <InputMask
                mask="+7 (999) 999-99-99"
                value={values.phone}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {() => (
                  <TextField
                    error={
                      Boolean(errors['user.phone']) || Boolean(errors.phone)
                    }
                    fullWidth
                    helperText={errors['user.phone'] || errors.phone}
                    label="Телефон"
                    margin="normal"
                    name="phone"
                    variant="outlined"
                  />
                )}
              </InputMask>{' '}
              <Box my={1}>
                <Typography variant="subtitle1" color="textPrimary">
                  Ваша компания
                </Typography>
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <TextField
                      error={
                        Boolean(errors['company.ownershipType']) ||
                        Boolean(errors.ownershipType)
                      }
                      fullWidth
                      helperText={
                        errors['company.ownershipType'] || errors.ownershipType
                      }
                      label="Форма собственности"
                      name="ownershipType"
                      onChange={handleChange}
                      required
                      value={values.ownershipType}
                      variant="outlined"
                      select
                      SelectProps={{ native: true }}
                      margin="normal"
                  >
                    {DictionaryStore.ownershipTypes.map(x => (
                        <option key={x.id} value={x.id}>
                          {x.name}
                        </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                      error={
                        Boolean(errors['company.companyName']) ||
                        Boolean(errors.companyName)
                      }
                      fullWidth
                      helperText={errors['company.companyName'] || errors.companyName}
                      label="Наименование компании"
                      margin="normal"
                      name="companyName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.companyName}
                      variant="outlined"
                  />
                </Grid>
              </Grid>

              <InputMask
                mask="999999999999"
                value={values.inn}
                maskChar=""
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {() => (
                  <TextField
                    fullWidth
                    error={
                      Boolean(errors['company.inn']) || Boolean(errors.inn)
                    }
                    label="ИНН"
                    name="inn"
                    margin="normal"
                    variant="outlined"
                    helperText={errors['company.inn'] || errors.inn}
                  />
                )}
              </InputMask>
              <InputMask
                mask="999999999999999"
                value={values.ogrn}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                maskChar=""
              >
                {() => (
                  <TextField
                    error={
                      Boolean(errors['company.ogrn']) || Boolean(errors.ogrn)
                    }
                    fullWidth
                    helperText={errors['company.ogrn'] || errors.ogrn}
                    label="ОГРН"
                    margin="normal"
                    name="ogrn"
                    variant="outlined"
                  />
                )}
              </InputMask>
            </Box>

            <Box alignItems="center" display="flex" mt={2} ml={-1}>
              <Checkbox
                checked={values.isAgree}
                name="isAgree"
                onChange={handleChange}
              />
              <Typography variant="body2" color="textSecondary">
                <Grid container alignItems={'center'} spacing={0}>
                  <Grid item xs={4}>
                    Я согласен с условиями{' '}
                  </Grid>
                  <Grid item xs={8}>
                    <Link
                      component="a"
                      href="#"
                      color="secondary"
                      onClick={e => {
                        e.preventDefault();
                        infoModalStore.openModalUrl(
                          'Пользовательское соглашение',
                          '/files/agreement.html'
                        );
                      }}
                    >
                      Пользовательского соглашения
                    </Link>
                    <br />
                    <Link
                      component="a"
                      href="#"
                      color="secondary"
                      onClick={e => {
                        e.preventDefault();
                        infoModalStore.openModalUrl(
                          'Оферта',
                          '/files/offer.html'
                        );
                      }}
                    >
                      Оферты
                    </Link>
                    <br />
                    <Link
                      component="a"
                      href="#"
                      color="secondary"
                      onClick={e => {
                        e.preventDefault();
                        infoModalStore.openModalUrl(
                          'Политика конфиденциальности',
                          '/files/policy.html'
                        );
                      }}
                    >
                      Политики конфиденциальности
                    </Link>
                  </Grid>
                </Grid>
              </Typography>
            </Box>
            {Boolean(errors.isAgree) && (
              <FormHelperText error>{errors.isAgree}</FormHelperText>
            )}
            <Box mt={2}>
              <Button
                color="secondary"
                disabled={isSubmitting}
                size="large"
                type="submit"
                variant="contained"
              >
                Зарегистрироваться
              </Button>
            </Box>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default RegisterForm;
