import {action, decorate, observable} from 'mobx';
import RootStoreModel from "../_root/RootStoreModel";
import FrontApi from "../../api/FrontApi";
import {delay} from "q";

class InfoModalStore extends RootStoreModel{
    open: boolean = false;

    title: string;
    body: string;
    onClose: Function;

    url: string;
    isLoading: boolean = false;
    htmlContent: string;

  openModal = (title: string, body: string, onClose?: Function) => {
      if (this.open) {
          this.closeModal();
      }

      this.onClose = onClose;
      this.isLoading = false;
      this.url = null;
      this.title = title;
      this.body = body;

      this.open = true;
  };

    openModalHtml = (title: string, body: string, onClose?: Function) => {
        if (this.open) {
            this.closeModal();
        }

        this.onClose = onClose;
        this.isLoading = false;
        this.url = null;
        this.title = title;
        this.htmlContent = body;

        this.open = true;
    };

    openModalUrl = (title: string, url: string) => {
        if (this.open) {
            this.closeModal();
        }

        this.title = title;
        this.body = null;
        this.url = url;

        this.isLoading = true;

        FrontApi.getHtml(url).then(response => {
            if (response.success) {
                this.htmlContent = this.extractBody(response.data);
            } else {
                this.htmlContent = `<h3>Ошибка:</h3><p>${response.message}</p>`;
            }
            this.isLoading = false;
        });

        this.open = true;
    };

    closeModal = () => {
      this.open = false;
      this.isLoading = false;
      this.htmlContent = null;

      this.title = null;
      this.body = null;
      this.url = null;

      const onClose = this.onClose;
      this.onClose = null;
      if (onClose) onClose();
  }

  extractBody = (html: string): string => {
        return html;
        /*if (!html) return html;
        const re = new RegExp('(?<=<\\s+body\\s+>)(.*)(?=<\\s+/\\s+body\\s+>)');
        let resArr = html.match(re);
        if (resArr && resArr[0]) return resArr[0];
        return html;*/
  }
}

decorate(InfoModalStore, {
    open: observable,
    title: observable,
    body: observable,
    url: observable,
    isLoading: observable,
    htmlContent: observable,
    openModal: action,
    openModalHtml: action,
    openModalUrl: action,
    closeModal: action,
});

export default InfoModalStore;