/* eslint-disable max-len */
import React from 'react';
import {observer} from "mobx-react";
import {Avatar, CardMedia, Popover} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Image} from "react-feather";
import getFilePath from "../utils/FileUtil";
import PropTypes from "prop-types";
import NavItem from "../layouts/DashboardLayout/NavBar/NavItem";
import {ArrowLeftIcon} from "@material-ui/pickers/_shared/icons/ArrowLeftIcon";

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    avatar: {
        height: 42,
        width: 42,
        marginRight: theme.spacing(1),
        borderRadius: 5,
    },
}));

function PopoverAvatar({src, ...rest}) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl) && src;

    return (
        <>
            <Avatar
                className={classes.avatar}
                src={src}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >фото</Avatar>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={rest.anchorOrigin || {
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={rest.transformOrigin}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <CardMedia
                    style={{maxWidth: 350, maxHeight: 350}}
                    component="img" title={'фото'} image={src}/>
            </Popover>
        </>
    );
}

PopoverAvatar.propTypes = {
    src: PropTypes.string,
    //alternativeText: PropTypes.string,
}

export default observer(PopoverAvatar);
