import BaseRequestOptions from "./BaseRequestOptions";
import {ProposalStatusEnum} from "../enum/ProposalStatusEnum";
import IApiRequestOptions from "./IApiRequestOptions";

export default class FoodRequestOptions extends BaseRequestOptions {
  categoryId?: number;
  kitchenTypeId?: number;

    constructor() {
        super();
        this.pageSize = 25;
    }

    asGetParams(): IApiRequestOptions {
        let res = super.asGetParams();

        let paramsArr: string[] = [];
        const filterParam = res.search;
        if (filterParam) paramsArr.push(filterParam);

        if (this.categoryId) paramsArr.push("category," + this.categoryId);
        if (this.kitchenTypeId) paramsArr.push("kitchenTypeId," + this.kitchenTypeId);

        if (paramsArr.length) res.search = paramsArr.join(";");

        return res;
    };

    protected makeSearchParam(): string {
        return 'text,'+(this.filter?this.filter : '');
    }

}
