import React, {useCallback, useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@material-ui/core";
import {observer} from "mobx-react";
import useStore from "../../context/RootStoreContext";

function DeleteWithDescriptionDialog() {
  const store = useStore().deleteWithDescriptionModalStore;
  const {open, title, body, handleConfirm, handleCancel} = store;

  const [description, setDescription] = useState('');
  const handleChangeDescription = useCallback((ev) => {
    setDescription(ev.target.value);
  }, [setDescription]);

  const defaultHandleConfirm = () => {
    if (handleConfirm) handleConfirm(description);
    store.closeModal();
  };

  const defaultHandleCancel = () => {
    if (handleCancel) handleCancel();
    store.closeModal();
  };

  return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={open}
            onClose={defaultHandleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>{body}</p>

              <p>
                <TextField
                    fullWidth
                    label="Описание причины удаления"
                    margin="normal"
                    onChange={handleChangeDescription}
                    type="text"
                    value={description}
                    variant="outlined"
                />
              </p>

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={defaultHandleCancel} color="secondary">
              Отмена
            </Button>
            <Button onClick={defaultHandleConfirm} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
  );
}

export default observer(DeleteWithDescriptionDialog);