import ServerApi, {IResponse} from '../../ServerApi';
import IUserDto from '../../_common/dto/user/IUserDto';
import IPersonalDataRequestDto from "../request/IPersonalDataRequestDto";
import INotificationSettingsRequestDto from "../request/INotificationSettingsRequestDto";
import IAdminChangePasswordRequestDto from "../request/IAdminChangePasswordRequestDto";

export default class UserProfileApi {
  static async getUser(userId: number): Promise<IResponse<IUserDto>> {
    return ServerApi.getQuery(`users/${userId}`);
  }

  static async editPersonalData(
    userId: number,
    body: IPersonalDataRequestDto
  ): Promise<IResponse<IUserDto>> {
    return ServerApi.postQuery(`users/${userId}/personalData`, body);
  }

  static async changePassword(
    userId: number,
    body: IAdminChangePasswordRequestDto
  ): Promise<IResponse> {
    return ServerApi.postQuery(`users/${userId}/changePassword`, body);
  }

  static async changeNotificationSettings(
    userId: number,
    body: INotificationSettingsRequestDto
  ): Promise<IResponse> {
    return ServerApi.postQuery(`users/${userId}/notificationSettings`, body);
  }

  static async getPhoto(userId: number): Promise<IResponse> {
    return ServerApi.getQuery(`users/${userId}/photo`);
  }

  static async changePhoto(userId: number, photo: File): Promise<IResponse> {
    const fd = new FormData();
    fd.append('file', photo);
    return ServerApi.postQuery(`users/${userId}/photo`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  static async deletePhoto(userId: number): Promise<IResponse> {
    return ServerApi.deleteQuery(`users/${userId}/photo`);
  }

  static async editServiceField(userId: number, text: string): Promise<IResponse> {
    return ServerApi.postQuery(`users/${userId}/updateServiceField`, null, {
      params: {
        text: text
      },
      headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
    });
  }
  static async getServiceField(userId: number): Promise<IResponse> {
    return ServerApi.getQuery(`users/${userId}/getServiceField`);
  }

}
