import BaseTabViewModel from 'src/store/Common/BaseTabViewModel';
import OffersApi from 'src/api/OffersApi';
import { IResponse } from 'src/api/ServerApi';
import { decorate, observable, computed, action } from 'mobx';
import { IDropdownFileItem } from 'src/components/common-form/FilesDropdownForm/type';
import IPhotoWithDescriptionDto from 'src/api/_common/dto/IPhotoWithDescriptionDto';

class OfferProfileFilesTabViewModel extends BaseTabViewModel {
  offerImages: IPhotoWithDescriptionDto[] = [];
  processing = false;

  get images(): IDropdownFileItem[] {
    return this.offerImages.map(x => ({
      file: x.fileInfo,
      description: x.description,
      id: x.id
    }));
  }

  protected async initializeTab(): Promise<void> {
    const res = await OffersApi.getOfferImages(this.id);
    if (res.success) {
      this.offerImages = res.data;
      this.initialized = true;
    } else this.loadingError = res.message;
  }

  addImage = async (file: File): Promise<IResponse> => {
    if (this.processing) return;
    this.processing = true;
    const res = await OffersApi.addOfferImage(this.id, file);
    this.processing = false;
    if (res.success) this.offerImages.push(res.data);
    return res;
  };

  deleteImage = async (id: number): Promise<IResponse> => {
    if (this.processing) return;
    this.processing = true;
    const res = await OffersApi.deleteOfferImage(this.id, id);
    this.processing = false;
    if (res.success) {
      this.offerImages = this.offerImages.filter(x => x.id !== id);
    }
    return res;
  };

  changeImageDescription = async (
    id: number,
    description: string
  ): Promise<IResponse> => {
    if (this.processing) return;
    this.processing = true;
    const res = await OffersApi.changeOfferImageDescription(
      this.id,
      id,
      description.trim()
    );
    this.processing = false;
    if (res.success) {
      this.offerImages.find(x => x.id === id).description =
        res.data.description;
    }
    return res;
  };
}

decorate(OfferProfileFilesTabViewModel, {
  offerImages: observable,
  images: computed,
  addImage: action,
  deleteImage: action,
  changeImageDescription: action
});

export default OfferProfileFilesTabViewModel;
