import React, { FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Toolbar,
  Hidden,
  Typography,
  Link,
  makeStyles,
  AppBarProps,
  colors
} from '@material-ui/core';
import { APP_VERSION } from 'src/config';
import Logo from 'src/components/Logo';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main
  },
  toolbar: {
    minHeight: 64,
    height: 64
  },
  caption: {
    color: colors.common.white
  }
}));

interface ITopbarProps extends AppBarProps {
  className?: string;
}

const TopBar: FunctionComponent<ITopbarProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo topbar />
        </RouterLink>

        <Box flexGrow={1} />
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.caption}
        >
          Партнерский раздел
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
