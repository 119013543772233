
export function rub(value: number): string {
    if (!value) return '';
    let res: string;
    let k = value % 1;
    let rubKop = String(value).split('.');
    let r = rubKop[0];
    let rub = '';
    for (let i = 0; i < r.length; i++) {
        rub += r[i];
        if ((r.length - (i+1)) % 3 == 0 && i != r.length - 1) rub += ' ';
    }
    res = rub + ' \u20bd';
    if (k) {
        res += ', ' + k.toFixed(2) + ' коп.';
    }
    return res;
}
