import ServerApi, {IResponse} from '../ServerApi';
import IPageDto from '../_common/dto/IPageDto';
import IProposalDto from "../_common/dto/proposal/IProposalDto";
import IApiRequestOptions from "../_common/request/IApiRequestOptions";
import IQuestionDto from "../_common/dto/IQuestionDto";
import IQuestionCommentDto from "../_common/dto/IQuestionCommentDto";
import ICreateQuestionDto from "../_common/dto/ICreateQuestionDto";
import ICreateQuestionCommentDto from "../_common/dto/ICreateQuestionCommentDto";
import IProposalOfferDto from "../_common/dto/offer/IProposalOfferDto";

export default class ProposalsApi {
  static async getProposalList(apiRequestOptions: IApiRequestOptions): Promise<IResponse<IPageDto<IProposalDto>>> {
    return ServerApi.getQuery('applications', {params: apiRequestOptions});
  }
  static async delete(ids: number[]): Promise<IResponse<any>> {
    return ServerApi.deleteQuery('applications/delete', {data: ids});
  }

  static async getProposal(id: number): Promise<IResponse<IProposalDto>> {
    return ServerApi.getQuery(`applications/${id}`);
  }

  static async getQuestions(proposalId: number, apiRequestOptions?: IApiRequestOptions): Promise<IResponse<IPageDto<IQuestionDto>>> {
    return ServerApi.getQuery(`applications/${proposalId}/questions`, {params: apiRequestOptions});
  }
  static async getQuestionComments(proposalId: number, questionId: number, apiRequestOptions?: IApiRequestOptions): Promise<IResponse<IPageDto<IQuestionCommentDto>>> {
    return ServerApi.getQuery(`applications/${proposalId}/questions/${questionId}/comments`, {params: apiRequestOptions});
  }
  static async createQuestion(proposalId: number, dto: ICreateQuestionDto): Promise<IResponse<IQuestionDto>> {
    return ServerApi.postQuery(`applications/${proposalId}/questions`, dto);
  }
  static async createQuestionComment(proposalId: number, questionId: number, dto: ICreateQuestionCommentDto): Promise<IResponse<IQuestionCommentDto>> {
    return ServerApi.postQuery(`applications/${proposalId}/questions/${questionId}/comments`, dto);
  }

  static async getProposalOffers(proposalId: number, apiRequestOptions: IApiRequestOptions): Promise<IResponse<IPageDto<IProposalOfferDto>>> {
    return ServerApi.getQuery(`applications/${proposalId}/offers`, {params: apiRequestOptions});
  }

  // возвращает дто клона
  static async sendOffer(proposalId: number, offerId: number): Promise<IResponse<number>> {
    return ServerApi.postQuery(`applications/${proposalId}/sendOffer`, null, {
      params: {
        offerId: offerId,
      },
      headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' },
    });
  }
}

