import {action, decorate, observable} from 'mobx';
import IPageDto from "../../api/_common/dto/IPageDto";
import {IResponse} from "../../api/ServerApi";
import BaseListStore, {BaseListStoreDecorators} from "../Common/BaseListStore";
import IApiRequestOptions from "../../api/_common/request/IApiRequestOptions";
import CompanyRequestOptions from "../../api/_common/request/CompanyRequestOptions";
import CompaniesApi from "../../api/CompaniesApi";
import ICompanyForGridDto from "../../api/_common/dto/company/ICompanyForGridDto";
import RootStoreModel from "../_root/RootStoreModel";
import AlertModalStore, {AlertModalStoreDecorators} from "./AlertModalStore";

class TextInputModalStore extends AlertModalStore{
  value: string;

  openInputModal = (title: string, body: string, initialValue: string, handleConfirm?: Function, handleCancel?: Function) => {
      this.value = initialValue;
      this.openModal(title, body, handleConfirm, handleCancel);
  };
}


decorate(TextInputModalStore, {...AlertModalStoreDecorators,
    value: observable,
    openInputModal: action,
});

export default TextInputModalStore;