import SessionStore from '../SessionStore';
import IRootStore from './type';
import AccountStore from '../AccountStore';
import CompanyProfileStore from '../CompanyProfileStore';
import TradeListStore from '../Trade/TradeListStore';
import TradeProfileStore from '../Trade/TradeProfileStore';
import ProposalListStore from '../Proposal/ProposalListStore';
import ProposalProfileStore from '../Proposal/ProposalProfileStore';
import OrderListStore from '../Order/OrderListStore';
import OrderProfileStore from '../Order/OrderProfileStore';
import UserListStore from '../UserListStore';
import CompanyListStore from '../CompanyListStore';
import FoodListStore from '../Food/FoodListStore';
import GoodsListStore from '../Goods/GoodsListStore';
import OfferListStore from '../Offer/OfferListStore';
import OfferProfileStore from '../Offer/OfferProfileStore';
import FoodProfileStore from '../Food/FoodProfileStore';
import GoodsProfileStore from '../Goods/GoodsProfileStore';
import AlertModalStore from '../ModalStore/AlertModalStore';
import InfoModalStore from '../ModalStore/InfoModalStore';
import TextInputModalStore from '../ModalStore/TextInputModalStore';
import UserProfileStore from '../UserProfileStore';
import ServiceTabsStore from '../ServiceTabsStore';
import UserInfoModalStore from '../ModalStore/UserInfoModalStore';
import CompanyNewsListStore from '../CompanyNews/CompanyNewsListStore';
import CompanyNewsProfileStore from '../CompanyNews/CompanyNewsProfileStore';
import NotificationListStore from '../Notification/NotificationListStore';
import NotificationProfileStore from '../Notification/NotificationProfileStore';
import DashboardStore from "../DashboardStore";

export default class RootStore {
  sessionStore: SessionStore;
  accountStore: AccountStore;
  userProfileStore: UserProfileStore;
  companyProfileStore: CompanyProfileStore;
  tradeListStore: TradeListStore;
  tradeProfileStore: TradeProfileStore;
  proposalListStore: ProposalListStore;
  proposalProfileStore: ProposalProfileStore;
  orderListStore: OrderListStore;
  orderProfileStore: OrderProfileStore;
  userListStore: UserListStore;
  companyListStore: CompanyListStore;
  foodListStore: FoodListStore;
  foodProfileStore: FoodProfileStore;
  goodsListStore: GoodsListStore;
  goodsProfileStore: GoodsProfileStore;
  offerListStore: OfferListStore;
  offerProfileStore: OfferProfileStore;
  alertModalStore: AlertModalStore;
  deleteWithDescriptionModalStore: AlertModalStore;
  addQuestionModalStore: AlertModalStore;
  infoModalStore: InfoModalStore;
  userInfoModalStore: UserInfoModalStore;
  textInputModalStore: TextInputModalStore;
  fileSelectModalStore: AlertModalStore;
  serviceTabsStore: ServiceTabsStore;
  companyNewsListStore: CompanyNewsListStore;
  companyNewsProfileStore: CompanyNewsProfileStore;
  notificationListStore: NotificationListStore;
  notificationProfileStore: NotificationProfileStore;
  dashboardStore: DashboardStore;

  constructor() {
    const r = this.#getRootStore;
    this.sessionStore = new SessionStore(r);
    this.accountStore = new AccountStore(r);
    this.userProfileStore = new UserProfileStore(r);
    this.companyProfileStore = new CompanyProfileStore(r);
    this.tradeListStore = new TradeListStore(r);
    this.tradeProfileStore = new TradeProfileStore(r);
    this.proposalListStore = new ProposalListStore(r);
    this.proposalProfileStore = new ProposalProfileStore(r);
    this.orderListStore = new OrderListStore(r);
    this.orderProfileStore = new OrderProfileStore(r);
    this.userListStore = new UserListStore(r);
    this.companyListStore = new CompanyListStore(r);
    this.foodListStore = new FoodListStore(r);
    this.foodProfileStore = new FoodProfileStore(r);
    this.goodsListStore = new GoodsListStore(r);
    this.goodsProfileStore = new GoodsProfileStore(r);
    this.offerListStore = new OfferListStore(r);
    this.offerProfileStore = new OfferProfileStore(r);
    this.alertModalStore = new AlertModalStore(r);
    this.infoModalStore = new InfoModalStore(r);
    this.userInfoModalStore = new UserInfoModalStore(r);
    this.textInputModalStore = new TextInputModalStore(r);
    this.deleteWithDescriptionModalStore = new AlertModalStore(r);
    this.addQuestionModalStore = new AlertModalStore(r);
    this.fileSelectModalStore = new AlertModalStore(r);
    this.serviceTabsStore = new ServiceTabsStore(r);
    this.companyNewsListStore = new CompanyNewsListStore(r);
    this.companyNewsProfileStore = new CompanyNewsProfileStore(r);
    this.notificationListStore = new NotificationListStore(r);
    this.notificationProfileStore = new NotificationProfileStore(r);
    this.dashboardStore = new DashboardStore(r);
  }

  #getRootStore = (): IRootStore => this

  get getStore(): () => IRootStore {
    return this.#getRootStore;
  }
}
