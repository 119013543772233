import {action, computed, decorate, observable} from 'mobx';

import RootStoreModel from '../../_root/RootStoreModel';
import IPlatformDto from 'src/api/PlatformApi/dto/IPlatformDto';
import PlatformApi from 'src/api/PlatformApi';
import ICreateEditPlatformRequestDto from 'src/api/PlatformApi/dto/ICreateEditPlatformRequestDto';
import {IResponse} from 'src/api/ServerApi';
import IEditWorkTimeRequestDto from 'src/api/_common/request/IEditWorkTimeRequestDto';
import TradeProfileFilesTabViewModel from './tab/TradeProfileFilesTabViewModel';
import DictionaryStore from "../../DictionaryStore";
import TradeProfileReviewsTabViewModel from './tab/TradeProfileReviewsTabViewModel';

interface ISaveEntityResponse extends IResponse {
  id: number;
  isAdd: boolean;
}

class TradeProfileStore extends RootStoreModel {
  loadingError: string = null;
  platform: IPlatformDto;
  id: number;

  images: TradeProfileFilesTabViewModel;
  reviews: TradeProfileReviewsTabViewModel;

  processing = false;

  get mainImageId(): number {
    return (
      (this.platform &&
        this.platform.platformImageMain &&
        this.platform.platformImageMain.id) ||
      null
    );
  }

  initialize = async (id?: number): Promise<void> => {
    this.loadingError = null;
    this.id = id;

    let dictRes = await DictionaryStore.initializeCompanyTradeRelationTypes();
    if (!dictRes.success) {
      this.loadingError = dictRes.data;
      return;
    }
    dictRes = await DictionaryStore.initializeTradeTypes();
    if (!dictRes.success) {
      this.loadingError = dictRes.data;
      return;
    }
    dictRes = await DictionaryStore.initializeLocationsAll();
    if (!dictRes.success) {
      this.loadingError = dictRes.data;
      return;
    }

    if (!id) {
      this.platform = {
        id: null,
        address: "",
        capacity: 0,
        capacityParking: 0,
        company: undefined,
        createdAd: undefined,
        description: "",
        shortDescription: "",
        lat: "",
        lng: "",
        name: "",
        platformTypes: [],
        platformWorkTimes: [],
        relationshipType: undefined,
        rentalPrice: 0,
        square: 0,
        location: null,
      };
      return;
    }
    const platformRes = await PlatformApi.getPlatform(id);
    if (!platformRes.success) {
      this.loadingError = platformRes.message;
      return;
    }
    this.platform = platformRes.data;
    this.images = new TradeProfileFilesTabViewModel(
      this.root,
      this.platform.id
    );
    this.reviews = new TradeProfileReviewsTabViewModel(
        this.root,
        this.platform.id
    );
  };

  save = async (
    body: ICreateEditPlatformRequestDto
  ): Promise<ISaveEntityResponse> => {
    const isAdd = (this.platform && this.platform.id) ? false : true;
    if (this.platform) body.id = this.platform.id;
    const res = isAdd
      ? await PlatformApi.createPlatform(body)
      : await PlatformApi.editPlatform(body);
    if (res.success) {
      this.platform = res.data;
    }
    return { ...res, id: res.data && res.data.id, isAdd };
  };

  editPlatformWorkTime = async (
    body: IEditWorkTimeRequestDto
  ): Promise<IResponse> => {
    const id = this.platform.id;
    const res = await PlatformApi.changePlatformWorkTime(id, body);
    if (res.success && id === this.platform.id) {
      this.platform = res.data;
    }
    return res;
  };

  editPlatformMainImage = async (id: number): Promise<IResponse> => {
    if (this.processing) return;
    this.processing = true;
    const platformId = this.platform.id;
    const res = await PlatformApi.markPlatformImageAsMain(platformId, id);
    this.processing = false;
    if (res.success && platformId === this.platform.id) {
      this.platform.platformImageMain = res.data;
    }
    return res;
  };
}

decorate(TradeProfileStore, {
  loadingError: observable,
  platform: observable,
  mainImageId: computed,
  save: action,
  editPlatformWorkTime: action,
  editPlatformMainImage: action
});

export default TradeProfileStore;
