import IRootStore from "../type";
import {action, decorate} from "mobx";

class RootStoreModel {
  #root: () => IRootStore;

  constructor(root: () => IRootStore) {
    this.#root = root;
  }

  protected get root() : () => IRootStore {
    return this.#root;
  }

  protected getRootStore(): IRootStore {
    return this.#root();
  }

  performAsAction(fn: () => void) {
    fn();
  }
  
}

decorate(RootStoreModel, {
  performAsAction: action
})

export default RootStoreModel;
