import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import useStore from 'src/context/RootStoreContext';

const GuestRoute: FunctionComponent<RouteProps> = ({
  component,
  render,
  ...rest
}) => {
  const { authenticated } = useStore().sessionStore;
  const Component = component as React.ComponentType;

  if (authenticated) return <Redirect to="/" />;

  return (
    <Route
      {...rest}
      component={(props: RouteComponentProps) =>
        !authenticated ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default observer(GuestRoute);
