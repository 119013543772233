import React from 'react';
import { RouteComponentProps } from 'react-router';
import LoadingScreen from 'src/components/LoadingScreen';
import { decorate, observable, action } from 'mobx';
import { observer } from 'mobx-react';
import IRootStore from 'src/store/_root/type';
import { RootContext, RootContextType } from 'src/context/RootStoreContext';

export type LoadableView<IP = {}, PP = {}> = React.ComponentType<
  RouteComponentProps & IP & PP
> & {
  getInitialProps?(
    props?: RouteComponentProps & PP & { store: IRootStore }
  ): Promise<IP> | IP;
};

export default function withViewLoader<PP = {}>(Page: LoadableView) {
  class ViewLoader extends React.Component<
    RouteComponentProps & PP,
    any
  > {
    context: RootContextType;

    loaded = false;
    pageProps: any;
    async componentDidMount() {
      if (Page.getInitialProps) {
        this.pageProps = await Page.getInitialProps({
          ...this.props,
          store: this.context()
        });
        this.setInitialPropsLoaded();
      } else {
        this.pageProps = {};
        this.setInitialPropsLoaded();
      }
    }

    setInitialPropsLoaded = () => {
      this.loaded = true;
    };

    render(): JSX.Element {
      if (!this.loaded) return <LoadingScreen />;
      return <Page {...this.props} {...this.pageProps} />;
    }
  }

  decorate(ViewLoader, {
    loaded: observable,
    setInitialPropsLoaded: action
  });

  ViewLoader.contextType = RootContext;

  return observer(ViewLoader);
}
