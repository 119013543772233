import BaseRequestOptions from "./BaseRequestOptions";
import {ProposalStatusEnum} from "../enum/ProposalStatusEnum";
import IApiRequestOptions from "./IApiRequestOptions";

export default class ProposalRequestOptions extends BaseRequestOptions {
  status?: ProposalStatusEnum;
  formatId?: number;
  todayOnly?: boolean;

    constructor() {
        super();
        this.pageSize = 25;
    }

    asGetParams(): IApiRequestOptions {
        let res = super.asGetParams();

        let paramsArr: string[] = [];
        const filterParam = res.search;
        if (filterParam) paramsArr.push(filterParam);

        if (this.formatId) paramsArr.push("formatId," + this.formatId);
        if (this.status) paramsArr.push("status," + this.status);
        if (this.todayOnly) paramsArr.push("todayOnly,true");

        if (paramsArr.length) res.search = paramsArr.join(";");

        return res;
    };

}
