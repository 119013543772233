import {action, decorate, observable} from 'mobx';
import {IResponse} from 'src/api/ServerApi';
import RootStoreModel from '../../_root/RootStoreModel';
import INotificationDto from '../../../api/_common/dto/notification/INotificationDto';
import ICreateNotificationDto from "../../../api/_common/dto/notification/ICreateNotificationDto";
import NotificationsApi from "../../../api/NotificationsApi";
import NotificationTypeEnum from "../../../api/_common/enum/NotificationTypeEnum";
import ICreateNotificationForPartnerDto from "../../../api/_common/dto/notification/ICreateNotificationForPartnerDto";
import ICreateNotificationForCompanyDto from "../../../api/_common/dto/notification/ICreateNotificationForCompanyDto";
import ICreateNotificationForUserDto from "../../../api/_common/dto/notification/ICreateNotificationForUserDto";

class NotificationProfileStore extends RootStoreModel {
  loadingError = null;
  processing: boolean = false;

  notification: INotificationDto;
  notificationType: NotificationTypeEnum;
  open: boolean = false;

  initialize = async (notificationId: number): Promise<void> => {

    if (!notificationId) {
      this.notification = {};
    } else {
      const res = await NotificationsApi.getNotification(notificationId);
      if (res.success) {
        this.notification = res.data;
      }
      if (!this.notification) this.notification = {};
    }
  };

  createNotificationForAll = async (dto: ICreateNotificationDto): Promise<IResponse> => {
    const res = await NotificationsApi.createForAll(dto);
    if (res.success) {
      this.notification = dto as INotificationDto;
    }
    return res;
  };

  createNotificationForPartner = async (dto: ICreateNotificationForPartnerDto): Promise<IResponse> => {
    const res = await NotificationsApi.createForPartner(dto);
    if (res.success) {
      this.notification = dto as INotificationDto;
    }
    return res;
  };

  createNotificationForCompany = async (dto: ICreateNotificationForCompanyDto): Promise<IResponse> => {
    const res = await NotificationsApi.createForCompany(dto);
    if (res.success) {
      this.notification = dto as INotificationDto;
    }
    return res;
  };

  createNotificationForUser = async (dto: ICreateNotificationForUserDto): Promise<IResponse> => {
    const res = await NotificationsApi.createForUser(dto);
    if (res.success) {
      this.notification = dto as INotificationDto;
    }
    return res;
  };

  setNotificationType = (notificationType: NotificationTypeEnum) => {
    this.notificationType = notificationType;
  };
}

decorate(NotificationProfileStore, {
  notification: observable,
  notificationType: observable,
  open: observable,
  setNotificationType: action,
  createNotificationForAll: action,
  createNotificationForCompany: action,
  createNotificationForUser: action,
});

export default NotificationProfileStore;
