import BaseTabViewModel from 'src/store/Common/BaseTabViewModel';
import PlatformApi from 'src/api/PlatformApi';
import {IResponse} from 'src/api/ServerApi';
import {action, decorate, observable} from 'mobx';
import IPageDto from "../../../../api/_common/dto/IPageDto";
import ITradeReviewDto from "../../../../api/_common/dto/trade/ITradeReviewDto";
import BaseReviewStore, {BaseReviewStoreDecorators} from "../../../Common/BaseReviewStore";
import IApiRequestOptions from "../../../../api/_common/request/IApiRequestOptions";
import IReviewDto from "../../../../api/_common/dto/IReviewDto";

class TradeProfileReviewsTabViewModel extends BaseReviewStore {

  protected async getReviews(apiRequestOptions: IApiRequestOptions): Promise<IResponse<IPageDto<IReviewDto>>> {
    return await PlatformApi.getReviews(this.id, apiRequestOptions);
  }

}

decorate(TradeProfileReviewsTabViewModel, {...BaseReviewStoreDecorators,
});

export default TradeProfileReviewsTabViewModel;
