import BaseTabViewModel from 'src/store/Common/BaseTabViewModel';
import PlatformApi from 'src/api/PlatformApi';
import { IResponse } from 'src/api/ServerApi';
import { decorate, observable, computed, action } from 'mobx';
import { IDropdownFileItem } from 'src/components/common-form/FilesDropdownForm/type';
import IPhotoWithDescriptionDto from 'src/api/_common/dto/IPhotoWithDescriptionDto';
import CompanyNewsApi from "../../../../api/CompanyNewsApi";
import IRootStore from "../../../_root/type";

class CompanyNewsFilesTabViewModel extends BaseTabViewModel {
  companyId: number;
  newsId: number;
  newsImages: IPhotoWithDescriptionDto[] = [];
  processing = false;

  get images(): IDropdownFileItem[] {
    return this.newsImages.map(x => ({
      file: x.fileInfo,
      description: x.description,
      id: x.id
    }));
  }

  constructor(root: () => IRootStore, companyId: number, newsId: number) {
    super(root, null);
    this.companyId = companyId;
    this.newsId = newsId
  }

  protected async initializeTab(): Promise<void> {
    const res = await CompanyNewsApi.getImages(this.companyId, this.newsId);
    if (res.success) {
      this.newsImages = res.data.content;
      this.initialized = true;
    } else this.loadingError = res.message;
  }

  addImage = async (file: File): Promise<IResponse> => {
    if (this.processing) return;
    this.processing = true;
    const res = await CompanyNewsApi.addImage(this.companyId, this.newsId, file);
    this.processing = false;
    if (res.success) this.newsImages.push(res.data);
    return res;
  };

  deleteImage = async (id: number): Promise<IResponse> => {
    if (this.processing) return;
    this.processing = true;
    const res = await CompanyNewsApi.deleteImage(this.companyId, this.newsId, id);
    this.processing = false;
    if (res.success) {
      this.newsImages = this.newsImages.filter(x => x.id !== id);
    }
    return res;
  };

  changeImageDescription = async (
    id: number,
    description: string
  ): Promise<IResponse> => {
    if (this.processing) return;
    this.processing = true;
    const res = await CompanyNewsApi.changeImageDescription(
      this.companyId,
      this.newsId,
      id,
      description
    );
    this.processing = false;
    if (res.success) {
      this.newsImages.find(x => x.id === id).description =
        res.data.description;
    }
    return res;
  };

  setMainImage = async (id: number): Promise<IResponse> => {
    if (this.processing) return { success: false, message: '' };
    this.processing = true;
    const res = await CompanyNewsApi.setImageAsMain(this.companyId, this.newsId, id);
    this.processing = false;
    if (res.success) {
      this.root().companyNewsProfileStore.updateMainImage(this.newsImages.filter((x) => x.id === id)[0]);
    }
    return res;
  };

}

decorate(CompanyNewsFilesTabViewModel, {
  newsImages: observable,
  images: computed,
  addImage: action,
  deleteImage: action,
  changeImageDescription: action
});

export default CompanyNewsFilesTabViewModel;
