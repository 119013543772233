import axios, { AxiosError, AxiosRequestConfig } from '../../utils/axios';
import jwtDecode from 'jwt-decode';

import {
  LOCAL_STORAGE_ACCESS_TOKEN_NAME,
  LOCAL_STORAGE_REFRESH_TOKEN_NAME,
  LOCAL_STORAGE_CURRENT_COMPANY,
  LOCAL_STORAGE_COMPANY_COUNT
} from '../../const/localStorage';
import IAuthTokenDto from '../_common/dto/IAuthTokenDto';
import { API_BASE_URL } from 'src/config';

const BASE_URL = `${process.env.REACT_APP_DEV_DOMAIN || ''}${API_BASE_URL}`;

export interface IErrorResponse {
  code: number;
  message: string;
  details: any;
}

export interface IResponse<T = any> {
  success: boolean;
  status?: number;
  data?: T;
  message?: string;
  details?: any;
  contentType?: string;
}

export default class ServerApi {
  static onUpdateToken: () => void;

  static async getQuery<T>(
    uri: string,
    config?: AxiosRequestConfig
  ): Promise<IResponse<T>> {
    const result: IResponse<T> = {
      success: false,
      status: 0
    };

    try {
      const resp = await axios.get<T>(`${BASE_URL}${uri}`, config);
      result.success = true;
      result.status = resp.status;
      result.data = resp.data;
      result.contentType = resp.headers['content-type'];
      return result;
    } catch (error) {
      return await ServerApi.handleError(error, result, async () => {
        return await ServerApi.getQuery(uri, config);
      });
    }
  }

  static async postQuery<T>(
    uri: string,
    body: any,
    config?: AxiosRequestConfig
  ): Promise<IResponse<T>> {
    const result: IResponse<T> = {
      success: false,
      status: 0,
      data: null
    };
    try {
      const resp = await axios.post(`${BASE_URL}${uri}`, body, config);
      result.success = true;
      result.status = resp.status;
      result.data = resp.data;
      result.contentType = resp.headers['content-type'];
      return result;
    } catch (error) {
      return await ServerApi.handleError(error, result, async () => {
        return await ServerApi.postQuery(uri, body, config);
      });
    }
  }

  static async putQuery<T>(
      uri: string,
      body: any,
      config?: AxiosRequestConfig
  ): Promise<IResponse<T>> {
    const result: IResponse<T> = {
      success: false,
      status: 0,
      data: null
    };
    try {
      const resp = await axios.put(`${BASE_URL}${uri}`, body, config);
      result.success = true;
      result.status = resp.status;
      result.data = resp.data;
      result.contentType = resp.headers['content-type'];
      return result;
    } catch (error) {
      return await ServerApi.handleError(error, result, async () => {
        return await ServerApi.postQuery(uri, body, config);
      });
    }
  }

  static async deleteQuery<T>(
    uri: string,
    config?: AxiosRequestConfig
  ): Promise<IResponse<T>> {
    const result: IResponse<T> = {
      success: false,
      status: 0
    };
    try {
      const resp = await axios.delete<T>(`${BASE_URL}${uri}`, config);
      result.success = true;
      result.status = resp.status;
      result.data = resp.data;
      result.contentType = resp.headers['content-type'];
      return result;
    } catch (error) {
      return await ServerApi.handleError(error, result, async () => {
        return await ServerApi.deleteQuery(uri, config);
      });
    }
  }

  static async uploadFileQuery<T>(
    uri: string,
    file: File
  ): Promise<IResponse<T>> {
    const result: IResponse<T> = {
      success: false,
      status: 0,
      data: null
    };
    const fd = new FormData();
    fd.append('file', file);
    try {
      const resp = await axios.post(`${BASE_URL}${uri}`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      result.success = true;
      result.status = resp.status;
      result.data = resp.data;
      result.contentType = resp.headers['content-type'];
      return result;
    } catch (error) {
      return await ServerApi.handleError(error, result, async () => {
        return await ServerApi.uploadFileQuery(uri, file);
      });
    }
  }

  private static async handleError<T>(
    error: AxiosError,
    result: IResponse<T>,
    callback: () => Promise<IResponse<T>>
  ): Promise<IResponse<T>> {
    const res = result;
    if (error.response) {
      if (error.response.status === 401) {
        if (await this.refreshTokens()) return await callback();
      }
      if (error.response.data) {
        const data = error.response.data as IErrorResponse;
        res.status = data.code;
        res.message = data.message;
        res.details = data.details;
      } else {
        res.status = error.response.status;
        res.message = error.response.statusText;
      }
    } else if (error.request) {
      res.message = 'Сервис недоступен';
    }
    return res;
  }

  static async refreshTokens(callOnUpdate = true): Promise<boolean> {
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_NAME);
    if (refreshToken) {
      try {
        const resp = await axios.post(
          `${BASE_URL}auth/refreshAuthorizeTokens`,
          {
            refreshToken
          }
        );
        const data = resp.data as IAuthTokenDto;
        this.updateTokenValues(data.accessToken, data.refreshToken);
        if (callOnUpdate) this.onUpdateToken();
        return true;
      } catch (error) {
        this.updateTokenValues();
        if (callOnUpdate) this.onUpdateToken();
      }
    } else {
      this.updateTokenValues();
      if (callOnUpdate) this.onUpdateToken();
    }
    return false;
  }

  static updateTokenValues(token?: string, refreshToken?: string): void {
    if (token) {
      localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_NAME, token);
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_NAME);
      delete axios.defaults.headers.common.Authorization;
      localStorage.removeItem(LOCAL_STORAGE_COMPANY_COUNT);
      localStorage.removeItem(LOCAL_STORAGE_CURRENT_COMPANY);
    }
    if (refreshToken)
      localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_NAME, refreshToken);
    else localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_NAME);
  }

  static async initialize(onUpdateToken: () => void): Promise<void> {
    this.onUpdateToken = onUpdateToken;

    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_NAME);
    if (accessToken) {
      const decoded = jwtDecode(accessToken) as any;
      const currentTime = Date.now() / 1000;
      if (decoded.exp > currentTime)
        axios.defaults.headers.common.Auhtorization = `Bearer ${accessToken}`;
      else await this.refreshTokens(false);
    }
  }
}
