import RootStoreModel from '../_root/RootStoreModel';

import IUserCompanyDto from 'src/api/_common/dto/company/IUserCompanyDto';
import {IResponse} from 'src/api/ServerApi';
import {action, computed, decorate, observable} from 'mobx';
import DictionaryStore from '../DictionaryStore';
import {UserProfileTabType} from "./const";
import IPersonalDataRequestDto from "../../api/ProfileApi/request/IPersonalDataRequestDto";
import IUserDto from "../../api/_common/dto/user/IUserDto";
import UserProfileApi from "../../api/ProfileApi/UserProfileApi";
import getFilePath from "../../utils/FileUtil";

class UserProfileStore extends RootStoreModel {
  currentTab: UserProfileTabType = 'profile';
  userId?: number = null;
  user: IUserDto;
  loadingError: string = '';
  serviceField: string;

  companies: IUserCompanyDto[];

  setCurrentTab = (page: UserProfileTabType): void => {
    this.currentTab = page;
  };

  isInitializeSuccess(res: IResponse): boolean {
    if (res.success) return true;
    this.loadingError = res.message;
    return false;
  }

  async initialize(userId: number): Promise<void> {
    this.loadingError = null;
    this.userId = userId;
    await this.initializeProfilePage();

    const res = await UserProfileApi.getServiceField(userId);
    if (!res.success) this.loadingError = res.message;
    else this.serviceField = res.data;
  }

  private async initializeProfilePage(): Promise<void> {
    if (!DictionaryStore.locations) {
      const res = await DictionaryStore.initializeLocations();
      if (!res.success) {
        this.loadingError = res.message;
        return;
      }
    }

    const res = await UserProfileApi.getUser(this.userId);
    if (!this.isInitializeSuccess(res)) return;
    this.user = res.data;
  }

  async updateProfile(data: IPersonalDataRequestDto): Promise<IResponse> {
    const res = await UserProfileApi.editPersonalData(this.userId, data);
    if (res.success) {
      this.user = res.data;
    }
    return res;
  }

  get avatar(): string {
    return getFilePath(this.user && this.user.photo);
  }

  editServiceField = async (value: string): Promise<IResponse> => {
    const res = await UserProfileApi.editServiceField(this.userId, value);
    this.serviceField = value;
    return res;
  };

}

decorate(UserProfileStore, {
  userId: observable,
  user: observable,
  currentTab: observable,
  serviceField: observable,
  // setCurrentTab: action.bound,
  companies: observable,
  isInitializeSuccess: action,
  avatar: computed,
});

export default UserProfileStore;
