import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import {
  Box,
  // Button,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
// import Logo from 'src/components/Logo';
import RegisterForm from './RegisterForm';
import { CateringTheme } from 'src/theme/type';
import withViewLoader, { LoadableView } from 'src/hoc/withViewLoader';
import DictionaryStore from 'src/store/DictionaryStore';
import useStore from "../../../context/RootStoreContext";

const useStyles = makeStyles((theme: CateringTheme) => ({
  root: {
    marginTop: 64,
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80
  }
}));

const RegisterView: LoadableView = () => {
  const classes = useStyles();
  const history = useHistory();
  const { infoModalStore } = useStore();

  const handleSubmitSuccess = () => {
    infoModalStore.openModal('',
        "Спасибо за регистрацию, на указанный Вами адрес электронной почты отправлен запрос подтверждения",
        () => {
          history.push('/signin');
        });
  };

  return (
    <Page className={classes.root} title="Регистрация">
      <Container maxWidth="sm">
        {/* <Box mb={5} display="flex" alignItems="center">
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Button component={RouterLink} to="/" className={classes.backButton}>
            Back to home
          </Button>
        </Box> */}
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h2" color="textPrimary">
              Регистрация
            </Typography>
            <Box my={2}>
              <Divider />
            </Box>
            <RegisterForm onSubmitSuccess={handleSubmitSuccess} />
            <Box my={2}>
              <Divider />
            </Box>
            <Link
              component={RouterLink}
              to="/signin"
              variant="body2"
              color="textSecondary"
            >
              Уже зарегистрированы?
            </Link>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};
RegisterView.getInitialProps = async (): Promise<{}> => {
  await DictionaryStore.initializeOwnershipTypes();
  return {};
};

export default withViewLoader(RegisterView);
