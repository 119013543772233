import DictionaryStore from 'src/store/DictionaryStore';
import { decorate, observable } from 'mobx';
import CompanyLocationItemViewModel from '../CompanyLocationItemViewModel';
import IIdNameDto from 'src/api/_common/dto/IIdNameDto';
import CompaniesApi from "../../../api/CompaniesApi";
import {IResponse} from "../../../api/ServerApi";
import BaseTabViewModel from "../../Common/BaseTabViewModel";

class CompanyServiceFieldTabViewModel extends BaseTabViewModel{
  loadingError: string = null;
  text: string;

  protected async initializeTab(): Promise<void> {
    const res = await CompaniesApi.getServiceField(this.id);
    if (!res) {
      this.loadingError = res.message;
    } else {
      this.text = res.data;
    }
  }

  editServiceField = async (value: string): Promise<IResponse> => {
    const res = await CompaniesApi.editServiceField(this.id, value);
    this.text = value;
    return res;
  };
}

decorate(CompanyServiceFieldTabViewModel, {
  loadingError: observable,
  text: observable,
});

export default CompanyServiceFieldTabViewModel;
