import IIdNameDto from 'src/api/_common/dto/IIdNameDto';
import IWorkTimeDto from '../IWorkTimeDto';
import IFileDto from '../IFileDto';
import IUserCompanyDto from "./IUserCompanyDto";
import IUserForGridDto from "../user/IUserForGridDto";
import IUserDto from "../user/IUserDto";

export default interface ICompanyDto {
  id: number;
  companyName: string;
  brandedCompanyName: string;
  inn: string;
  ogrn: string;
  isEnabled: boolean;
  description: string;
  yearFoundation: string;
  site: string;
  legalAddress: string;
  ownershipType: IIdNameDto;
  icon: IFileDto;
  background: IFileDto;
  paymentTypes: IIdNameDto[];
  serviceZones: IIdNameDto[];
  cateringZones: IIdNameDto[];
  eventTypes: IIdNameDto[];
  eventFormats: IIdNameDto[];
  companyWorkTimes: IWorkTimeDto[];
  cntMembers: number;
  contactPerson: IUserCompanyDto;
}

export function makeCompanyName( dto: { companyName: string, brandedCompanyName?: string }) {
  if (!dto) return 'Компания';
  let res = dto.companyName;
  if (dto.brandedCompanyName) res += ' (' + dto.brandedCompanyName + ')';
  return res;
}
