import {action, decorate, observable} from 'mobx';
import IPageDto from "../../../api/_common/dto/IPageDto";
import {IResponse} from "../../../api/ServerApi";
import IOrderDto from "../../../api/_common/dto/order/IOrderDto";
import OrderRequestOptions from "../../../api/_common/request/OrderRequestOptions";
import OrdersApi from "../../../api/OrdersApi";
import BaseListStore, {BaseListStoreDecorators} from "../../Common/BaseListStore";
import IApiRequestOptions from "../../../api/_common/request/IApiRequestOptions";
import {copyAndSpread} from "../../../utils/objects";

class OrderListStore extends BaseListStore<IOrderDto, OrderRequestOptions> {

    partnerAll: boolean;

    protected getDefaultRequestOptions(): OrderRequestOptions {
        return new OrderRequestOptions();
    }

    protected async deleteManyRoutine(ids: number[]): Promise<IResponse> {
        //return OrdersApi.delete(ids);
        return;
    }

    protected async deleteOneRoutine(id: number): Promise<IResponse> {
        //return OrdersApi.delete([id]);
        return;
    }

    protected async getListRoutine(apiRequestOptions: IApiRequestOptions, options?: any): Promise<IResponse> {
        if (this.partnerAll) return OrdersApi.getOrderListPartnerAll(apiRequestOptions);
        else return OrdersApi.getOrderList(this.getCompanyId(), apiRequestOptions);
    }

    setPartnerAll = (value: boolean): Promise<IResponse> => {
        this.partnerAll = value;
        return this.load(copyAndSpread(this.requestOptions, {pageNumber: 1}));
    }

  imitateLoadResults = (requestOptions: OrderRequestOptions): IPageDto<IOrderDto> => {
      /*const totalElements = 17;
      const totalPages = Math.trunc(totalElements / requestOptions.pageSize) + 1;
      const size = requestOptions.pageSize;
      var number = requestOptions.pageSize;
      if (requestOptions.pageNumber == totalPages - 1) number = totalElements % size;
      var content = [];
      for (var i = requestOptions.pageNumber * size; i < requestOptions.pageNumber * size + number; i++) content.push(
          {
              id: i+1,
              city: ['Краснодар', 'Анапа', 'Сочи'][i % 3],
              persons: (i % 7) + 20,
              date: new Date(),
              price: 5000 + Math.trunc( (Math.random() * 10000) / 1000) * 1000,
              paymentType: [ {id: 1, name: 'Наличными'}, {id: 2, name: 'Картой онлайн'}, {id: 3, name: 'По счету с НДС'}, {id: 4, name: 'Картой при получении'}  ][ (i+2) % 4],
              comment: 'некий комментарий ' + (i+1),
              orderStatus: ['NEW', 'CLOSED', 'CANCELLED', 'WAITING', 'INPROC'][(i + 6) % 5],
              user: {
                  id: 1000 + i,
                  email: 'testUser' + (i+1) + '@mail.ru',
                  lastName: 'Фамилия' + (i+1),
                  firstName: 'Имя' + (i+1),
                  secondName: 'Отчество' + (i+1),
              },
          }
      );

      if (requestOptions.orderStatus) {
          content = content.filter(x => {return x.orderStatus === requestOptions.orderStatus});
      }
      return  {
          totalElements: totalElements,
          totalPages: totalPages,
          size: size,
          number: number,
          content: content
      };*/
      return null;
  };
}

decorate(OrderListStore, {
    ...BaseListStoreDecorators,
    partnerAll: observable,
    setPartnerAll: action,
});

export default OrderListStore;