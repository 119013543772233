import React, { FunctionComponent } from 'react';
import { Formik } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';

import {
  Box,
  Button,
  TextField,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import useStore from 'src/context/RootStoreContext';
import SignInApi from 'src/api/SignInApi';

interface IForgotPasswordFormProps {
  className?: string;
  onSubmitSuccess: () => void;
}

const useStyles = makeStyles(() => ({
  root: {}
}));

const ForgotPasswordForm: FunctionComponent<IForgotPasswordFormProps> = ({
  className,
  onSubmitSuccess,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Formik<{ email: string; submit?: string }>
      initialValues={{ email: '' }}
      validationSchema={Yup.object().shape<{ email: string }>({
        email: Yup.string()
          .email('Неверный формат Email')
          .max(255)
          .required('Email не указан')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const result = await SignInApi.getForgotPasswordToken(values.email);
        if (result.success) {
          setErrors({});
          onSubmitSuccess();
        } else {
          const { message, details } = result;
          setStatus({ success: false });
          setErrors(details || { submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            autoFocus
            helperText={touched.email && errors.email}
            label="Email"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <Box mt={2}>
            <Button
              color="secondary"
              disabled={isSubmitting}
              // fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Восстановить
            </Button>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default React.memo(ForgotPasswordForm);
