import React, { FunctionComponent } from 'react';
import StarIcon from '@material-ui/icons/Star';
import {rub} from "../utils/StringUtil";
import {Badge, Box} from "@material-ui/core";

interface IOneLinerProps {
  text: string,
}

const OneLiner: FunctionComponent<IOneLinerProps> = ({ ...props }) => {

  const { text } = props;
  return (
      <span style={{whiteSpace: "nowrap"}}>{text}</span>
  );
};

export default OneLiner;
