import IIdNameDto from "./IIdNameDto";

export default interface IIdNameTreeDto extends IIdNameDto{
  childs?: IIdNameTreeDto[];

  // front only
  parent?: IIdNameTreeDto;
}

export function makeBidirectional(root: IIdNameTreeDto) {
  bidir(root);
}

export function makeBidirectionalArr(roots: IIdNameTreeDto[]): IIdNameTreeDto[] {
  if (roots) roots.forEach(x => bidir(x));
  return roots;
}

function bidir(node: IIdNameTreeDto) {
  if (node.childs) {
    node.childs.forEach(x => {
      x.parent = node;
      bidir(x);
    });
  }
}

export function findInTree(root: IIdNameTreeDto, id: number): IIdNameTreeDto {
  if (root.id === id) return root;
  if (root.childs) {
    for (let i = 0; i < root.childs.length; i++) {
      let res = findInTree(root.childs[i], id);
      if (res) return res;
    }
  }
  return null;
}

export function findInTreeArr(roots: IIdNameTreeDto[], id: number): IIdNameTreeDto {
  for (let i = 0; i < roots.length; i++) {
    let res = findInTree(roots[i], id);
    if (res) return res;
  }
  return null;
}

export function replaceInRoots<T extends IIdNameTreeDto>(roots: T[], updatedItem: T): T[] {
  if (!updatedItem) return roots;
  for (let i = 0; i < roots.length; i++) {
    const x = findInTree(roots[i], updatedItem.id) as T;
    if (x) {
      for (let field in updatedItem) {
        x[field] = updatedItem[field];
      }
      break;
    }
  }
}