import BaseTabViewModel from '../../Common/BaseTabViewModel';
import IUserDto from 'src/api/_common/dto/user/IUserDto';
import CompaniesApi from 'src/api/CompaniesApi';
import CompanyMemberStatusEnum from 'src/api/_common/enum/CompanyMemberStatusEnum';
import CompanyMemberRoleEnum from 'src/api/_common/enum/CompanyMemberRoleEnum';
import {decorate, observable} from 'mobx';
import { IResponse } from 'src/api/ServerApi';

export interface IUserMemberModel {
  id: number;
  user: IUserDto;
  role: CompanyMemberRoleEnum;
  status: CompanyMemberStatusEnum;
}

class CompanyMembersTabViewModel extends BaseTabViewModel {
  users: IUserMemberModel[];
  canLoad = false;

  addUser = async (email: string): Promise<IResponse> => {
    const res = await CompaniesApi.inviteUser(this.id, email);
    if (res.success)
      this.users.push({
        id: res.data.id,
        user: res.data.user,
        role: res.data.role,
        status: res.data.status
      });
    return res;
  };

  removeMember = async (companyId: number, memberId: number): Promise<IResponse> => {
    const res = await CompaniesApi.removeMember(companyId, memberId);
    if (res.success) {
      this.users = this.users.filter(x => {
        return x.id !== memberId
      })
    }
    return res;
  };

  protected async initializeTab(): Promise<void> {
    const res = await CompaniesApi.getCompanyMembers(this.id);
    if (res.success) {
      this.users = res.data.content.map(x => ({
        id: x.id,
        user: x.user,
        role: x.role,
        status: x.status
      }));
      this.canLoad = res.data.number + 1 < res.data.totalPages;
      this.initialized = true;
    } else this.loadingError = res.message;
  }
}

decorate(CompanyMembersTabViewModel, {
  users: observable,
});

export default CompanyMembersTabViewModel;
