import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core';
import useStore from 'src/context/RootStoreContext';
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

function Account() {
  const { session: account, avatar, logout } = useStore().sessionStore;
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef<any>();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    handleClose();
    logout();
    history.push('/signin');
  };

  return (
    <>
      <div ref={ref}>
        <Box
          display="flex"
          alignItems="center"
          component={ButtonBase}
          onClick={handleOpen}
        >
          <Avatar alt="User" className={classes.avatar} src={avatar} />
          <Hidden smDown>
            <Typography variant="h6" color="inherit" align={"center"}>
              { (!account.firstName && !account.lastName) ? 'Пользователь' : `${account.firstName || ''} ${account.lastName || ''}`}
              {account.role === 'ADMIN' && (<><br/>Админ</>)}
            </Typography>
          </Hidden>
        </Box>
      </div>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        {/* <MenuItem component={RouterLink} to="/app/social/profile">
          Profile
        </MenuItem> */}
        <MenuItem component={RouterLink} to="/app/profile">
          Мой профиль
        </MenuItem>
        <MenuItem onClick={handleLogout}>Выйти из системы</MenuItem>
      </Menu>
    </>
  );
}

export default observer(Account);
