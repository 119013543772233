import { action, computed, decorate, observable } from 'mobx';
import ServerApi, { IResponse } from 'src/api/ServerApi';
import {
  LOCAL_STORAGE_ACCESS_TOKEN_NAME,
  LOCAL_STORAGE_COMPANY_COUNT,
  LOCAL_STORAGE_CURRENT_COMPANY
} from 'src/const/localStorage';
import JwtDecode from 'jwt-decode';
import SignInApi from 'src/api/SignInApi';

import RootStoreModel from '../_root/RootStoreModel';
import IUserDto from 'src/api/_common/dto/user/IUserDto';
import getFilePath from 'src/utils/FileUtil';
import ProfileApi from 'src/api/ProfileApi/MyProfileApi';
import ICompanyDto from 'src/api/_common/dto/company/ICompanyDto';
import CompaniesApi from 'src/api/CompaniesApi';
import IIdNameDto from 'src/api/_common/dto/IIdNameDto';

const ava = '';

interface IAuthToken {
  user: IUserDto;
  iat: number;
  exp: number;
}

export interface ICurrentCompanyModel {
  id: number;
  companyName: string;
  brandedCompanyName: string;
  isEnabled: boolean;
  ownershipType: IIdNameDto;
  icon: string;
}

function makeCCM(dto: ICompanyDto): ICurrentCompanyModel {
  return {
    id: dto.id,
    companyName: dto.companyName,
    brandedCompanyName: dto.brandedCompanyName,
    ownershipType: dto.ownershipType,
    isEnabled: dto.isEnabled,
    icon: getFilePath(dto.icon)
  };
}

class SessionStore extends RootStoreModel {
  session: IUserDto;
  company: ICurrentCompanyModel;
  availableCompanies: ICurrentCompanyModel[] = [];

  get authenticated(): boolean {
    return !!this.session;
  }

  get isSysAdmin(): boolean {
    return this.session && this.session.role === 'ADMIN';
  }

  get avatar(): string {
    return getFilePath(this.session && this.session.photo);
  }

  getCurrentUser = async (): Promise<void> => {
    await ServerApi.initialize(this.updateSession);
    this.updateSession();
    await this.loadCompany();
  };

  updateSession = () => {
    const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_NAME);
    if (token) this.session = (JwtDecode(token) as IAuthToken).user;
    else this.session = null;
  };

  loadCompany = async (): Promise<void> => {
    if (!this.session) return;
    if (!localStorage.getItem(LOCAL_STORAGE_CURRENT_COMPANY)) {
      const members = await ProfileApi.getUserCompanyList(1, 1000);
      if (members.success) {
        localStorage.setItem(
          LOCAL_STORAGE_COMPANY_COUNT,
          members.data.totalElements.toString()
        );
        if (members.data.content.length > 0) {
          this.availableCompanies = members.data.content.map(x => {
            return makeCCM(x.company);
          });
          const firstCompany = members.data.content[0].company;
          this.updateCompany(firstCompany);
        }
      }
    } else {
      const companyRes = await CompaniesApi.getCompany(
        Number(localStorage.getItem(LOCAL_STORAGE_CURRENT_COMPANY))
      );
      if (companyRes.success) this.updateCompany(companyRes.data);
    }
  };

  loadAvailableCompanies = async (): Promise<IResponse> => {
    if (this.isSysAdmin) {
      let response = await CompaniesApi.getCompanyList({
        pageNumber: 1,
        pageSize: 1000
      });
      if (response.success) {
        this.availableCompanies = response.data.content.map(x => {
          return makeCCM(x);
        });
      } else {
        this.availableCompanies = [];
      }
      return response;
    } else {
      let response = await ProfileApi.getUserCompanyList(1, 1000);
      if (response.success) {
        this.availableCompanies = response.data.content.map(x => {
          return makeCCM(x.company);
        });
      } else {
        this.availableCompanies = [];
      }
      return response;
    }
  };

  updateCompany = (company: ICompanyDto) => {
    this.updateCompany2(makeCCM(company));
  };

  updateCompany2 = (company: ICurrentCompanyModel) => {
    localStorage.setItem(LOCAL_STORAGE_CURRENT_COMPANY, company.id.toString());
    this.company = company;
  };

  login = async (email: string, password: string): Promise<IResponse> => {
    const res = await SignInApi.signIn(email, password);
    if (res.success) {
      ServerApi.updateTokenValues(res.data.accessToken, res.data.refreshToken);
      this.updateSession();
      await this.loadCompany();
    }
    return res;
  };

  logout = () => {
    ServerApi.updateTokenValues();
    this.session = null;
  };
}

decorate(SessionStore, {
  session: observable,
  company: observable,
  availableCompanies: observable,
  authenticated: computed,
  isSysAdmin: computed,
  avatar: computed,
  getCurrentUser: action,
  updateSession: action,
  loadCompany: action,
  updateCompany: action,
  updateCompany2: action,
  logout: action
});

export default SessionStore;
