import IApiRequestOptions from '../_common/request/IApiRequestOptions';
import IPageDto from '../_common/dto/IPageDto';
import ServerApi, { IResponse } from '../ServerApi';
import ICreateNotificationDto from '../_common/dto/notification/ICreateNotificationDto';
import ICreateNotificationForUserDto from '../_common/dto/notification/ICreateNotificationForUserDto';
import ICreateNotificationForCompanyDto from '../_common/dto/notification/ICreateNotificationForCompanyDto';
import INotificationDto from '../_common/dto/notification/INotificationDto';
import ICreateNotificationForPartnerDto from "../_common/dto/notification/ICreateNotificationForPartnerDto";

export default class NotificationsApi {
  static async createForAll(
    dto: ICreateNotificationDto
  ): Promise<IResponse> {
    return ServerApi.postQuery('notifications/createForAll', dto);
  }

  static async createForUser(
    dto: ICreateNotificationForUserDto
  ): Promise<IResponse> {
    return ServerApi.postQuery('notifications/createForUser', dto);
  }

  static async createForPartner(
      dto: ICreateNotificationForPartnerDto
  ): Promise<IResponse> {
    return ServerApi.postQuery('notifications/createForPartner', dto);
  }

  static async createForCompany(
    dto: ICreateNotificationForCompanyDto
  ): Promise<IResponse> {
    return ServerApi.postQuery('notifications/createForCompany', dto);
  }

  static async getNotificationList(
    apiRequestOptions: IApiRequestOptions
  ): Promise<IResponse<IPageDto<INotificationDto>>> {
    return ServerApi.getQuery('notifications/forMe', { params: apiRequestOptions });
  }

  static async getNotification(
    id: number
  ): Promise<IResponse<INotificationDto>> {
    return ServerApi.getQuery(`notifications/${id}`, null);
  }

  static async markAsRead(id: number): Promise<IResponse> {
    return ServerApi.postQuery(`users/updateLastReadNotification`, null, {
      params: { id: id },
      headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
    });
  }
}
