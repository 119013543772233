import {action, decorate, observable} from 'mobx';
import BaseListStore, {BaseListStoreDecorators} from "../../../Common/BaseListStore";
import IOfferFoodDto from "../../../../api/_common/dto/offer/IOfferFoodDto";
import OfferFoodRequestOptions from "../../../../api/_common/request/OfferFoodRequestOptions";
import {IResponse} from "../../../../api/ServerApi";
import IApiRequestOptions from "../../../../api/_common/request/IApiRequestOptions";
import IPageDto from "../../../../api/_common/dto/IPageDto";
import OffersApi from "../../../../api/OffersApi";
import IChangeMenuDto from "../../../../api/OffersApi/dto/IChangeMenuDto";
import IOfferDto from "../../../../api/_common/dto/offer/IOfferDto";
import DictionaryStore from "../../../DictionaryStore";

export type OfferFoodEditorStateEnum = 'normal' | 'added' | 'deleted' | 'touched';

class OfferFoodListStore extends BaseListStore<IOfferFoodDto, OfferFoodRequestOptions> {
    offerId: number;
    items: IOfferFoodDto[] = [];
    touchedItems: {}; // foodId (touched if quantity changed)
    addedItems:  {};

    modalOpened: boolean = false;
    modalDto?: IOfferFoodDto = {id: null, food: null, quantity: null};
    modalCategoryId?: number = null;

    public getDefaultRequestOptions(): OfferFoodRequestOptions {
        const res = new OfferFoodRequestOptions();
        res.editStatus = 'ALL';
        res.sortField = 'name,asc';
        return res;
    }

    loadDics = async (): Promise<void> => {
        let dictRes = await DictionaryStore.initializeFoodCategories();
        if (!dictRes.success) {
            this.loadingError = dictRes.data;
            return;
        }
        dictRes = await DictionaryStore.initializeFoodKitchenTypes();
        if (!dictRes.success) {
            this.loadingError = dictRes.data;
            return;
        }
    }

    protected async deleteManyRoutine(ids: number[]): Promise<IResponse> {
        this.selectedItems = [];
        ids.forEach(id => {
            this.addedItems[id] = false;
            this.touchedItems[id] = false;
        });
        this.items = this.items.filter(x => !ids.includes(x.food.id));
        return {success: true};
    }

    protected async deleteOneRoutine(id: number): Promise<IResponse> {
        return this.deleteManyRoutine([id])
    }

    private offerFoodSort = (x1: IOfferFoodDto, x2: IOfferFoodDto, ): number => {
        switch (this.requestOptions.sortField) {
            case 'price,asc': return x1.food.price - x2.food.price;
            case 'price,desc': return x2.food.price - x1.food.price;
            case 'name,asc':
            default:  return x1.food.name.toLowerCase().localeCompare(x1.food.name.toLowerCase());
        }
    }

    protected async getListRoutine(apiRequestOptions: IApiRequestOptions, options?: any): Promise<IResponse> {
        const filter = this.requestOptions.filter && this.requestOptions.filter.toLowerCase();

        let content: IOfferFoodDto[] = this.items.filter(x => {
            let b = true;
            if (filter) b = b && x.food.name.toLowerCase().indexOf(filter) >= 0;

            if (this.requestOptions.editStatus === 'added') b = b && this.addedItems[x.food.id];
            else if (this.requestOptions.editStatus === 'touched') b = b && this.touchedItems[x.food.id];

            if (this.requestOptions.categoryId) b = b && x.food.category && x.food.category.id === this.requestOptions.categoryId;
            if (this.requestOptions.kitchenTypeId) b = b && x.food.kitchenType && x.food.kitchenType.id === this.requestOptions.kitchenTypeId;

            return b;
        });

        if (this.requestOptions.sortField) {
            content = content.sort( this.offerFoodSort );
        }

        return {
            success: true,
            data: {
                pageSize: this.requestOptions.pageSize,
                pageNumber: this.requestOptions.pageNumber,
                //number: content.length,
                //size: content.length,
                totalElements: content.length,
                content: content.slice(this.requestOptions.pageSize * (this.requestOptions.pageNumber - 1), this.requestOptions.pageSize * (this.requestOptions.pageNumber))
            }
        };
    }

  imitateLoadResults = (requestOptions: OfferFoodRequestOptions): IPageDto<IOfferFoodDto> => {
      return  {
          totalElements: 0,
          totalPages: 1,
          size: 0,
          number: 0,
          content: []
      };
  };

    public async save(offerId: number) : Promise<IResponse<IOfferDto>> {
        //return OffersApi.changeMenu(this.getBodyForSave());
        return OffersApi.changeMenu(this.getBodyForSave());
    }

    public addItem(offerFoodDto: IOfferFoodDto) {
        const id = offerFoodDto.food.id;
        this.selectedItems = [];
        let found = false;
        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].food.id === id) {
                this.items[i] = {...this.items[i], quantity: Number(offerFoodDto.quantity)};
                if (!this.addedItems[id]) {
                    this.touchedItems[id] = true;
                }
                found = true;
                break;
            }
        }
        if (!found) {
            this.items.push({...offerFoodDto, id: id});
            this.addedItems[id] = true;
        }
        this.load(this.requestOptions);
    }

    public setOriginalValues(offerId: number, values: IOfferFoodDto[]) {
        this.offerId = offerId;
        this.touchedItems = {};
        this.addedItems = {};

        this.items = values;
        this.items.forEach(x => x.id = x.food.id);
    }

    public openModalFor(offerFoodDto: IOfferFoodDto) {
        this.modalOpened = true;
        this.modalDto = offerFoodDto || {id: null, food: null, quantity: 1};
        this.modalCategoryId = this.modalDto.food && this.modalDto.food.category && this.modalDto.food.category.id;
    }

    public handleModalConfirm(offerFoodDto: IOfferFoodDto) {
        this.addItem(offerFoodDto);
        this.modalOpened = false;
    }

    private getBodyForSave(): IChangeMenuDto {
        const res = {
            offerId: this.offerId,
            foods: this.items.map(x => {return {id: x.food.id, quantity: x.quantity}})
        }
        return res;
    }
}

decorate(OfferFoodListStore, {...BaseListStoreDecorators,
    items: observable,
    touchedItems: observable,
    addedItems:  observable,
    addItem: action,
    setOriginalValues: action,

    modalOpened: observable,
    modalDto: observable,
    modalCategoryId: observable,
    openModalFor: action,
});

export default OfferFoodListStore;