import React, { FunctionComponent } from 'react';
import {Field, Formik, FormikHelpers} from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  MenuItem,
  Select, TextField
} from '@material-ui/core';
import { ICurrentCompanyModel } from '../../../store/SessionStore';
import {Autocomplete} from "@material-ui/lab";

interface IFormikChangeCompany {
  companyId: number;
  submit?: string;
}

export type ChangeCompanySubmitFuncType = (
  values: IFormikChangeCompany,
  formikHelpers: FormikHelpers<IFormikChangeCompany>
) => Promise<void>;

interface IChangeCompanyModalProps {
  initialCompanyId: number;
  availableCompanies: ICurrentCompanyModel[];
  open: boolean;
  onClose: () => void;
  onSubmit: ChangeCompanySubmitFuncType;
}

const ModalComponent: FunctionComponent<IChangeCompanyModalProps> = ({
  initialCompanyId,
  availableCompanies,
  open,
  onClose,
  onSubmit
}) => {
  return (
    <Dialog
      aria-labelledby="employee-dialog-title"
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle id="employee-dialog-title">
        Сменить текущую компанию
      </DialogTitle>
      <Formik<IFormikChangeCompany>
        initialValues={{ companyId: initialCompanyId }}
        onSubmit={onSubmit}
      >
        {({ handleChange, handleSubmit, isSubmitting, values, setFieldValue }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Card>
              <CardContent>
                <Autocomplete
                    className=""
                    noOptionsText={'Не найдено'}
                    options={availableCompanies}
                    getOptionLabel={option => `[ID:${option.id}] ${option.brandedCompanyName} (${option.companyName})`}
                    defaultValue={ availableCompanies.filter(x => x.id === initialCompanyId)[0] }
                    onChange={(e, value) => {
                      setFieldValue("companyId", (value as ICurrentCompanyModel) && (value as ICurrentCompanyModel).id)
                    }}
                    renderInput={params => (
                        <Field component={TextField} {...params} label="Компания" variant="outlined" fullWidth/>
                    )}
                />
                <Box mt={2}>
                  <Button
                    color="secondary"
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Сменить
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ModalComponent;
