import React, { FunctionComponent } from 'react';
import useStore from "../context/RootStoreContext";
import ScriptTag from 'react-script-tag';
import {observer} from "mobx-react";

const Bitrix = () => {
  const store = useStore();
  const authentificated = store.sessionStore.authenticated;

  return (<>
      {authentificated && <ScriptTag type="text/javascript" src="/static/bitrix.js" />}
  </>);
};

export default observer(Bitrix);
