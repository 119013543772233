import BaseRequestOptions from "./BaseRequestOptions";
import IApiRequestOptions from "./IApiRequestOptions";

export default class CompanyNewsRequestOptions extends BaseRequestOptions {

    constructor() {
        super();
        this.sortField = 'id,desc';
    }

}
