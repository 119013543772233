import {decorate, observable} from 'mobx';
import IPageDto from "../../api/_common/dto/IPageDto";
import {IResponse} from "../../api/ServerApi";
import UserRequestOptions from "../../api/_common/request/UserRequestOptions";
import UsersApi from "../../api/UsersApi";
import IUserForGridDto from "../../api/_common/dto/user/IUserForGridDto";
import BaseListStore, {BaseListStoreDecorators} from "../Common/BaseListStore";
import IApiRequestOptions from "../../api/_common/request/IApiRequestOptions";
import ICompanyForGridDto from "../../api/_common/dto/company/ICompanyForGridDto";
import CompaniesApi from "../../api/CompaniesApi";
import DictionaryStore from "../DictionaryStore";

class UserListStore extends BaseListStore<IUserForGridDto, UserRequestOptions> {

    protected getDefaultRequestOptions(): UserRequestOptions {
        const res = new UserRequestOptions();
        res.sortField = 'createdAt,desc';
        return res;
    }

    loadDics = async (): Promise<void> => {
        const res = await DictionaryStore.initializeLocations();
        if (!res.success) this.loadingError = res.message;
    };

    protected async deleteManyRoutine(ids: number[]): Promise<IResponse> {
        return UsersApi.deleteMany(ids);
    }

    protected async deleteOneRoutine(id: number): Promise<IResponse> {
        return UsersApi.deleteOne(id);
    }

    protected async getListRoutine(apiRequestOptions: IApiRequestOptions, options?: any): Promise<IResponse> {
        return UsersApi.getUserList(apiRequestOptions);
    }

    disableUser = async (id: number): Promise<IResponse> => {
        let obj: IUserForGridDto = this.page.content.filter(item => {return item.id === id})[0];
        if (!obj) return;
        obj.isUpdating = true;
        const response = await UsersApi.disableUser(id);
        if (response.success) obj.isEnabled = false;
        obj.isUpdating = false;
        return response
    };

    enableUser = async (id: number): Promise<IResponse> => {
        let obj: IUserForGridDto = this.page.content.filter(item => {return item.id === id})[0];
        if (!obj) return;
        obj.isUpdating = true;
        const response = await UsersApi.enableUser(id);
        if (response.success) obj.isEnabled = true;
        obj.isUpdating = false;
        return response
    };

  imitateLoadResults = (requestOptions: UserRequestOptions): IPageDto<IUserForGridDto> => {
      return  {
          totalElements: 0,
          totalPages: 1,
          size: 0,
          number: 0,
          content: []
      };
  };

}

decorate(UserListStore, BaseListStoreDecorators);

export default UserListStore;