/* eslint-disable max-len */
import React from 'react';
import {Button, SvgIcon, TableCell} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {PlusCircle as PlusCircleIcon} from "react-feather";
import {makeStyles} from "@material-ui/core/styles";
import {CateringTheme} from "../../theme/type";
import Label from "../Label";
import {OrderStatusCollection} from "../../api/_common/enum/OrderStatusEnum";

function OrderStatusLabel({
    value,
    ...rest
}) {
    let stateTitle = "";
    let stateColor = "";
    const collectionItem = OrderStatusCollection.filter(x => {return x.status === value})[0];
    if (collectionItem) {
        stateTitle = collectionItem.label;
        stateColor = collectionItem.color;
    }

  return <Label color={stateColor} style={""} className={""} >
        {stateTitle}
    </Label>
}

export default OrderStatusLabel;
