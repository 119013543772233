import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import useStore from 'src/context/RootStoreContext';

const PrivateRoute: FunctionComponent<RouteProps> = ({
  component,
  ...rest
}) => {
  const { authenticated } = useStore().sessionStore;
  const Component = component as React.ComponentType;

  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default observer(PrivateRoute);
