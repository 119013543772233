import BaseRequestOptions from "./BaseRequestOptions";
import {ProposalStatusEnum} from "../enum/ProposalStatusEnum";
import IApiRequestOptions from "./IApiRequestOptions";
import {OfferTypeEnum} from "../enum/OfferTypeEnum";

export default class FoodRequestOptions extends BaseRequestOptions {
  categoryId?: number;
  kitchenTypeId?: string;
  offerType?: OfferTypeEnum;
  isPersonal?: boolean;
  useDatesActive?: boolean;
  active?: boolean;

    constructor() {
        super();
        this.pageSize = 25;
    }

    asGetParams(): IApiRequestOptions {
        let res = super.asGetParams();

        let paramsArr: string[] = [];
        const filterParam = res.search;
        if (filterParam) paramsArr.push(filterParam);

        if (this.categoryId) paramsArr.push("category," + this.categoryId);
        if (this.kitchenTypeId) paramsArr.push("kitchenTypeId," + this.kitchenTypeId);
        if (this.offerType !== null && this.offerType !== undefined) paramsArr.push("offerType," + this.offerType);
        if (this.useDatesActive === true) paramsArr.push("useDatesActive,true");

        if (this.active === true) paramsArr.push("active,true")
        else paramsArr.push("active,false");

        if (paramsArr.length) res.search = paramsArr.join(";");

        return res;
    };

    protected makeSearchParam(): string {
        return 'text,'+(this.filter?this.filter : '');
    }

}
