import React, { FunctionComponent } from 'react';
import { Formik } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';

import {
  Box,
  Button,
  TextField,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import useStore from 'src/context/RootStoreContext';

interface ILoginFormProps {
  className?: string;
  onSubmitSuccess: () => void;
}

const useStyles = makeStyles(() => ({
  root: {}
}));

const LoginForm: FunctionComponent<ILoginFormProps> = ({
  className,
  onSubmitSuccess,
  ...rest
}) => {
  const classes = useStyles();
  const { login } = useStore().sessionStore;
  return (
    <Formik<{ email: string; password: string; submit?: string }>
      initialValues={{ email: '', password: '' }}
      validationSchema={Yup.object().shape<{ email: string; password: string }>(
        {
          email: Yup.string()
            .email('Неверный формат Email')
            .max(255)
            .required('Email не указан'),
          password: Yup.string()
            .max(255)
            .required('Пароль не указан')
        }
      )}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const result = await login(values.email, values.password);
        console.log(result);
        if (result.success) {
          setErrors({});
          onSubmitSuccess();
        } else {
          let mes;
          if (result.details && result.details.password)
            mes = 'Неверный логин или пароль';
          else if (result.message) mes = result.message;
          else mes = 'Не удалось войти в аккаунт';
          setStatus({ success: false });
          setErrors({ submit: mes });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Пароль"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Box mt={2}>
            <Button
              color="secondary"
              disabled={isSubmitting}
              // fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Войти
            </Button>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default React.memo(LoginForm);
