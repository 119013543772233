import {decorate} from 'mobx';
import BaseReviewStore, {BaseReviewStoreDecorators} from "../../../Common/BaseReviewStore";
import IApiRequestOptions from "../../../../api/_common/request/IApiRequestOptions";
import OffersApi from "../../../../api/OffersApi";
import IReviewDto from "../../../../api/_common/dto/IReviewDto";
import CompanyReviewTabViewModel from "../../../CompanyProfileStore/tab/CompanyReviewViewModel";
import {IResponse} from "../../../../api/ServerApi";
import IPageDto from "../../../../api/_common/dto/IPageDto";

class OfferReviewTabViewModel extends BaseReviewStore {

    protected async getReviews(apiRequestOptions: IApiRequestOptions): Promise<IResponse<IPageDto<IReviewDto>>> {
        return await OffersApi.getReviews(this.id, apiRequestOptions);
    }

}

decorate(OfferReviewTabViewModel, {...BaseReviewStoreDecorators,
});

export default OfferReviewTabViewModel;
