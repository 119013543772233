import React, {FunctionComponent} from 'react';
import {Box} from '@material-ui/core';
import Logo from "../../components/Logo";
import useStore from "../../context/RootStoreContext";
import CompanyInactiveView from "./CompanyInactiveView";

const LogoView: FunctionComponent = () => {
    const {sessionStore} = useStore();

    if (!sessionStore.company) return <span>...</span>;
    if (!sessionStore.company.isEnabled) return <CompanyInactiveView/>;

  return (

      <Box display="flex" justifyContent="center" mt={6} alignContent={'center'}>
              <Box display="flex" justifyContent="center" mt={6} alignContent={'center'}>
                  <Logo/>
              </Box>
      </Box>
  );
};

export default LogoView;
