import lodash from 'lodash';
import {
  createMuiTheme,
  colors,
  responsiveFontSizes,
  ThemeOptions,
  Theme
} from '@material-ui/core';
import typography from './typography';
import { softShadows } from './shadows';
import { CateringThemeConfigType } from './type';

const baseConfig: ThemeOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
};

const themeConfig: CateringThemeConfigType = {
  name: 'LIGHT',
  overrides: {
    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: colors.blueGrey[600]
        }
      }
    }
  },
  palette: {
    type: 'light',
    action: {
      active: colors.blueGrey[600]
    },
    background: {
      default: colors.common.white,
      dark: '#f4f6f8',
      paper: colors.common.white
    },
    primary: {
      main: '#7e55a3'
    },
    secondary: {
      main: '#7e55a3'
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows: softShadows
};

export function createTheme(): Theme {
  const theme = responsiveFontSizes(
    createMuiTheme(lodash.merge({}, baseConfig, themeConfig))
  );
  return theme;
}
