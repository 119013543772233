import React, { FunctionComponent } from 'react';

interface ILogoProps {
  className?: string;
  topbar?: boolean;
}

const Logo: FunctionComponent<ILogoProps> = ({ topbar, ...props }) => {
  return (
    <img alt="Logo" src={topbar ? '/static/logo-main.svg' : '/static/logo.svg'} {...props} />
  );
};

export default React.memo(Logo);
