import React, {FunctionComponent, useEffect, useState} from 'react';
import SplashScreen from './SplashScreen';
import useStore from 'src/context/RootStoreContext';
import {observer} from "mobx-react";

const Auth: FunctionComponent = props => {
  const [isLoading, setLoading] = useState(true);
  const { sessionStore } = useStore();
  const { getCurrentUser } = sessionStore;
  useEffect(() => {
    const initAuth = async () => {
      await getCurrentUser();
      setLoading(false);
    };
    initAuth();
  }, [getCurrentUser]);

  const session = sessionStore.session;
  if (session && !(session.role === 'ADMIN' || session.role === 'PARTNER')) {
    console.debug('Auth redirect...');
    const oldHref = window.location.href;
    let newHref = oldHref
        .replace("serg.", "")
        .replace("partners.", "");
    newHref = newHref.slice(0, newHref.substring(9, newHref.length).indexOf("/")+9);
    window.location.href = newHref;
  }

  if (isLoading) return <SplashScreen />;
  return <>{props.children}</>;
};

export default observer(Auth);
