import IPersonalDataRequestDto from '../request/IPersonalDataRequestDto';
import ServerApi, { IResponse } from '../../ServerApi';
import IUserDto from '../../_common/dto/user/IUserDto';
import IChangePasswordRequestDto from '../request/IChangePasswordRequestDto';
import INotificationSettingsRequestDto from '../request/INotificationSettingsRequestDto';
import IPageDto from '../../_common/dto/IPageDto';
import ICompanyDto from '../../_common/dto/company/ICompanyDto';
import IUserCompanyDto from '../../_common/dto/company/IUserCompanyDto';

export default class MyProfileApi {
  static async editPersonalData(
    body: IPersonalDataRequestDto
  ): Promise<IResponse<IUserDto>> {
    return ServerApi.postQuery('users/profile/personalData', body);
  }

  static async changePassword(
    body: IChangePasswordRequestDto
  ): Promise<IResponse> {
    return ServerApi.postQuery('users/profile/changePassword', body);
  }

  static async changeNotificationSettings(
    body: INotificationSettingsRequestDto
  ): Promise<IResponse> {
    return ServerApi.postQuery('users/profile/notificationSettings', body);
  }

  static async getUserCompanyList(
    pageNumber = 1,
    pageSize = 10
  ): Promise<IResponse<IPageDto<IUserCompanyDto>>> {
    const uri = `users/profile/myCompanies?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return ServerApi.getQuery(uri);
  }

  static async getPhoto(): Promise<IResponse> {
    return ServerApi.getQuery('users/profile/photo');
  }

  static async changePhoto(photo: File): Promise<IResponse> {
    const fd = new FormData();
    fd.append('file', photo);
    return ServerApi.postQuery('users/profile/photo', fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  static async deletePhoto(): Promise<IResponse> {
    return ServerApi.deleteQuery('users/profile/photo');
  }

  static async deleteMyProfile(): Promise<IResponse> {
    return ServerApi.postQuery('users/profile/remove', null);
  }
}
