import BaseTabViewModel from 'src/store/Common/BaseTabViewModel';
import IPhotoWithDescriptionDto from 'src/api/_common/dto/IPhotoWithDescriptionDto';
import { IDropdownFileItem } from 'src/components/common-form/FilesDropdownForm/type';
import GoodsApi from 'src/api/ServicesApi/GoodsApi';
import { IResponse } from 'src/api/ServerApi';
import { decorate, observable, computed, action } from 'mobx';

class GoodsProfileFilesTabViewModel extends BaseTabViewModel {
  goodsImages: IPhotoWithDescriptionDto[] = [];
  processing = false;

  get images(): IDropdownFileItem[] {
    if (!this.goodsImages) return [];
    return this.goodsImages.map(x => ({
      file: x.fileInfo,
      description: x.description,
      id: x.id
    }));
  }

  protected async initializeTab(): Promise<void> {
    const res = await GoodsApi.getGoodsImages(this.id);
    if (res.success) {
      this.goodsImages = res.data || [];
      this.initialized = true;
    } else this.loadingError = res.message;
  }

  addImage = async (file: File): Promise<IResponse> => {
    if (this.processing) return;
    this.processing = true;
    const res = await GoodsApi.addGoodsImage(this.id, file);
    this.processing = false;
    if (res.success) this.goodsImages.push(res.data);
    return res;
  };

  deleteImage = async (id: number): Promise<IResponse> => {
    if (this.processing) return;
    this.processing = true;
    const res = await GoodsApi.deleteGoodsImage(this.id, id);
    this.processing = false;
    if (res.success) {
      this.goodsImages = this.goodsImages.filter(x => x.id !== id);
    }
    return res;
  };

  changeImageDescription = async (
    id: number,
    description: string
  ): Promise<IResponse> => {
    if (this.processing) return;
    this.processing = true;
    const res = await GoodsApi.changeGoodsImageDescription(
      this.id,
      id,
      description.trim()
    );
    this.processing = false;
    if (res.success) {
      this.goodsImages.find(x => x.id === id).description = res.data.description;
    }
    return res;
  };
}

decorate(GoodsProfileFilesTabViewModel, {
  goodsImages: observable,
  images: computed,
  addImage: action,
  deleteImage: action,
  changeImageDescription: action
});

export default GoodsProfileFilesTabViewModel;
