import React, {FunctionComponent, lazy, Suspense} from 'react';
import LoadingScreen from './components/LoadingScreen';
import {Redirect, Route, Switch} from 'react-router';
import GuestRoute from './components/route/GuestRoute';
import PrivateRoute from './components/route/PrivateRoute';
import DashboardLayout from './layouts/DashboardLayout';
import AuthLayout from './layouts/AuthLayout';

import LoginView from './views/auth/LoginView';
import RegisterView from './views/auth/RegisterView';
import ForgotPasswordView from './views/auth/ForgotPasswordView';
import ResetPasswordView from './views/auth/ResetPasswordView';
import AdminRoute from './components/route/AdminRoute';
import ApproveInviteView from './views/auth/ApproveInviteView';
import LogoView from "./views/pages/LogoView";
import DashboardView from "./views/DashboardView";

const UnderConstructionView = lazy(() =>
  import('src/views/pages/UnderConstructionView')
);
const Error404View = lazy(() => import('src/views/pages/Error404View'));

const Routes: FunctionComponent = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Redirect exact from="/" to="/app" />
        <Route exact path="/404" component={Error404View} />
        <GuestRoute
          exact
          path="/signin"
          component={props => (
            <AuthLayout {...props}>
              <LoginView />
              {/* <Suspense fallback={<LoadingScreen />}>
                {lazy(() => import('src/views/auth/LoginView'))}
              </Suspense> */}
            </AuthLayout>
          )}
        />
        <GuestRoute
          exact
          path="/signup"
          component={props => (
            <AuthLayout {...props}>
              <RegisterView {...props} />
              {/* <Suspense fallback={<LoadingScreen />}>
                {lazy(() => import('src/views/auth/LoginView'))}
              </Suspense> */}
            </AuthLayout>
          )}
        />
        <GuestRoute
          exact
          path="/forgot-password"
          component={props => (
            <AuthLayout {...props}>
              <ForgotPasswordView />
            </AuthLayout>
          )}
        />
        <GuestRoute
          exact
          path="/tokens/:token/resetPassword"
          component={props => (
            <AuthLayout {...props}>
              <ResetPasswordView {...props} />
            </AuthLayout>
          )}
        />
        <GuestRoute
          exact
          path="/companies/:token/approveInvite"
          component={props => (
            <AuthLayout {...props}>
              <ApproveInviteView {...props} />
            </AuthLayout>
          )}
        />
        <GuestRoute
          exact
          path="/verify-account"
          component={lazy(() => import('src/views/auth/VerifyAccountView'))}
        />
        <AdminRoute
          path="/admin"
          component={props => (
            <DashboardLayout {...props}>
              <Suspense fallback={<LoadingScreen />}>
                <Switch>
                  <Route
                    exact
                    path="/admin/users"
                    component={lazy(() =>
                      import('src/views/admin/User/UserListView')
                    )}
                  />
                  <Route
                    exact
                    path="/admin/companies"
                    component={lazy(() =>
                      import('src/views/admin/CompanyListView')
                    )}
                  />
                  <Route
                      exact
                      path="/admin/user/:id"
                      component={lazy(() =>
                          import('src/views/admin/User/UserProfileView')
                      )}
                  />
                </Switch>
              </Suspense>
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          path="/app"
          component={props => (
            <DashboardLayout {...props}>
              <Suspense fallback={<LoadingScreen />}>
                <Switch>
                  {/*<Route exact path="/app" component={UnderConstructionView} />*/}
                  <Route exact path="/app" component={DashboardView /*LogoView*/} />

                  <Route
                    exact
                    path="/app/profile"
                    component={lazy(() =>
                      import('src/views/pages/AccountView')
                    )}
                  />
                  {/*временно главная страница*/}
                  <Route
                      exact
                      path={[
                          //'/app',
                          '/app/requests']}
                      component={lazy(() =>
                          import('src/views/pages/Proposal/ProposalListView')
                      )}
                  />
                  <Route
                      exact
                      path={['/app/requests/edit/:id', '/app/proposals/edit/:id']}
                      component={lazy(() =>
                          import('src/views/pages/Proposal/ProposalProfileView')
                      )}
                  />
                  <Route
                    exact
                    path="/app/company/:id"
                    component={lazy(() =>
                      import('src/views/pages/CompanyProfileView')
                    )}
                  />
                  <Route
                    exact
                    path="/app/trades"
                    component={lazy(() =>
                      import('src/views/pages/Trade/TradeListView')
                    )}
                  />
                  <Route
                    exact
                    path={['/app/trades/edit/:id', '/app/trades/add']}
                    component={lazy(() =>
                      import('src/views/pages/Trade/TradeProfileView')
                    )}
                  />

                  <Route
                    exact
                    path="/app/orders"
                    component={lazy(() =>
                      import('src/views/pages/Order/OrderListView')
                    )}
                  />
                    <Route
                        exact
                        path='/app/orders/edit/:id'
                        component={lazy(() =>
                            import('src/views/pages/Order/OrderProfileView')
                        )}
                    />

                  <Route
                    exact
                    path="/app/services"
                    component={lazy(() =>
                      import('src/views/pages/Service/ServiceListView')
                    )}
                  />
                  <Route
                    exact
                    path={[
                      '/app/services/food/edit/:id',
                      '/app/services/food/add'
                    ]}
                    component={lazy(() =>
                      import(
                        'src/views/pages/Service/ServiceProfileView/FoodProfileView'
                      )
                    )}
                  />
                    <Route
                        exact
                        path={[
                            '/app/services/goods/edit/:id',
                            '/app/services/goods/add'
                        ]}
                        component={lazy(() =>
                            import(
                                'src/views/pages/Service/ServiceProfileView/GoodsProfileView'
                                )
                        )}
                    />
                  <Route
                      exact
                      path="/app/offer"
                      component={lazy(() =>
                          import('src/views/pages/Offer/OfferListView')
                      )}
                  />
                  <Route
                      exact
                      path={['/app/offer/edit/:id', '/app/offer/add']}
                      component={lazy(() =>
                          import('src/views/pages/Offer/OfferProfileView')
                      )}
                  />
                  <Route
                      exact
                      path={['/app/offer/edit/:id/forProposal/:proposalId', '/app/offer/add/forProposal/:proposalId']}
                      component={lazy(() =>
                          import('src/views/pages/Offer/OfferProfileView')
                      )}
                  />

                    <Route
                        exact
                        path="/app/companyNews"
                        component={lazy(() =>
                            import('src/views/pages/CompanyNews/CompanyNewsListView')
                        )}
                    />
                    <Route
                        exact
                        path={['/app/companyNews/edit/:id', '/app/companyNews/add']}
                        component={lazy(() =>
                            import('src/views/pages/CompanyNews/CompanyNewsProfileView')
                        )}
                    />

                    <Route
                        exact
                        path="/app/notifications"
                        component={lazy(() =>
                            import('src/views/pages/Notification/NotificationListView')
                        )}
                    />
                </Switch>
              </Suspense>
            </DashboardLayout>
          )}
        />
        <PrivateRoute exact path="/test" component={UnderConstructionView} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
