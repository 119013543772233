import {action, decorate, observable} from 'mobx';
import BaseReviewStore, {BaseReviewStoreDecorators} from "../../Common/BaseReviewStore";
import IApiRequestOptions from "../../../api/_common/request/IApiRequestOptions";
import IPageDto from "../../../api/_common/dto/IPageDto";
import IReviewDto from "../../../api/_common/dto/IReviewDto";
import CompaniesApi from "../../../api/CompaniesApi";
import {IResponse} from "../../../api/ServerApi";

class CompanyReviewTabViewModel extends BaseReviewStore {

    protected async getReviews(apiRequestOptions: IApiRequestOptions): Promise<IResponse<IPageDto<IReviewDto>>> {
        return await CompaniesApi.getReviews(this.id, apiRequestOptions);
    }

}

decorate(CompanyReviewTabViewModel, {...BaseReviewStoreDecorators,
});

export default CompanyReviewTabViewModel;
