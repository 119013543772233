import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Typography,
  makeStyles, Link, Tooltip, CircularProgress
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {Link as RouterLink} from "react-router-dom";
import {Clipboard as ClipboardIcon, Icon} from "react-feather";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  avatar: {
    backgroundColor: theme.palette.secondary.contrastText,
    color: theme.palette.secondary.main,
    height: 48,
    width: 48
  }
}));

function BlackWindow({ className, title, value, icon, url, tooltip, ...rest }) {
  const classes = useStyles();

  const isLoading = typeof value === 'undefined';
  const loadingError = value === null;

  const IconComponent: Icon = icon;

  // @ts-ignore
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box flexGrow={1}>
        <Typography
          color="inherit"
          component="h3"
          gutterBottom
          variant="overline"
        >
          {title}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
        >
          {isLoading ?
              <CircularProgress/>
            :
              <Typography
                  color="inherit"
                  variant="h3"
              >
                {loadingError ? '?' : value}
              </Typography>
          }
        </Box>
      </Box>
      {url ?
          <Tooltip title={tooltip}>
            <Link
                component={RouterLink}
                to={url}
            >
              <Avatar className={classes.avatar}>
                {IconComponent && <IconComponent/>}
              </Avatar>
            </Link>
          </Tooltip>
          :
          <Avatar
              className={classes.avatar}
              color="inherit"
          >
            {IconComponent && <IconComponent/>}
          </Avatar>
      }
    </Card>
  );
}

BlackWindow.propTypes = {
  className: PropTypes.string,
};

export default BlackWindow;
