import IApiRequestOptions from "./IApiRequestOptions";

export default class BaseRequestOptions{
  pageNumber?: number;
  pageSize?: number;
  filter?: string;
  sortField?: string;

  constructor() {
      this.pageNumber = 1;
      this.pageSize = 10;
  }

  asGetParams(): IApiRequestOptions {
    return {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        search: this.makeSearchParam(),
        sort: this.makeSortParam()
    };
  };

  protected filterAsKeyValue(): {key: string, value: string} {
      if (!this.filter) return null;
      let arr = this.filter.split(",")
      return {key: arr[0], value: arr[1]};
  }

  protected makeSearchParam(): string {
      if (!this.filter) return null;
      return 'text,'+this.filter;
  }

  protected makeSortParam(): string {
      return this.sortField;
  }

}
