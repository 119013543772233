import React from 'react';
import {Router} from 'react-router-dom';
import history from 'src/history';
import {create} from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import {SnackbarProvider} from 'notistack';
import {createStyles, jssPreset, makeStyles, StylesProvider, ThemeProvider} from '@material-ui/core';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
// import Auth from 'src/components/Auth';
// import CookiesNotification from 'src/components/CookiesNotification';
// import SettingsNotification from 'src/components/SettingsNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import {createTheme} from 'src/theme';
import Auth from './components/Auth';
import Routes from './Routes';
import RootStore from 'src/store/_root';
import {RootContextProvider} from './context/RootStoreContext';
import AlertDialog from "./views/modals/AlertDialog";
import InfoDialog from "./views/modals/InfoDialog";
import DeleteWithDescriptionDialog from "./views/modals/DeleteWithDescriptionDialog";
import AddQuestionDialog from "./views/modals/AddQuestionDialog";
import UserInfoDialog from "./views/modals/UserInfoDialog";
import Bitrix from "./components/Bitrix";
import YaMetrika from "./components/YaMetrika";
import NotificationProfileDialog from "./views/pages/Notification/NotificationProfileDialog";
import FileSelectDialog from "./views/modals/FileSelectDialog";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%'
      }
    }
  })
);

const store = new RootStore();

function App() {
  useStyles();

  return (
    <RootContextProvider value={store.getStore}>
      <YaMetrika/>
      <ThemeProvider theme={createTheme()}>
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider maxSnack={1}>
              <Auth>
                <Bitrix/>
                <Router history={history}>
                  <AlertDialog/>
                  <InfoDialog/>
                  <UserInfoDialog/>
                  <DeleteWithDescriptionDialog/>
                  <AddQuestionDialog/>
                  <FileSelectDialog/>
                  <NotificationProfileDialog/>
                  <ScrollReset />
                  <GoogleAnalytics />
                  {/* <CookiesNotification />
                <SettingsNotification /> */}
                  <Routes />
                </Router>
              </Auth>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </RootContextProvider>
  );
}

export default App;
