import IEditCompanyNewsRequestDto from './request/IEditCompanyNewsRequestDto';
import IPhotoWithDescriptionDto from '../_common/dto/IPhotoWithDescriptionDto';
import IApiRequestOptions from '../_common/request/IApiRequestOptions';
import IPageDto from '../_common/dto/IPageDto';
import ServerApi, { IResponse } from '../ServerApi';
import ICompanyNewsForGridDto from '../_common/dto/companyNews/ICompanyNewsForGridDto';

export default class CompanyNewsApi {
  static async getNewsList(companyId: number,
    apiRequestOptions: IApiRequestOptions): Promise<IResponse<IPageDto<ICompanyNewsForGridDto>>> {
    return ServerApi.getQuery(`companies/${companyId}/news`, { params: apiRequestOptions });
  }

  static async deleteOne(companyId: number, id: number): Promise<IResponse<any>> {
    return ServerApi.deleteQuery(`companies/${companyId}/news/${id}`, null);
  }

  static async deleteMany(ids: number[]): Promise<IResponse<any>> {
    return ServerApi.postQuery('companies/news/removeMultiple', null, {
      params: {
        companyIds: ids && ids.join(',')
      },
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    });
  }

  static async getNews(companyId: number, newsId: number): Promise<IResponse<ICompanyNewsForGridDto>> {
    return ServerApi.getQuery(`companies/${companyId}/news/${newsId}`);
  }

  static async saveNews(
    companyId: number,
    dto: IEditCompanyNewsRequestDto
  ): Promise<IResponse<ICompanyNewsForGridDto>> {
    if (dto.id) {
      return ServerApi.putQuery(`companies/${companyId}/news/${dto.id}`, dto);
    }
    return ServerApi.postQuery(`companies/${companyId}/news`, dto);
  }

  // images

  static async getImages(
    companyId: number,
    newsId: number
  ): Promise<IResponse<IPageDto<IPhotoWithDescriptionDto>>> {
    return ServerApi.getQuery(`companies/${companyId}/news/${newsId}/images`);
  }

  static async addImage(
    companyId: number,
    newsId: number,
    file: File
  ): Promise<IResponse<IPhotoWithDescriptionDto>> {
    return ServerApi.uploadFileQuery(`companies/${companyId}/news/${newsId}/images`, file);
  }

  static async deleteImage(
    companyId: number,
    newsId: number,
    imageId: number
  ): Promise<IResponse<IPageDto<IPhotoWithDescriptionDto>>> {
    return ServerApi.deleteQuery(`companies/${companyId}/news/${newsId}/images/${imageId}`);
  }

  static async changeImageDescription(
    companyId: number,
    newsId: number,
    imageId: number,
    description: string
  ): Promise<IResponse<IPhotoWithDescriptionDto>> {
    return ServerApi.postQuery(
      `companies/${companyId}/news/${newsId}/images/${imageId}/description`,
      {
        description
      }
    );
  }

  static async setImageAsMain(
    companyId: number,
    newsId: number,
    imageId: number,
  ): Promise<IResponse<IPhotoWithDescriptionDto>> {
    return ServerApi.postQuery(
      `companies/${companyId}/news/${newsId}/images/${imageId}/makeMain`,
      null
    );
  }
}
