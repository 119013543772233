import {decorate} from 'mobx';
import IPageDto from "../../api/_common/dto/IPageDto";
import {IResponse} from "../../api/ServerApi";
import BaseListStore, {BaseListStoreDecorators} from "../Common/BaseListStore";
import IApiRequestOptions from "../../api/_common/request/IApiRequestOptions";
import CompanyRequestOptions from "../../api/_common/request/CompanyRequestOptions";
import CompaniesApi from "../../api/CompaniesApi";
import ICompanyForGridDto from "../../api/_common/dto/company/ICompanyForGridDto";

class CompanyListStore extends BaseListStore<ICompanyForGridDto, CompanyRequestOptions> {

    protected getDefaultRequestOptions(): CompanyRequestOptions {
        const res = new CompanyRequestOptions();
        res.sortField = 'companyName,asc';
        return res;
    }

    protected async deleteManyRoutine(ids: number[]): Promise<IResponse> {
        return CompaniesApi.deleteMany(ids);
    }

    protected async deleteOneRoutine(id: number, description: string): Promise<IResponse> {
        return CompaniesApi.deleteOne(id, description);
    }

    protected async getListRoutine(apiRequestOptions: IApiRequestOptions, options?: any): Promise<IResponse> {
        return CompaniesApi.getCompanyList(apiRequestOptions);
    }

    disableCompany = async (id: number): Promise<IResponse> => {
        let obj: ICompanyForGridDto = this.page.content.filter(item => {return item.id === id})[0];
        if (!obj) return;
        obj.isUpdating = true;
        const response = await CompaniesApi.disableCompany(id);
        if (response.success) obj.isEnabled = false;
        obj.isUpdating = false;
        return response
    };

    enableCompany = async (id: number): Promise<IResponse> => {
        let obj: ICompanyForGridDto = this.page.content.filter(item => {return item.id === id})[0];
        if (!obj) return;
        obj.isUpdating = true;
        const response = await CompaniesApi.enableCompany(id);
        if (response.success) obj.isEnabled = true;
        obj.isUpdating = false;
        return response
    };

  imitateLoadResults = (requestOptions: CompanyRequestOptions): IPageDto<ICompanyForGridDto> => {
      return  {
          totalElements: 0,
          totalPages: 1,
          size: 0,
          number: 0,
          content: []
      };
  };

}

decorate(CompanyListStore, BaseListStoreDecorators);

export default CompanyListStore;