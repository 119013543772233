import React, {useEffect, useState} from 'react';
import {Container, Grid, makeStyles} from '@material-ui/core';
import Page from 'src/components/Page';
import Header from './Header';
import useStore from "../../context/RootStoreContext";
import {observer} from "mobx-react";
import {
  Briefcase as BrifcaseIcon,
  Clipboard as ClipboardIcon,
  Layout as LayoutIcon,
  Map as MapIcon
} from 'react-feather';
import BlackWindow from "./BlackWindow";
import LogoView from "../pages/LogoView";
import TodayWindow from "./TodayWindow";
import TodayOrderList from "./TodayOrderList";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default, //dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64
    }
  }
}));

function DashboardView() {
  const classes = useStyles();
  const { dashboardStore, sessionStore } = useStore();
  const {
    ordersCountToday, ordersCountTotal, ordersToday,
    proposalsCountToday, proposalsCountTotal, proposalsToday,
    tradesCountTotal, offersCountTotal, companyMemberCount
  } = dashboardStore;
  const { isSysAdmin, company } = sessionStore;

  const [initStarted, setInitStarted] = useState(false);

  useEffect(() => {
    if (!initStarted && company) {
      setInitStarted(true);
      dashboardStore.initialize();
    }
  }, [company]);

  if (!company) return <LogoView/>;
  if (!isSysAdmin) return <LogoView/>;

  return (
    <Page
      className={classes.root}
      title="Главная"
    >
      <Container
        maxWidth={false}
        className={classes.container}
      >
        <Header className={""} />
        <Grid
          container
          spacing={3}
        >
          <Grid item lg={3} sm={6} xs={12}>
            <TodayWindow value={ordersCountTotal} difference={ordersCountToday}
                title={'Заказы'} tooltip={'Перейти к заказам'} url={'/app/orders'}
                         icon={ClipboardIcon} className={""} />
          </Grid>

          <Grid item lg={3} sm={6} xs={12} >
            <TodayWindow value={proposalsCountTotal} difference={proposalsCountToday}
                         title={'Заявки'} tooltip={'Перейти к заявкам'} url={'/app/requests'}
                         icon={LayoutIcon} className={""} />
          </Grid>

          <Grid item lg={3} sm={4} xs={12} >
            <BlackWindow className={""} title={"Предложения"} value={offersCountTotal}
                         icon={BrifcaseIcon} url={"/app/offer"} tooltip={"Перейти к предложениям"}
            />
          </Grid>

          <Grid item lg={3} sm={4} xs={12} >
            <BlackWindow className={""} title={"Площадки"} value={tradesCountTotal}
                         icon={MapIcon} url={"/app/trades"} tooltip={"Перейти к площадкам"}
            />
          </Grid>

          <Grid item lg={3} sm={4} xs={12} >
            <BlackWindow className={""} title={"Сотрудники"} value={companyMemberCount} icon={null} url={""} tooltip={""} />
          </Grid>

          <Grid item xs={12} >
            <TodayOrderList orders={ordersToday} />
          </Grid>

        </Grid>
      </Container>
    </Page>
  );
}

export default observer(DashboardView);
