import {action, decorate, observable} from 'mobx';
import RootStoreModel from "../_root/RootStoreModel";
import FrontApi from "../../api/FrontApi";
import {delay} from "q";
import IUserDto from "../../api/_common/dto/user/IUserDto";

class UserInfoModalStore extends RootStoreModel{
    open: boolean = false;
    user: IUserDto;

  openModal = (user: IUserDto) => {
      if (this.open) {
          this.closeModal();
      }

      this.user = user;

      this.open = true;
  };

    closeModal = () => {
      this.open = false;
  }

}

decorate(UserInfoModalStore, {
    open: observable,
    user: observable,
    openModal: action,
});

export default UserInfoModalStore;