import ServerApi, {IResponse} from '../ServerApi';
import IPageDto from '../_common/dto/IPageDto';
import IOrderDto from "../_common/dto/order/IOrderDto";
import OrderRequestOptions from "../_common/request/OrderRequestOptions";
import IApiRequestOptions from "../_common/request/IApiRequestOptions";
import {OrderStatusEnum} from "../_common/enum/OrderStatusEnum";
import IEnumDto from "../_common/dto/IEnumDto";
import ICommentDto from "../_common/dto/ICommentDto";

export default class OrdersApi {
  static async getOrderList(companyId: number, apiRequestOptions: IApiRequestOptions): Promise<IResponse<IPageDto<IOrderDto>>> {
    return ServerApi.getQuery(`cart/partner/${companyId}`, {params: apiRequestOptions});
  }

  static async getOrderListPartnerAll(apiRequestOptions: IApiRequestOptions): Promise<IResponse<IPageDto<IOrderDto>>> {
    return ServerApi.getQuery(`cart/partner`, {params: apiRequestOptions});
  }

  static async getOrder(companyId: number, orderId: number): Promise<IResponse<IOrderDto>> {
    return ServerApi.getQuery(`cart/${companyId}/${orderId}`);
  }

  /*static async delete(ids: number[]): Promise<IResponse<any>> {
    return ServerApi.deleteQuery('orders/delete', {data: ids});
  }*/

  static async changeStatus(orderId: number, newStatus: OrderStatusEnum): Promise<IResponse<IOrderDto>> {
    return ServerApi.postQuery(`cart/${orderId}/set/${newStatus}`, null);
  }

  static async getStates(orderId: number): Promise<IResponse<IEnumDto[]>> {
    return ServerApi.getQuery(`cart/${orderId}/states`);
  }

  static async getComments(orderId: number): Promise<IResponse<ICommentDto[]>> {
    return ServerApi.getQuery(`cart/${orderId}/comment`);
  }

  static async saveComment(orderId: number, comment: ICommentDto): Promise<IResponse<ICommentDto>> {
    return ServerApi.postQuery(`cart/${orderId}/comment/save`,
        comment
    );
  }

  static async deleteComment(orderId: number, commentId: number): Promise<IResponse> {
    return ServerApi.deleteQuery(`cart/${orderId}/comment/${commentId}`);
  }
}
