import React, {useCallback, useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@material-ui/core";
import {observer} from "mobx-react";
import useStore from "../../context/RootStoreContext";

function AddQuestionDialog() {
  const store = useStore().addQuestionModalStore;
  const {open, title, body, handleConfirm, handleCancel} = store;

  const [description, setDescription] = useState('');
  const handleChangeDescription = useCallback((ev) => {
    setDescription(ev.target.value);
  }, [setDescription]);

  const [qtitle, setQtitle] = useState('');
  const handleChangeQtitle = useCallback((ev) => {
    setQtitle(ev.target.value);
  }, [setQtitle]);

  const defaultHandleConfirm = () => {
    if (handleConfirm) handleConfirm(qtitle, description);
    store.closeModal();
  };

  const defaultHandleCancel = () => {
    if (handleCancel) handleCancel();
    store.closeModal();
  };

  return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={open}
            onClose={defaultHandleCancel}
            aria-labelledby="add-question-dialog-title"
            aria-describedby="add-question-dialog-description"
        >
          <DialogTitle id="add-question-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="add-question-dialog-description">
              <p>{body}</p>

              <p>
                <TextField
                    fullWidth
                    label="Заголовок вопроса"
                    margin="normal"
                    onChange={handleChangeQtitle}
                    type="text"
                    value={qtitle}
                    variant="outlined"
                />

                <TextField
                    fullWidth
                    label="Текст вопроса"
                    margin="normal"
                    onChange={handleChangeDescription}
                    type="text"
                    multiline={true}
                    rows={5}
                    rowsMax={20}
                    value={description}
                    variant="outlined"
                />
              </p>

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={defaultHandleCancel} color="secondary">
              Отмена
            </Button>
            <Button onClick={defaultHandleConfirm} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
  );
}

export default observer(AddQuestionDialog);