import ILocationDto from 'src/api/LocationApi/response/ILocationDto';
import IIdNameDto from 'src/api/_common/dto/IIdNameDto';
import { decorate, observable, computed, action } from 'mobx';

class CompanyLocationItemViewModel {
  id: number;
  name: string;
  locations: CompanyLocationItemViewModel[] = [];
  checked = false;
  expanded = false;

  #onCheckedChange: () => void;

  get expandable(): boolean {
    return this.locations.length > 0;
  }

  constructor(location: ILocationDto, onCheckedChange?: () => void) {
    this.id = location.id;
    this.name = location.name;
    this.#onCheckedChange = onCheckedChange;
    if (location.childs && location.childs.length > 0) {
      this.locations = location.childs.map(
        x => new CompanyLocationItemViewModel(x, this.handleChildCheckedChange)
      );
    }
  }

  initialize(checkedLocations: IIdNameDto[]) {
    if (checkedLocations.some(x => x.id === this.id)) {
      this.setChecked(true);
      return;
    }
    this.checked = false;
    if (this.locations.length > 0) {
      this.locations.forEach(x => x.initialize(checkedLocations));
    }
  }

  changeExpanded = () => {
    this.expanded = !this.expanded;
  };

  setChecked = (checked: boolean) => {
    this.checked = checked;
    if (this.locations && this.locations.length > 0)
      this.locations.forEach(x => x.setChecked(this.checked));
  };
  changeChecked = () => {
    this.checked = !this.checked;
    if (this.locations && this.locations.length > 0)
      this.locations.forEach(x => x.setChecked(this.checked));
    if (this.#onCheckedChange) this.#onCheckedChange();
  };

  handleChildCheckedChange = () =>  {
    const newChecked = this.locations.every(x => x.checked);
    if (newChecked !== this.checked) {
      this.checked = newChecked;
      if (this.#onCheckedChange) this.#onCheckedChange();
    }
  }

  getCheckedLocations = (): IIdNameDto[] => {
    if (this.checked) return [{ id: this.id, name: this.name }];
    const checkedLocations = [];
    if (this.locations.length > 0) {
      this.locations.forEach(x =>
        checkedLocations.push(...x.getCheckedLocations())
      );
    }
    return checkedLocations;
  };
}

decorate(CompanyLocationItemViewModel, {
  checked: observable,
  expanded: observable,
  initialize: action,
  changeExpanded: action,
  setChecked: action,
  handleChildCheckedChange: action,
});

export default CompanyLocationItemViewModel;
