import BaseTabViewModel from 'src/store/Common/BaseTabViewModel';
import PlatformApi from 'src/api/PlatformApi';
import { IResponse } from 'src/api/ServerApi';
import { decorate, observable, computed, action } from 'mobx';
import { IDropdownFileItem } from 'src/components/common-form/FilesDropdownForm/type';
import IPhotoWithDescriptionDto from 'src/api/_common/dto/IPhotoWithDescriptionDto';

class TradeProfileFilesTabViewModel extends BaseTabViewModel {
  platformImages: IPhotoWithDescriptionDto[] = [];
  processing = false;

  get images(): IDropdownFileItem[] {
    return this.platformImages.map(x => ({
      file: x.fileInfo,
      description: x.description,
      id: x.id
    }));
  }

  protected async initializeTab(): Promise<void> {
    const res = await PlatformApi.getPlatformImages(this.id);
    if (res.success) {
      this.platformImages = res.data.content;
      this.initialized = true;
    } else this.loadingError = res.message;
  }

  addImage = async (file: File): Promise<IResponse> => {
    if (this.processing) return;
    this.processing = true;
    const res = await PlatformApi.addPlatformImage(this.id, file);
    this.processing = false;
    if (res.success) this.platformImages.push(res.data);
    return res;
  };

  deleteImage = async (id: number): Promise<IResponse> => {
    if (this.processing) return;
    this.processing = true;
    const res = await PlatformApi.deletePlatformImage(this.id, id);
    this.processing = false;
    if (res.success) {
      this.platformImages = this.platformImages.filter(x => x.id !== id);
    }
    return res;
  };

  changeImageDescription = async (
    id: number,
    description: string
  ): Promise<IResponse> => {
    if (this.processing) return;
    this.processing = true;
    const res = await PlatformApi.changePlatformImageDescription(
      this.id,
      id,
      description.trim()
    );
    this.processing = false;
    if (res.success) {
      this.platformImages.find(x => x.id === id).description =
        res.data.description;
    }
    return res;
  };
}

decorate(TradeProfileFilesTabViewModel, {
  platformImages: observable,
  images: computed,
  addImage: action,
  deleteImage: action,
  changeImageDescription: action
});

export default TradeProfileFilesTabViewModel;
