import BaseRequestOptions from "./BaseRequestOptions";
import IApiRequestOptions from "./IApiRequestOptions";

export default class TradeRequestOptions extends BaseRequestOptions {
  companyId?: number;
  categoryId?: number;

  asGetParams(): IApiRequestOptions {
    let res = super.asGetParams();

    let paramsArr: string[] = [];
    const filterParam = res.search;
    if (filterParam) paramsArr.push(filterParam);

    if (this.categoryId) paramsArr.push("categoryId," + this.categoryId);

    if (paramsArr.length) res.search = paramsArr.join(";");

    return res;
  };

}
