import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Typography,
  makeStyles, Link, CardContent, Tooltip, CircularProgress
} from '@material-ui/core';
import { Clipboard as ClipboardIcon} from 'react-feather';
import Label from "../../components/Label";
import {Link as RouterLink} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  label: {
    marginLeft: theme.spacing(1),
    fontSize: '26px',
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: 48,
    width: 48
  }
}));

function TodayWindow({ className, value, difference, title, icon, url, tooltip, ...rest }) {
  //console.log('TodayWindow...');
  const classes = useStyles();

  const valueIsLoading = value === undefined;
  const valueLoadingError = value === null;

  const differenceIsLoading = difference === undefined;
  const differenceLoadingError = difference === null;

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box flexGrow={1}>
        <Typography
          component="h3"
          gutterBottom
          variant="overline"
          color="textSecondary"
        >
          {title}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
        >
          <Typography
            variant="h3"
            color="textPrimary"
          >
            {valueIsLoading ? <CircularProgress/> :
                (valueLoadingError ? '?' : value)
            }
          </Typography>
          <Label
            className={classes.label}
            color={differenceIsLoading ? 'primary' : (difference > 0 ? 'success' : 'error')}
            style={{}}
          >
            {differenceIsLoading ? <CircularProgress/> :
                (differenceLoadingError ? '?' : `+${difference}`)
            }

          </Label>
          <Typography
              variant="body2"
              color="textSecondary"
          >
            сегодня
          </Typography>
        </Box>
      </Box>
      <Tooltip title={tooltip}>
        <Link
            component={RouterLink}
            to={url}
        >
          <Avatar className={classes.avatar}>
            <ClipboardIcon />
          </Avatar>
        </Link>
      </Tooltip>
    </Card>
  );
}

TodayWindow.propTypes = {
  className: PropTypes.string
};

export default TodayWindow;
