import BaseRequestOptions from "./BaseRequestOptions";
import IApiRequestOptions from "./IApiRequestOptions";

export default class GoodsRequestOptions extends BaseRequestOptions {
    categoryId?: number;

    constructor() {
        super();
        this.pageSize = 25;
    }

    asGetParams(): IApiRequestOptions {
        let res = super.asGetParams();

        let paramsArr: string[] = [];
        const filterParam = res.search;
        if (filterParam) paramsArr.push(filterParam);

        if (this.categoryId) paramsArr.push("category," + this.categoryId);

        if (paramsArr.length) res.search = paramsArr.join(";");

        return res;
    };

    protected makeSearchParam(): string {
        return 'text,'+(this.filter?this.filter : '');
    }

}
