/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Avatar, Box, Button, Divider, Drawer, Hidden, Link, makeStyles} from '@material-ui/core';
import {
  Archive as ArchiveIcon,
  Briefcase,
  Calendar,
  Icon,
  MessageCircle,
  Layout as LayoutIcon,
  Map as MapIcon,
  User as UserIcon,
  Clipboard as ClipboardIcon,
  BarChart as BarChartIcon,
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import useStore from 'src/context/RootStoreContext';
import {observer} from 'mobx-react';
import ChangeCompanyModal from "../../../views/pages/CompanyChangeModal";
import {LOCAL_STORAGE_COMPANY_COUNT} from "../../../const/localStorage";
import {makeCompanyName} from "../../../api/_common/dto/company/ICompanyDto";

interface INavItem {
  title?: string;
  subheader?: string;
  icon?: Icon;
  href?: string;
  info?: React.ComponentType;
  items?: INavItem[];
}

const navConfig: INavItem[] = [
  {
    title: 'Главная',
    icon: BarChartIcon,
    href: '/app'
  },
  {
    title: 'Заявки',
    icon: LayoutIcon,
    href: '/app/requests'
  },
  {
    title: 'Заказы',
    icon: ClipboardIcon,
    href: '/app/orders'
  },
  {
    title: 'Услуги',
    icon: ArchiveIcon,
    href: '/app/services'
  },
  {
    title: 'Предложения',
    icon: Briefcase,
    href: '/app/offer'
  },
  {
    title: 'Площадки',
    icon: MapIcon,
    href: '/app/trades'
  },
  {
    title: 'Новости',
    icon: Calendar,
    href: '/app/companyNews'
  },
  {
    title: 'Уведомления',
    icon: MessageCircle,
    href: '/app/notifications'
  },
];

const adminNavConfig: INavItem[] = [
  ...navConfig,
  { title: 'Пользователи (Админ)', icon: UserIcon, href: '/admin/users' },
  { title: 'Компании (Админ)', icon: UserIcon, href: '/admin/companies' }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

interface INavBarProps {
  openMobile: boolean;
  onMobileClose: () => void;
}

const NavBar: FunctionComponent<INavBarProps> = ({
  openMobile,
  onMobileClose
}) => {
  const classes = useStyles();
  const location = useLocation();
  const { sessionStore, notificationProfileStore } = useStore();
  const { company, isSysAdmin } = sessionStore;
  const companyCount = Number(localStorage.getItem(LOCAL_STORAGE_COMPANY_COUNT)) || 0;

  const config = isSysAdmin ? adminNavConfig : navConfig;

  const [availableCompaniesLoading, setAvailableCompaniesLoading] = useState(false);
  const [changeCompanyOpen, setChangeCompanyOpen] = useState(false);
  const handleChangeCompanyOpen = useCallback(() => {
    setAvailableCompaniesLoading(true);
    sessionStore.loadAvailableCompanies().then(response => {
      setAvailableCompaniesLoading(false);
    });
    setChangeCompanyOpen(true);
  }, [setChangeCompanyOpen]);
  const handleChangeCompanyClose = useCallback(() => {
    setChangeCompanyOpen(false);
  }, [setChangeCompanyOpen]);


  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        {company && (
          <Box p={2}>
            <Box display="flex" justifyContent="center">
              <RouterLink to={`/app/company/${company.id}`}>
                <Avatar
                  alt="User"
                  className={classes.avatar}
                  src={company.icon}
                />
              </RouterLink>
            </Box>
            <Box mt={2} textAlign="center">
              <Link
                component={RouterLink}
                to={`/app/company/${company.id}`}
                variant="h5"
                color="textPrimary"
                underline="none"
              >
                {(company.ownershipType && company.ownershipType.name ? (company.ownershipType.name + ' ') : '') + makeCompanyName(company)}
                {!company.isEnabled && (<><br/>неактивна</>)}
              </Link>
            </Box>
            {( (companyCount > 1) || isSysAdmin) &&
              <Box mt={2} textAlign="center">
                <Button onClick={handleChangeCompanyOpen}>Сменить компанию</Button>
                <ChangeCompanyModal
                  open={changeCompanyOpen}
                  isLoading={availableCompaniesLoading}
                  onClose={handleChangeCompanyClose}
                />
              </Box>
            }

          </Box>
        )}
        <Divider />
        <Box p={2}>
          {company && company.isEnabled && config.map(item => (
            <NavItem
              depth={0}
              href={item.href}
              icon={item.icon}
              key={item.title}
              info={item.info}
              title={item.title}
            />
          ))}

          {isSysAdmin && (<>
            <Divider/>
            <NavItem
                depth={0}
                href={'createNotification'}
                icon={MessageCircle}
                key={'Создать уведомление'}
                info={null}
                title={'Создать уведомление'}
                handleClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  notificationProfileStore.open = true;
                }}
            />
          </>)}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default observer(NavBar);
