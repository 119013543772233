import RootStoreModel from '../_root/RootStoreModel';
import {action, decorate, observable, runInAction} from "mobx";
import CompaniesApi from "../../api/CompaniesApi";
import OrdersApi from "../../api/OrdersApi";
import IApiRequestOptions from "../../api/_common/request/IApiRequestOptions";
import ProposalsApi from "../../api/ProposalsApi";
import PlatformApi from "../../api/PlatformApi";
import IOrderDto from "../../api/_common/dto/order/IOrderDto";
import IProposalDto from "../../api/_common/dto/proposal/IProposalDto";
import OrderRequestOptions from "../../api/_common/request/OrderRequestOptions";
import ProposalRequestOptions from "../../api/_common/request/ProposalRequestOptions";


class DashboardStore extends RootStoreModel {

  // undefined = isLoading, null = loadingError

  ordersCountToday: number;
  ordersCountTotal: number;
  ordersToday: IOrderDto[];

  proposalsCountToday: number;
  proposalsCountTotal: number;
  proposalsToday: IProposalDto[];

  tradesCountTotal: number;
  offersCountTotal: number;

  companyMemberCount: number;


  initialize = async () => {
    //console.log('DashboardStore.initialize');
    await this.loadOrders();
    await this.loadProposals();
    await this.loadTrades();
    await this.loadOffers();
    await this.loadCompanyMembers();
  }

  loadOrders = async () => {
    this.ordersToday = undefined;
    this.ordersCountToday = undefined;
    this.ordersCountTotal = undefined;
    // total
    const req: IApiRequestOptions = {
      pageSize: 1,
    };
    await OrdersApi.getOrderList(this.getCompanyId(), req).then(resp => {
      this.ordersCountTotal = resp.success ? resp.data.totalElements : null;
    });

    // today
    const reqToday = new OrderRequestOptions();
    reqToday.pageSize = 20;
    reqToday.todayOnly = true
    reqToday.sortField = 'cratedAt,desc';
    await OrdersApi.getOrderList(this.getCompanyId(), reqToday.asGetParams()).then(resp => {
      runInAction( () => {
        if (resp.success) {
          this.ordersCountToday = resp.data.totalElements;
          this.ordersToday = resp.data.content;
        } else {
          this.ordersCountToday = null;
          this.ordersToday = null;
        }
      });
    });
  }

  loadProposals = async () => {
    this.proposalsToday = undefined;
    this.proposalsCountToday = undefined;
    this.proposalsCountTotal = undefined;
    // total
    let req: IApiRequestOptions = {
      pageSize: 1,
    };
    await ProposalsApi.getProposalList(req).then(resp => {
      this.proposalsCountTotal = resp.success ? resp.data.totalElements : undefined;
    });

    // today
    const reqToday = new ProposalRequestOptions();
    reqToday.pageSize = 20;
    reqToday.todayOnly = true
    await ProposalsApi.getProposalList(reqToday.asGetParams()).then(resp => {
      runInAction( () => {
        if (resp.success) {
          this.proposalsCountToday = resp.data.totalElements;
          this.proposalsToday = resp.data.content;
        } else {
          this.proposalsCountToday = null;
          this.proposalsToday = null;
        }
      });
    });
  }

  loadCompanyMembers = async () => {
    const resp = await CompaniesApi.getCompany(this.getCompanyId());
    this.companyMemberCount = resp.success ? resp.data.cntMembers : null;
  };

  loadTrades = async () => {
    let req: IApiRequestOptions = {
      pageSize: 1,
    };
    const resp = await PlatformApi.getTradeList(req, this.getCompanyId());
    this.tradesCountTotal = resp.success ? resp.data.totalElements : null;
  };

  loadOffers = async () => {
    let req: IApiRequestOptions = {
      pageSize: 1,
    };
    const resp = await PlatformApi.getTradeList(req, this.getCompanyId());
    this.offersCountTotal = resp.success ? resp.data.totalElements : null;
  };

  protected getCompany = () => {
    return this.getRootStore().sessionStore.company;
  }
  protected getCompanyId = () => {
    const company = this.getCompany();
    return company && company.id;
  }

}

decorate(DashboardStore, {
  ordersCountToday: observable,
  ordersCountTotal: observable,
  ordersToday: observable,

  proposalsCountToday: observable,
  proposalsCountTotal: observable,
  proposalsToday: observable,

  tradesCountTotal: observable,
  offersCountTotal: observable,

  companyMemberCount: observable,

  initialize: action,
  loadOrders: action,
  loadProposals: action,
  loadTrades: action,
  loadOffers: action,
  loadCompanyMembers: action,
});

export default DashboardStore;
