import {decorate} from 'mobx';
import IPageDto from "../../../api/_common/dto/IPageDto";
import {IResponse} from "../../../api/ServerApi";
import IProposalDto from "../../../api/_common/dto/proposal/IProposalDto";
import ProposalRequestOptions from "../../../api/_common/request/ProposalRequestOptions";
import ProposalsApi from "../../../api/ProposalsApi";
import BaseListStore, {BaseListStoreDecorators} from "../../Common/BaseListStore";
import IApiRequestOptions from "../../../api/_common/request/IApiRequestOptions";

class ProposalListStore extends BaseListStore<IProposalDto, ProposalRequestOptions> {

    protected getDefaultRequestOptions(): ProposalRequestOptions {
        return new ProposalRequestOptions();
    }

    protected async deleteManyRoutine(ids: number[]): Promise<IResponse> {
        return ProposalsApi.delete(ids);
    }

    protected async deleteOneRoutine(id: number): Promise<IResponse> {
        return ProposalsApi.delete([id]);
    }

    protected async getListRoutine(apiRequestOptions: IApiRequestOptions, options?: any): Promise<IResponse> {
        return ProposalsApi.getProposalList(apiRequestOptions);
    }

  imitateLoadResults = (requestOptions: ProposalRequestOptions): IPageDto<IProposalDto> => {
      return  {
          totalElements: 0,
          totalPages: 1,
          size: 0,
          number: 0,
          content: []
      };
      /*const totalElements = 22;
      const totalPages = Math.trunc(totalElements / requestOptions.pageSize) + 1;
      const size = requestOptions.pageSize;
      var number = requestOptions.pageSize;
      if (requestOptions.pageNumber == totalPages - 1) number = totalElements % size;
      var content = [];
      for (var i = requestOptions.pageNumber * size; i < requestOptions.pageNumber * size + number; i++) content.push(
          {
              id: i+1,
              userStatus: (i % 7) - 1,
              city: ['Краснодар', 'Анапа', 'Сочи'][i % 3],
              format: [ {id: 1, name: 'Банкет'}, {id: 2, name: 'Шведский стол'}, {id: 3, name: 'Детский ужин'}, {id: 4, name: 'Доставка обедов'}  ][i % 4],
              persons: i + 20,
              date: new Date(),
              budget: [ Math.trunc((Math.random() * 3000) / 1000) * 1000 + 1000, 5000 + Math.trunc( (Math.random() * 20000) / 1000) * 1000  ],
              paymentType: [ {id: 1, name: 'Наличными'}, {id: 2, name: 'Картой онлайн'}, {id: 3, name: 'По счету с НДС'}, {id: 4, name: 'Картой при получении'}  ][ (i+2) % 4],
              proposalStatus: ['NEW', 'CLOSED', 'CANCELLED', 'CHARGED', 'INPROC'][(i + 6) % 5],
          }
      );

      if (requestOptions.proposalStatus) {
          content = content.filter(x => {return x.proposalStatus === requestOptions.proposalStatus});
      }
      return  {
          totalElements: totalElements,
          totalPages: totalPages,
          size: size,
          number: number,
          content: content
      };*/
  };
}

decorate(ProposalListStore, BaseListStoreDecorators);

export default ProposalListStore;