import React, {useCallback, useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@material-ui/core";
import {observer} from "mobx-react";
import useStore from "../../context/RootStoreContext";

function FileSelectDialog() {
  const store = useStore().fileSelectModalStore;
  const {open, title, body, handleConfirm, handleCancel} = store;

  const [file, setFile] = useState(undefined);
  const handleChangeFile = useCallback((ev) => {
    setFile(ev.target.files[0]);
  }, [setFile]);

  const defaultHandleConfirm = () => {
    if (handleConfirm) handleConfirm(file);
    store.closeModal();
  };

  const defaultHandleCancel = () => {
    if (handleCancel) handleCancel();
    store.closeModal();
  };

  return (
        <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            open={open}
            onClose={defaultHandleCancel}
            aria-labelledby="file-select-dialog-title"
            aria-describedby="file-select-dialog-description"
        >
          <DialogTitle id="file-select-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="file-select-dialog-description">
              <p>{body}</p>

              <p>
                <input type={'file'} onChange={handleChangeFile}/>
              </p>

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={defaultHandleCancel} color="secondary">
              Отмена
            </Button>
            <Button onClick={defaultHandleConfirm} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
  );
}

export default observer(FileSelectDialog);