import ServerApi, {IResponse} from '../ServerApi';
import IPageDto from '../_common/dto/IPageDto';
import IApiRequestOptions from '../_common/request/IApiRequestOptions';
import IFoodDto from '../_common/dto/service/IFoodDto';
import IPhotoWithDescriptionDto from '../_common/dto/IPhotoWithDescriptionDto';
import IGoodsDto from "../_common/dto/service/IGoodsDto";
import ICreateEditGoodsDto from "../_common/dto/service/ICreateEditGoodsDto";

export default class FoodApi {

  static async getGoodsList(
      companyId: number,
      apiRequestOptions: IApiRequestOptions
  ): Promise<IResponse<IPageDto<IFoodDto>>> {
    return ServerApi.getQuery(`goods/${companyId}`, {
      params: apiRequestOptions
    });
  }
  static async deleteGoods(
      companyId: number,
      ids: number[]
  ): Promise<IResponse<any>> {
    return ServerApi.postQuery('goods/delete', {
      companyId: companyId,
      ids: ids
    });
  }
  static async deleteGoodsFromView(
    companyId: number,
    id: number
  ): Promise<IResponse> {
    return this.deleteGoods(companyId, [id]);
    //return ServerApi.deleteQuery(`goods/delete?companyId=${companyId}&id=${id}`);
  }

  static async getGoods(id: number): Promise<IResponse<IGoodsDto>> {
    return ServerApi.postQuery(`goods/get?id=${id}`, {});
  }

  static async saveGoods(
    body: ICreateEditGoodsDto
  ): Promise<IResponse<IGoodsDto>> {
    return ServerApi.postQuery('goods/save', body);
  }

  static async getGoodsImages(
    platformId: number
  ): Promise<IResponse<IPhotoWithDescriptionDto[]>> {
    return ServerApi.getQuery(`goods/${platformId}/images`);
  }

  static async addGoodsImage(
    goodsId: number,
    file: File
  ): Promise<IResponse<IPhotoWithDescriptionDto>> {
    return ServerApi.uploadFileQuery(`goods/${goodsId}/images`, file);
  }

  static async deleteGoodsImage(
    goodsId: number,
    imageId: number
  ): Promise<IResponse<IPageDto<IPhotoWithDescriptionDto>>> {
    return ServerApi.deleteQuery(`goods/${goodsId}/images/${imageId}`);
  }

  static async markGoodsImageAsMain(
    goodsId: number,
    imageId: number
  ): Promise<IResponse<IPhotoWithDescriptionDto>> {
    return ServerApi.postQuery(
      `goods/${goodsId}/images/${imageId}/makeMain`,
      {}
    );
  }

  static async changeGoodsImageDescription(
    goodsId: number,
    imageId: number,
    description: string
  ): Promise<IResponse<IPhotoWithDescriptionDto>> {
    return ServerApi.postQuery(
      `goods/${goodsId}/images/${imageId}/description`,
      { description }
    );
  }

}
