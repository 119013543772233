import {IResponse} from 'src/api/ServerApi';
import RootStoreModel from 'src/store/_root/RootStoreModel';
import IFoodDto from 'src/api/_common/dto/service/IFoodDto';
import FoodProfileFilesTabViewModel from './tab/FoodProfileFilesTabViewModel';
import DictionaryStore from 'src/store/DictionaryStore';
import FoodApi from 'src/api/ServicesApi/FoodApi';
import ICreateEditFoodDto from 'src/api/_common/dto/service/ICreateEditFoodDto';
import {action, computed, decorate, observable} from 'mobx';

interface ISaveEntityResponse extends IResponse {
  id: number;
  isAdd: boolean;
}

class FoodProfileStore extends RootStoreModel {
  loadingError: string = null;
  food: IFoodDto;
  id: number;

  images: FoodProfileFilesTabViewModel;

  procesing = false;

  get mainImageId(): number {
    return (this.food && this.food.mainImage && this.food.mainImage.id) || null;
  }

  initialize = async (id?: number): Promise<void> => {
    this.loadingError = null;
    this.id = id;

    let dictRes = await DictionaryStore.initializeFoodCategories();
    if (!dictRes.success) {
      this.loadingError = dictRes.data;
      return;
    }
    dictRes = await DictionaryStore.initializeFoodKitchenTypes();
    if (!dictRes.success) {
      this.loadingError = dictRes.data;
      return;
    }
    if (!id) {
      this.food = null;
      return;
    }

    const foodRes = await FoodApi.getFood(id);
    if (!foodRes.success) {
      this.loadingError = foodRes.message || 'Не удалось загрузить меню';
      return;
    }
    this.food = foodRes.data;
    this.images = new FoodProfileFilesTabViewModel(this.root, this.food.id);
  };

  save = async (body: ICreateEditFoodDto): Promise<ISaveEntityResponse> => {
    const isAdd = this.food ? false : true;
    if (this.food) body.id = this.food.id;
    const res = await FoodApi.saveFood(body);
    if (res.success) {
      this.food = res.data;
    }
    return { ...res, id: res.data && res.data.id, isAdd };
  };

  editFoodMainImage = async (id: number): Promise<IResponse> => {
    if (this.procesing) return;
    this.procesing = true;
    const foodId = this.food.id;
    const res = await FoodApi.markFoodImageAsMain(foodId, id);
    this.procesing = false;
    if (res.success && foodId === this.food.id) {
      this.food.mainImage = res.data;
    }
    return res;
  };
}

decorate(FoodProfileStore, {
  loadingError: observable,
  food: observable,
  mainImageId: computed,
  save: action,
  editFoodMainImage: action
});

export default FoodProfileStore;
