import IIdNameDto from "../api/_common/dto/IIdNameDto";
import IIdDto from "../api/_common/dto/IIdDto";

export function copyAndSpread(srcObj: any, extentionObj: any): any {
    let x = {...srcObj};
    for (var z in extentionObj) {
        x[z] = extentionObj[z];
    }
    return Object.assign(Object.create(Object.getPrototypeOf(srcObj)), x);
}

export function getIdByName(arr: IIdNameDto[], name: string): number {
    if (!name) return null;
    const item = arr.filter(x => x.name === name);
    return item && item[0] && item[0].id;
}

export function getNameById(arr: IIdNameDto[], id: number): string {
    if (!id) return null;
    const item = arr.filter(x => x.id === id);
    return item && item[0] && item[0].name;
}

export function getIndById(arr: IIdDto[], id: number): number {
    if (!id) return -1;
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === id) return i;
    }
    return -1;
}