/* eslint-disable max-len */
import React, {useCallback, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider, Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  SvgIcon, Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow, Tabs,
  TextField, Typography
} from '@material-ui/core';
import {ArrowRight as ArrowRightIcon, Search as SearchIcon} from 'react-feather';
import useStore from "../../../../context/RootStoreContext";
import {observer} from "mobx-react";
import LoadingScreen from "../../../../components/LoadingScreen";
import {CheckBox, CheckCircleOutline, RadioButtonUnchecked} from "@material-ui/icons";
import {useSnackbar} from "notistack";
import {IResponse} from "../../../../api/ServerApi";
import Label from "../../../../components/Label";
import {CateringTheme} from "../../../../theme/type";
import moment from "moment";
import {OrderStatusCollection, OrderStatusEnum} from "../../../../api/_common/enum/OrderStatusEnum";
import ListPaginator from "../../../../components/ListComponents/ListPaginator";
import BulkOperationsHandler from "../../../../components/ListComponents/BulkOperationsHandler";
import {copyAndSpread} from "../../../../utils/objects";
import ActionDotsMenu from "../../../../components/ActionDotsMenu";
import {ActionDotsMenuOption} from "../../../../components/ActionDotsMenu/type";
import OrdersApi from "../../../../api/OrdersApi";
import OrderStatusLabel from "../../../../components/Order/OrderStatusLabel";
import {rub} from "../../../../utils/StringUtil";
import OneLiner from "../../../../components/OneLiner";

const useStyles = makeStyles((theme: CateringTheme) => ({
  root: {},
  queryField: {
    width: 500
  }

}));

function ResultsTable({orders, isLoading, hasPartnerName, className, ...rest }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleChangeStatus = async (id: number, newStatus: OrderStatusEnum) => {
    const order = orders.find(x => x.id === id);
    if (!order) return;
    order.status = undefined;
    const resp = await OrdersApi.changeStatus(id, newStatus);
    if (resp.success) {
      order.status = newStatus;
      enqueueSnackbar(`Статус изменен`, { variant: 'success' });
    } else {
      enqueueSnackbar(`Ошибка: ${resp.message}`, { variant: 'error' });
    }
  };

  return (
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                {/*<TableCell padding="checkbox" colSpan={2}>
                  <span style={{whiteSpace: "nowrap"}}>
                  <Checkbox
                      checked={bulkOperationsHandler.selectedAll()}
                      indeterminate={bulkOperationsHandler.selectedSome()}
                      onChange={bulkOperationsHandler.handleSelectAll}
                  />
                  Выбрать все
                  </span>
                </TableCell>*/}
                <TableCell>
                  ID
                </TableCell>
                {/*<TableCell>
                  Заказчик
                </TableCell>
                <TableCell>
                  Город
                </TableCell>
                <TableCell>
                  Персон
                </TableCell>*/}
                <TableCell>
                  Дата заказа
                </TableCell>
                <TableCell>
                  Цена
                </TableCell>
                {/*<TableCell>
                  Способ оплаты
                </TableCell>*/}
                <TableCell>
                  Необходимость доставки
                </TableCell>
                <TableCell>
                  Адрес доставки
                </TableCell>
                <TableCell>
                  Время доставки
                </TableCell>
                <TableCell>
                  Статус
                </TableCell>
                <TableCell align="right">
                  {hasPartnerName ? 'Компания' : ''}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading ?
                  (<>
                    {orders.map((order) => {
                      const isOrderSelected = false;// orderListStore.selectedItems.includes(order.id);

                      return (
                          <TableRow
                              hover
                              key={order.id}
                              selected={isOrderSelected}
                          >
                            {/*<TableCell padding="checkbox">
                              <Checkbox
                                  checked={isOrderSelected}
                                  onChange={(event) => bulkOperationsHandler.handleSelectOne(event, order.id)}
                                  value={isOrderSelected}
                              />
                            </TableCell>*/}
                            <TableCell>
                              {order.id}
                            </TableCell>
                            {/*<TableCell>
                              {order.user && (<>
                                <span>{order.user.lastName} {order.user.firstName} {order.user.secondName}</span> <br/>
                                <Typography color="textSecondary" variant="body1">
                                  {order.user.email || 'email не указан'}
                                </Typography>
                              </>)}
                            </TableCell>*/}
                            {/*<TableCell>
                              {order.city}
                            </TableCell>*/}
                            {/*<TableCell>
                              {order.persons}
                            </TableCell>*/}
                            <TableCell>
                              {order.cratedAt && moment(order.cratedAt).format(
                                  'DD.MM.YYYY HH:mm'
                              )}
                            </TableCell>
                            <TableCell>
                              <OneLiner text={rub(order.price)}/>
                            </TableCell>
                            {/*<TableCell>
                              {order.paymentType && order.paymentType.name}
                            </TableCell>*/}
                            {/*<TableCell>
                              {order.comment}
                            </TableCell>*/}

                            <TableCell align={"center"}>
                              {order.deliveryNeeded ?
                                  <CheckCircleOutline/>
                                  :
                                  <RadioButtonUnchecked/>
                              }
                            </TableCell>
                            <TableCell>
                              {order.deliveryAddress}
                            </TableCell>

                            <TableCell>
                              {order.deliveryTime && moment(order.deliveryTime).format('DD.MM.YYYY HH:mm')}
                            </TableCell>

                            <TableCell>
                              <OrderStatusLabel value={order.state}/>
                            </TableCell>
                            <TableCell align="right">
                              {hasPartnerName ?
                                  <>{order.partnerName}</>
                                   :
                                  <IconButton
                                      component={RouterLink}
                                      to={"/app/orders/edit/" + order.id}
                                  >
                                    <SvgIcon fontSize="small">
                                      <ArrowRightIcon/>
                                    </SvgIcon>
                                  </IconButton>
                              }
                              </TableCell>
                          </TableRow>
                      );
                    })}
                  </>):
                  <>
                    <TableRow>
                      <TableCell colSpan={9} >
                        <LoadingScreen/>
                      </TableCell>
                    </TableRow>
                  </>
              }

            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>

  );
}

export default ResultsTable;
