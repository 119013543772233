import {decorate} from 'mobx';
import IPageDto from "../../../api/_common/dto/IPageDto";
import {IResponse} from "../../../api/ServerApi";
import BaseListStore, {BaseListStoreDecorators} from "../../Common/BaseListStore";
import IApiRequestOptions from "../../../api/_common/request/IApiRequestOptions";
import GoodsApi from "../../../api/ServicesApi/GoodsApi";
import IGoodsDto from "../../../api/_common/dto/service/IGoodsDto";
import GoodsRequestOptions from "../../../api/_common/request/GoodsRequestOptions";

class GoodsListStore extends BaseListStore<IGoodsDto, GoodsRequestOptions> {

    public getDefaultRequestOptions(): GoodsRequestOptions {
        const res = new GoodsRequestOptions();
        res.sortField = 'name,asc';
        return res;
    }

    protected async deleteManyRoutine(ids: number[]): Promise<IResponse> {
        return GoodsApi.deleteGoods(this.getCompanyId(), ids);
    }

    protected async deleteOneRoutine(id: number): Promise<IResponse> {
        return this.deleteManyRoutine([id]);
    }

    protected async getListRoutine(apiRequestOptions: IApiRequestOptions, options?: any): Promise<IResponse> {
        return GoodsApi.getGoodsList(this.getCompanyId(), apiRequestOptions);
    }


  imitateLoadResults = (requestOptions: GoodsRequestOptions): IPageDto<IGoodsDto> => {
      return  {
          totalElements: 0,
          totalPages: 1,
          size: 0,
          number: 0,
          content: []
      };
  };

}

decorate(GoodsListStore, BaseListStoreDecorators);

export default GoodsListStore;