import React from 'react';
import qs from 'query-string';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Box } from '@material-ui/core';
import withViewLoader, { LoadableView } from 'src/hoc/withViewLoader';
import { useHistory } from 'react-router';
import SignInApi from 'src/api/SignInApi';
import Page from 'src/components/Page';
import CompaniesApi from 'src/api/CompaniesApi';

interface IVerifyAccountViewInitialProps {
  success: boolean;
  message?: string;
}
const ApproveInviteView: LoadableView<IVerifyAccountViewInitialProps> = props => {
  const { success, message } = props;
  return (
    <Page title="Приглашение в компанию">
      <Box mt={2}>
        {success
          ? 'Вы приняли приглашение'
          : `При принятии приглашения произошла ошибка - ${message}`}
      </Box>
      <Link component={RouterLink} to="/signin" variant="body2" color="primary">
        Войти в учетную запись
      </Link>
    </Page>
  );
};
ApproveInviteView.getInitialProps = async props => {
  const { token } = props.match.params as { token?: string };
  const res = await CompaniesApi.approveInvite(token);
  return { success: res.success, message: res.message };
};

export default withViewLoader(ApproveInviteView);
