import React from 'react';
import useStore, {RootContextProvider} from "../context/RootStoreContext";
import {observer} from "mobx-react";
import {YMInitializer} from "react-yandex-metrika";

const YaMetrika = () => {
  const store = useStore();
  const authentificated = store.sessionStore.authenticated;
  const userId = authentificated && store.sessionStore.session.id;

  let options: any = {clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:true,
  };

  if (authentificated) options = {...options, userParams: { UserID: userId} };

  return (<>
    <YMInitializer accounts={[66216661]} options={options} />
  </>);
};

export default observer(YaMetrika);
