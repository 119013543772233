import {observable} from 'mobx';

import RootStoreModel from '../_root/RootStoreModel';
import IPageDto from '../../api/_common/dto/IPageDto';
import {IResponse} from '../../api/ServerApi';
import BaseRequestOptions from '../../api/_common/request/BaseRequestOptions';
import IApiRequestOptions from '../../api/_common/request/IApiRequestOptions';

// T - ResponseDTO type, R - requestOptionsType
abstract class BaseListStore<
  T,
  R extends BaseRequestOptions
> extends RootStoreModel {
  page: IPageDto<T>;
  requestOptions: R;
  loadingError: string;
  isLoading: boolean;
  selectedItems: number[] = [];

  protected abstract getDefaultRequestOptions(): R;
  protected abstract async deleteManyRoutine(ids: number[]): Promise<IResponse>;
  protected abstract async deleteOneRoutine(id: number, description: string): Promise<IResponse>;
  protected abstract async getListRoutine(
    apiRequestOptions: IApiRequestOptions,
    options?: any
  ): Promise<IResponse>;

  initialize = async (): Promise<void> => {
    this.requestOptions = this.getDefaultRequestOptions();
    await this.loadDics();
    await this.load(this.requestOptions);
  };

  loadDics = async (): Promise<void> => {
  };

  deleteMany = async (ids: number[]): Promise<IResponse> => {
    this.isLoading = true;
    const response = await this.deleteManyRoutine(ids);
    if (response.success) {
      this.loadingError = null;
    } else {
      this.loadingError = response.message;
    }
    await this.load(this.requestOptions);
    this.isLoading = false;
    return response;
  };

  deleteOne = async (id: number, description: string): Promise<IResponse> => {
    this.isLoading = true;
    const response = await this.deleteOneRoutine(id, description);
    if (response.success) {
      this.loadingError = null;
    } else {
      this.loadingError = response.message;
    }
    await this.load(this.requestOptions);
    this.isLoading = false;
    return response;
  };

  load = async (requestOptions: R): Promise<IResponse> => {
    return this.loadList(requestOptions);
  };

  protected loadList = async (
    requestOptions: R,
    options?: any
  ): Promise<IResponse> => {
    this.performAsAction(() => {
      this.requestOptions = requestOptions;
      this.isLoading = true;
      this.selectedItems = [];
    });
    const response = await this.getListRoutine(
      requestOptions.asGetParams(),
      options
    );
    this.performAsAction(() => {
      if (response.success) {
        this.loadingError = null;
        this.page = response.data;
      } else {
        this.loadingError = response.message;
        // imitation
        /*this.page = this.imitateLoadResults(this.requestOptions);
        response.success = true;*/
        // / imitation
      }
      this.isLoading = false;
    });
    return response;
  };

  protected abstract imitateLoadResults(requestOptions: R): IPageDto<T>;

  protected getCompany = () => {
    return this.getRootStore().sessionStore.company;
  }
  protected getCompanyId = () => {
    const company = this.getCompany();
    return company && company.id;
  }

  resetRequestOptions() {
    this.requestOptions = this.getDefaultRequestOptions();
  }
}

export default BaseListStore;

export const BaseListStoreDecorators = {
  page: observable,
  requestOptions: observable,
  loadingError: observable,
  isLoading: observable,
  selectedItems: observable
};
