import ILocationDto from 'src/api/LocationApi/response/ILocationDto';
import IFileDto from '../IFileDto';
import UserRoleEnum from '../../enum/UserRoleEnum';
import IUserForGridDto from "./IUserForGridDto";

export default interface IUserDto {
  id: number;
  email?: string;
  lastName: string;
  firstName: string;
  secondName?: string;
  phone?: string;
  isEnabled?: boolean;
  isVerify?: boolean;
  role?: UserRoleEnum;
  post?: string;
  location?: ILocationDto;
  photo: IFileDto;
}


export function makeUserStr(dto: IUserDto | IUserForGridDto) {
  if (!dto) return 'Пользователь';
  const arr = [dto.lastName, dto.firstName, dto.secondName];
  return arr.filter(x => x).join(' ');
}

export function makeUserAnonymStr(dto: IUserDto) {
  if (!dto) return 'Пользователь';
  const arr = [dto.firstName, dto.lastName];
  return arr.filter(x => x).join(' ');
}
