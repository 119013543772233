import DictionaryStore from 'src/store/DictionaryStore';
import { decorate, observable } from 'mobx';
import CompanyLocationItemViewModel from '../CompanyLocationItemViewModel';
import IIdNameDto from 'src/api/_common/dto/IIdNameDto';

class CompanySettingTabViewModel {
  loadingError: string = null;

  locations: CompanyLocationItemViewModel[] = [];

  async initializeTab(serviceZones: IIdNameDto[]): Promise<void> {
    const paymentRes = await DictionaryStore.initializePaymentTypes();
    if (!paymentRes.success) {
      this.loadingError = paymentRes.message;
      return;
    }

    const eventRes = await DictionaryStore.initializeEventTypes();
    if (!eventRes.success) {
      this.loadingError = eventRes.message;
      return;
    }

    const locationRes = await DictionaryStore.initializeLocations();
    if (!locationRes.success) {
      this.loadingError = locationRes.message;
      return;
    }

    const locationAllRes = await DictionaryStore.initializeLocationsAll();
    if (!locationAllRes.success) {
      this.loadingError = locationRes.message;
      return;
    }

    const ownershipRes = await DictionaryStore.initializeOwnershipTypes();
    if (!ownershipRes.success) {
      this.loadingError = ownershipRes.message;
      return;
    }

    const eventFormatRes = await DictionaryStore.initializeEventFormats();
    if (!eventFormatRes.success) {
      this.loadingError = eventFormatRes.message;
      return;
    }

    if (this.loadingError) this.loadingError = null;
    this.locations = DictionaryStore.locations.map(x => {
      const vm = new CompanyLocationItemViewModel(x);
      vm.initialize(serviceZones);
      return vm;
    });
  }

  getCheckedLocations = (): IIdNameDto[] => {
    const checkedLocations = [];
    this.locations.forEach(x =>
      checkedLocations.push(...x.getCheckedLocations())
    );
    return checkedLocations;
  };

  updateCheckedLocations = (locations: IIdNameDto[]): void => {
    this.locations.forEach(x => x.initialize(locations));
  };
}

decorate(CompanySettingTabViewModel, {
  loadingError: observable
});

export default CompanySettingTabViewModel;
