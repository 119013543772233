import { decorate, observable, action, computed } from 'mobx';

import RootStoreModel from '../../_root/RootStoreModel';
import OffersApi from 'src/api/OffersApi';
import { IResponse } from 'src/api/ServerApi';
import OfferProfileFilesTabViewModel from './tab/OfferProfileFilesTabViewModel';
import IOfferDto from "../../../api/_common/dto/offer/IOfferDto";
import ICreateEditOfferRequestDto from "../../../api/OffersApi/dto/ICreateEditOfferRequestDto";
import OfferFoodListStore from "./FoodListStore";
import OfferGoodsListStore from "./GoodsListStore";
import DictionaryStore from "../../DictionaryStore";
import ProposalsApi from "../../../api/ProposalsApi";
import OfferReviewTabViewModel from "./tab/OfferReviewViewModel";

interface ISaveEntityResponse extends IResponse {
  id: number;
  isAdd: boolean;
}

class OfferProfileStore extends RootStoreModel {
  id: number;
  proposalId: number;
  offer: IOfferDto;
  loadingError: string = null;
  offerFoodListStore: OfferFoodListStore;
  offerGoodsListStore: OfferGoodsListStore;

  images: OfferProfileFilesTabViewModel;
  reviews: OfferReviewTabViewModel;

  processing = false;

  get mainImageId(): number {
    return (
      (this.offer &&
        this.offer.mainImage &&
        this.offer.mainImage.id) ||
      null
    );
  }

  initialize = async (id?: number): Promise<void> => {
    this.loadingError = null;

    let dicRes = await DictionaryStore.initializeEventTypes();
    if (!dicRes.success) {
      this.loadingError = dicRes.message;
      return;
    }
    dicRes = await DictionaryStore.initializeEventFormats();
    if (!dicRes.success) {
      this.loadingError = dicRes.message;
      return;
    }

    this.id = id;
    if (!id) {
      this.offer = {
        id: null,
        cost: null,
        description: "",
        shortDescription: "",
        eventFormats: [],
        eventTypes: [],
        foodList: [],
        goodsList: [],
        mainImage: undefined,
        minTimeComplete: 60,
        name: "",
        persons: 1,
        preCost: 0,
        quantity: 0,
        type: undefined,
        active: false,
      };
      return;
    }

    const response = await OffersApi.getOffer(id);
    if (!response.success) {
      this.loadingError = response.message || 'Неизвестная ошибка';
      return;
    }
    this.offer = response.data;
    this.images = new OfferProfileFilesTabViewModel(
      this.root,
      this.offer.id
    );
    this.reviews = new OfferReviewTabViewModel(
        this.root,
        this.offer.id
    );

    this.offerFoodListStore = new OfferFoodListStore(this.getRootStore);
    this.offerFoodListStore.setOriginalValues(id, this.offer.foodList)
    this.offerFoodListStore.initialize();
    this.offerFoodListStore.load(this.offerFoodListStore.getDefaultRequestOptions());

    this.offerGoodsListStore = new OfferGoodsListStore(this.getRootStore);
    this.offerGoodsListStore.setOriginalValues(id, this.offer.goodsList)
    this.offerGoodsListStore.initialize();
    this.offerGoodsListStore.load(this.offerGoodsListStore.getDefaultRequestOptions());
  };

  save = async (
    body: ICreateEditOfferRequestDto
  ): Promise<ISaveEntityResponse> => {
    const isAdd = (this.offer && this.offer.id) ? false : true;

    if (!this.id && this.proposalId) {
      body.applicationId = this.proposalId;
    }
    let resId;
    const res = await OffersApi.createEditOffer(body);
    if (res.success) {
      this.offer = res.data;
      resId = res.data.id;
      /*const gotIdPublic = res.data.id;
      if (!this.id && this.proposalId) {
        const resp2 = await ProposalsApi.sendOffer(this.proposalId, gotIdPublic);
        resId = resp2.data.id;
        const resp3 = await this.root().offerListStore.deleteOne(gotIdPublic, null);
      }*/
    }

    return { ...res, id: resId, isAdd };
  };

  editOfferMenu = async (): Promise<IResponse> => {
    const id = this.offer.id;
    const response = await this.offerFoodListStore.save(id);
    if (response.success) {
      this.offerFoodListStore.setOriginalValues(response.data.id, response.data.foodList);
      await this.offerFoodListStore.load(this.offerFoodListStore.requestOptions);
    }
    return response;
  };

  editOfferGoods = async (): Promise<IResponse> => {
    const id = this.offer.id;
    const response = await this.offerGoodsListStore.save(id);
    if (response.success) {
      this.offerGoodsListStore.setOriginalValues(response.data.id, response.data.goodsList);
      await this.offerGoodsListStore.load(this.offerGoodsListStore.requestOptions);
    }
    return response;
  };

  editOfferMainImage = async (id: number): Promise<IResponse> => {
    if (this.processing) return;
    this.processing = true;
    const offerId = this.offer.id;
    const res = await OffersApi.markOfferImageAsMain(offerId, id);
    this.processing = false;
    if (res.success) {
      this.offer.mainImage = res.data;
    }
    return res;
  };
}

decorate(OfferProfileStore, {
  loadingError: observable,
  offer: observable,
  mainImageId: computed,
  save: action,
  editOfferMainImage: action,
  editOfferMenu: action,
  editOfferGoods: action,
});

export default OfferProfileStore;
