import {IResponse} from 'src/api/ServerApi';
import {action, decorate, observable} from 'mobx';
import IPageDto from "../../api/_common/dto/IPageDto";
import CompaniesApi from "../../api/CompaniesApi";
import IApiRequestOptions from "../../api/_common/request/IApiRequestOptions";
import IReviewDto from "../../api/_common/dto/IReviewDto";
import BaseTabViewModel from "./BaseTabViewModel";

abstract class BaseReviewStore extends BaseTabViewModel {
    reviewPage: IPageDto<IReviewDto>;
    processing = false;

    protected abstract async getReviews(apiRequestOptions: IApiRequestOptions): Promise<IResponse<IPageDto<IReviewDto>>>;

    public async initializeTab(): Promise<void> {
        //console.log('BaseReviewStore::initializeTab');
        this.loadingError = null;
        const res = await this.getReviews({});
        if (res.success) {
            this.reviewPage = res.data;
            this.initialized = true;
        } else this.loadingError = res.message;
    }

    loadMoreReviews = async(): Promise<IResponse<IPageDto<IReviewDto>>> => {
        const newPageNumber = (this.reviewPage.number || 0) + 1 + 1;
        this.loadingError = null;
        const res = await this.getReviews({pageNumber: newPageNumber, sort: 'createdAt,desc'});
        if (res.success) {
            this.reviewPage.content = this.reviewPage.content.concat(res.data.content);
            this.reviewPage.number = newPageNumber;
        } else {
            this.loadingError = res.message;
        }
        return res;
    };

}

export const BaseReviewStoreDecorators = {
    reviewPage: observable,
    loadMoreReviews: action
};

export default BaseReviewStore;
