import React, {useState} from 'react';
import {observer} from 'mobx-react';
import useStore from 'src/context/RootStoreContext';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogTitle,
  Divider,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import {CateringTheme} from 'src/theme/type';
import Page from 'src/components/Page';
import {Autocomplete} from "@material-ui/lab";
import {ICurrentCompanyModel} from "../../../../store/SessionStore";
import {Field, Formik} from "formik";
import {useSnackbar} from "notistack";
import NotificationTypeEnum from "../../../../api/_common/enum/NotificationTypeEnum";
import {IResponse} from "../../../../api/ServerApi";
import LoadingScreen from "../../../../components/LoadingScreen";
import {makeUserStr} from "../../../../api/_common/dto/user/IUserDto";
import IUserCompanyDto from "../../../../api/_common/dto/company/IUserCompanyDto";
import UsersApi from "../../../../api/UsersApi";
import {makeCompanyName} from "../../../../api/_common/dto/company/ICompanyDto";

const useStyles = makeStyles((theme: CateringTheme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

interface IFormikCreateNotification {
  notificationType: NotificationTypeEnum;

  title?: string;
  body?: string;
  companyId?: string;
  userId?: string;
};

//const NotificationProfileView:  LoadableView = () => {
function NotificationProfileDialog() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const defaultNotificationType: NotificationTypeEnum = 'ALL';

  const { notificationProfileStore, sessionStore } = useStore();
  const availableCompanies = sessionStore.availableCompanies;
  const [availableUsers, setAvailableUsers] = useState([]);
  const { notification, loadingError } = notificationProfileStore;
  const id = notification && notification.id;
  const notificationType = notificationProfileStore.notificationType;
  const isAdding = !!id ? false : true;

  const open = notificationProfileStore.open;

  const handleOnClose = () => {
    notificationProfileStore.open = false;
  }

  const [availableCompaniesLoading, setAvailableCompaniesLoading] = useState(false);
  const loadUsers = (filter: string) => {
    UsersApi.getUserList({search: null, pageSize: 200, sort: "lastName,asc;firstName,asc;secondName,asc"}).then(resp => {
      if (resp.success) {
        setAvailableUsers(resp.data.content);
      }
    });
  }

  const handleInputUser = (ev) => {
    loadUsers(ev.target.value);
  }

  const handleNotificationType = (ev) => {
    notificationProfileStore.setNotificationType( ev.target.value );

    if (notificationProfileStore.notificationType === 'COMPANY') {
      setAvailableCompaniesLoading(true);
      sessionStore.loadAvailableCompanies().then(response => {
        setAvailableCompaniesLoading(false);
      });
    }

    if (notificationProfileStore.notificationType === 'USER') {
      loadUsers(null);
    }
  }

  const handleChangeCompany = (e, value) => {
    const id = (value as ICurrentCompanyModel) && (value as ICurrentCompanyModel).id;
    notificationProfileStore.notification = {...notificationProfileStore.notification,
      company: {id: id}
    };
  }

  const handleChangeUser = (e, value) => {
    const id = (value as IUserCompanyDto) && (value as IUserCompanyDto).id;
    notificationProfileStore.notification = {...notificationProfileStore.notification,
      user: {id: id}
    };
    setAvailableUsers([]);
  }

  const myHandleSubmit =
      async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        let resp: IResponse;
        switch (notificationProfileStore.notificationType || defaultNotificationType) {
          case "ALL":
            resp = await notificationProfileStore.createNotificationForAll({
              title: values.title,
              body: values.body,
            });
            break;
          case "PARTNER":
            resp = await notificationProfileStore.createNotificationForPartner({
              title: values.title,
              body: values.body,
            });
            break;
          case "COMPANY":
            resp = await notificationProfileStore.createNotificationForCompany({
              title: values.title,
              body: values.body,
              company: notificationProfileStore.notification.company
            });
            break;
          case "USER":
            resp = await notificationProfileStore.createNotificationForUser({
              title: values.title,
              body: values.body,
              user: notificationProfileStore.notification.user
            });
            break;
        }

        if (resp) {
          if (resp.success) {
            setStatus({ success: true });
            handleOnClose();
            enqueueSnackbar('Уведомление отправлено', {variant: 'success'});
          } else {
            setStatus({ success: false });
            enqueueSnackbar('Ошибка создания уведомления: ' + resp.message, {variant: 'error'});
          }
          setSubmitting(false);
        } else {
          setSubmitting(false);
          setStatus({ success: false });
        }
      };

  if (!sessionStore.session) return <span>Пользователь не авторизован</span>;
  if (sessionStore.session.role !== 'ADMIN') return <span>Недоступно с текущими правами</span>;

  if (loadingError)
    return (
      <Page
        className={classes.root}
        title={isAdding ? 'Новое уведомление' : 'Редактирование уведомления'}
      >
        <Typography variant="h6">{loadingError}</Typography>
      </Page>
    );

  return (
      <Dialog
          aria-labelledby="employee-dialog-title"
          open={open}
          onClose={handleOnClose}
          fullWidth={true}
          maxWidth={'sm'}
          closeAfterTransition={false}
      >
        <DialogTitle id="employee-dialog-title">
          Создать уведомление
        </DialogTitle>
        <Container maxWidth="lg" >

          <Formik<IFormikCreateNotification>
              initialValues={{ notificationType: "ALL" }}
              onSubmit={myHandleSubmit}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form>
                  <Box mt={3} height={350}>

                    <Select fullWidth={true} onChange={handleNotificationType}
                            defaultValue={notificationProfileStore.notificationType || defaultNotificationType}
                    >
                      <MenuItem id={'ALL'} value={'ALL'}>Всем</MenuItem>
                      <MenuItem id={'PARTNER'} value={'PARTNER'}>Партнерам</MenuItem>
                      <MenuItem id={'COMPANY'} value={'COMPANY'}>Компании</MenuItem>
                      <MenuItem id={'USER'} value={'USER'}>Пользователю</MenuItem>
                    </Select>

                    <Box m={3}/>
                    <Divider/>

                    { (notificationType === 'COMPANY') && (<>
                      {availableCompaniesLoading ?
                          (<Box style={{height:50}}><LoadingScreen/></Box>)
                          :
                          <Autocomplete
                              className=""
                              noOptionsText={'Не найдено'}
                              options={availableCompanies}
                              getOptionLabel={option => makeCompanyName(option)}
                              defaultValue={null}
                              onChange={handleChangeCompany}
                              renderInput={params => (
                                  <Field component={TextField} {...params} label="Компания" variant="outlined" fullWidth/>
                              )}
                          />
                      }
                    </>)}

                    {notificationType === 'USER' && (<>
                      {availableCompaniesLoading ?
                          (<Box style={{height:50}}><LoadingScreen/></Box>)
                          :
                          <Autocomplete
                              className=""
                              noOptionsText={'Не найдено'}
                              options={availableUsers}
                              getOptionLabel={option => makeUserStr(option)}
                              defaultValue={null}
                              onChange={(e, value) => {
                                notificationProfileStore.notification = {...notificationProfileStore.notification,
                                  user: {id: (value as IUserCompanyDto) && (value as IUserCompanyDto).id }}
                              }}
                              renderInput={params => (
                                  <Field component={TextField} onChange={handleInputUser}  {...params} label="Пользователь" variant="outlined" fullWidth/>
                              )}
                          />
                      }
                    </>)}

                    <TextField
                        required
                        error={Boolean(errors.title)}
                        fullWidth
                        helperText={errors.title}
                        label="Заголовок"
                        margin="normal"
                        name="title"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="title"
                        value={values.title}
                        variant="outlined"
                    />

                    <TextField
                        required
                        error={Boolean(errors.body)}
                        fullWidth
                        helperText={errors.body}
                        label="Текст"
                        margin="normal"
                        name="body"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="body"
                        value={values.body}
                        variant="outlined"
                        multiline={true}
                        rows={3}
                        rowsMax={3}
                    />

                    <Box mt={2}>
                      <Button
                          color="secondary"
                          disabled={isSubmitting}
                          size="large"
                          type="button"
                          onClick={(ev) => handleSubmit()}
                          variant="contained"
                      >
                        Создать
                      </Button>
                    </Box>
                  </Box>
                </form>
            )}
          </Formik>


        </Container>
      </Dialog>
  );
};
NotificationProfileDialog.getInitialProps = async (props): Promise<{}> => {
  /*const { id } = props.match.params as ICompanyNewsProfileParams;
  await props.store.companyNewsProfileStore.initialize(props.store.sessionStore.company.id, id ? Number(id) : null);*/
  return {};
};

//export default withViewLoader(observer(NotificationProfileDialog));
export default observer(NotificationProfileDialog);

