import React, { FunctionComponent } from 'react';
import { Formik } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';

import {
  Box,
  Button,
  TextField,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import useStore from 'src/context/RootStoreContext';
import SignInApi from 'src/api/SignInApi';

interface IResetPasswordFormProps {
  className?: string;
  token: string;
  onSubmitSuccess: () => void;
  [key: string]: any;
}

const useStyles = makeStyles(() => ({
  root: {}
}));

const ResetPasswordForm: FunctionComponent<IResetPasswordFormProps> = ({
  className,
  token,
  onSubmitSuccess,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Formik<{ password: string; matchingPassword: string; submit?: string }>
      initialValues={{ password: '', matchingPassword: '' }}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const result = await SignInApi.resetPassword({
          token,
          password: values.password,
          matchingPassword: values.matchingPassword
        });
        if (result.success) {
          setErrors({});
          onSubmitSuccess();
        } else {
          const { message, details } = result;
          setStatus({ success: false });
          setErrors(details || { submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            error={Boolean(errors.password)}
            fullWidth
            helperText={errors.password}
            label="Новый пароль"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            required
          />
          <TextField
            error={Boolean(errors.matchingPassword)}
            fullWidth
            helperText={errors.matchingPassword}
            label="Повторите пароль"
            margin="normal"
            name="matchingPassword"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.matchingPassword}
            variant="outlined"
            required
          />
          <Box mt={2}>
            <Button
              color="secondary"
              disabled={isSubmitting}
              size="large"
              type="submit"
              variant="contained"
            >
              Сохранить
            </Button>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default React.memo(ResetPasswordForm);
