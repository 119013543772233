import { action, decorate, observable } from 'mobx';
import { IResponse } from 'src/api/ServerApi';
import RootStoreModel from '../../_root/RootStoreModel';
import CompanyNewsApi from '../../../api/CompanyNewsApi';
import IEditCompanyNewsRequesDto from '../../../api/CompanyNewsApi/request/IEditCompanyNewsRequestDto';
import ICompanyNewsDto from '../../../api/_common/dto/companyNews/ICompanyNewsDto';
import CompanyNewsFilesTabViewModel from './tab/CompanyNewsFilesTabViewModel';
import IPhotoWithDescriptionDto from '../../../api/_common/dto/IPhotoWithDescriptionDto';

class CompanyNewsProfileStore extends RootStoreModel {
  loadingError = null;

  processing: boolean = false;

  companyId: number;

  news: ICompanyNewsDto;

  images: CompanyNewsFilesTabViewModel;

  initialize = async (companyId: number, newsId: number): Promise<void> => {
    this.companyId = companyId;

    if (!newsId) {
      this.news = {};
    } else {
      const res = await CompanyNewsApi.getNews(companyId, newsId);
      if (res.success) {
        this.news = res.data;
        this.images = new CompanyNewsFilesTabViewModel(this.root, companyId, newsId);
      }
      if (!this.news) this.news = {};
    }
  };

  saveNews = async (body: IEditCompanyNewsRequesDto): Promise<IResponse<ICompanyNewsDto>> => {
    const res = await CompanyNewsApi.saveNews(this.companyId, body);
    if (res.success) {
      const newsForGrid = res.data;
      this.news.id = newsForGrid.id;
    }
    return res;
  };

  updateMainImage = (image: IPhotoWithDescriptionDto) => {
    this.news = { ...this.news, newsCompanyImageMain: image };
  }
}

decorate(CompanyNewsProfileStore, {
  news: observable,
  images: observable,
  saveNews: action,
  updateMainImage: action,
});

export default CompanyNewsProfileStore;
