import RootStoreModel from '../_root/RootStoreModel';

import { AccountTabType } from './const';

import ProfileApi from 'src/api/ProfileApi/MyProfileApi';

import IUserCompanyDto from 'src/api/_common/dto/company/IUserCompanyDto';
import ServerApi, { IResponse } from 'src/api/ServerApi';
import { decorate, action, observable } from 'mobx';
import IPersonalDataRequestDto from 'src/api/ProfileApi/request/IPersonalDataRequestDto';
import DictionaryStore from '../DictionaryStore';
import CompaniesApi from "../../api/CompaniesApi";
import {LOCAL_STORAGE_COMPANY_COUNT} from "../../const/localStorage";

class AccountStore extends RootStoreModel {
  currentTab: AccountTabType = 'profile';
  loadingError: string = '';

  companies: IUserCompanyDto[];

  setCurrentTab = (page: AccountTabType): void => {
    this.currentTab = page;
  };

  isInitializeSuccess(res: IResponse): boolean {
    if (res.success) return true;
    this.loadingError = res.message;
    return false;
  }

  async initializeProfilePage(): Promise<void> {
    if (DictionaryStore.locations) return;
    const res = await DictionaryStore.initializeLocations();
    if (!res.success) this.loadingError = res.message;
  }

  async initializeCompaniesPage(): Promise<void> {
    const res = await ProfileApi.getUserCompanyList();
    this.performAsAction(() => {
      if (this.isInitializeSuccess(res)) this.companies = res.data.content;
      else this.companies = [];
    });
  }

  async updateProfile(data: IPersonalDataRequestDto): Promise<IResponse> {
    const res = await ProfileApi.editPersonalData(data);
    if (res.success) await ServerApi.refreshTokens();
    return res;
  }

  handleAcceptInvite = async (id: number): Promise<IResponse> => {
    const res = await CompaniesApi.approveInviteById(id);
    if (res.success) {
      this.companies = this.companies.map(x => x.company.id === id ? {...x, status: 'APPROVED'} : x);
    }
    return res;
  };

  handleRejectInvite = async (id: number): Promise<IResponse> => {
    const res = await CompaniesApi.declineInviteById(id);
    if (res.success) {
      this.companies = this.companies.filter(x => x.company.id !== id);
    }
    return res;
  };

  deleteMyProfile = async (): Promise<IResponse> => {
    return await ProfileApi.deleteMyProfile();
  };
}

decorate(AccountStore, {
  currentTab: observable,
  // setCurrentTab: action.bound,
  companies: observable,
  isInitializeSuccess: action,
  initializeCompaniesPage: action,
  handleAcceptInvite: action,
  handleRejectInvite: action
});

export default AccountStore;
