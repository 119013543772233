import BaseRequestOptions from "./BaseRequestOptions";
import {orderEnumPartnerState, OrderStatusEnum} from "../enum/OrderStatusEnum";
import IApiRequestOptions from "./IApiRequestOptions";

export default class OrderRequestOptions extends BaseRequestOptions {
  formatId?: number;
  state: string; // aaa-bbb-ccc
  todayOnly?: boolean;

  partnerAll?: boolean;

  constructor() {
    super();
    this.pageSize = 25;
    this.sortField = 'cratedAt,desc';
    this.state = orderEnumPartnerState;
  }

  asGetParams(): IApiRequestOptions {
    let res = super.asGetParams();

    let paramsArr: string[] = [];
    const filterParam = res.search;
    if (filterParam) paramsArr.push(filterParam);

    if (this.formatId) paramsArr.push("formatId," + this.formatId);
    if (this.state) paramsArr.push("state," + this.state);
    if (this.todayOnly) paramsArr.push("todayOnly,true");

    if (paramsArr.length) res.search = paramsArr.join(";");

    return res;
  };
}
