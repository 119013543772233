import {action, computed, decorate, observable, runInAction} from 'mobx';
import IPageDto from "../../../api/_common/dto/IPageDto";
import {IResponse} from "../../../api/ServerApi";
import BaseListStore, {BaseListStoreDecorators} from "../../Common/BaseListStore";
import IApiRequestOptions from "../../../api/_common/request/IApiRequestOptions";
import NotificationRequestOptions from "../../../api/_common/request/NotificationRequestOptions";
import NotificationsApi from "../../../api/NotificationsApi";
import INotificationDto from "../../../api/_common/dto/notification/INotificationDto";

class NotificationListStore extends BaseListStore<INotificationDto, NotificationRequestOptions> {
    initialized: boolean = false;
    updating: boolean = false;
    unreadCount: number = 0;

    protected getDefaultRequestOptions(): NotificationRequestOptions {
        const res = new NotificationRequestOptions();
        return res;
    }

    newestId = () => {
        return this.page && this.page.content && this.page.content.length && this.page.content[0].id;
    }

    private scheduleReload = async () => {
        this.updating = true;

        const requestOptions = this.requestOptions;
        const response = await this.getListRoutine(
            requestOptions.asGetParams()
        );

        if (requestOptions.pageNumber !== this.requestOptions.pageNumber ||
            requestOptions.pageSize !== this.requestOptions.pageSize) {
            return;
        }

        runInAction(() => {
            if (response.success) {
                this.loadingError = null;
                if (!this.newestId()) {
                    this.page = response.data;
                } else {
                    const receivedNewestId = response.data && response.data.content && response.data.content.length && response.data.content[0].id;
                    if (this.newestId() < receivedNewestId) {
                        this.page = response.data;
                    }
                }
                this.unreadCount = this.calcUnreadCount();
            }
        });

        this.updating = false;
    }

    initialize = async (): Promise<void> => {
        if (this.initialized) return;
        this.requestOptions = this.getDefaultRequestOptions();

        setInterval( async () => {
            if (!this.updating) {
                this.scheduleReload();
            }
        }, 60 * 1000);

        this.initialized = true;
    };

    protected async deleteManyRoutine(ids: number[]): Promise<IResponse> {
        return {success: true};
    }

    protected async deleteOneRoutine(id: number, description: string): Promise<IResponse> {
        return {success: true};
    }

    protected async getListRoutine(apiRequestOptions: IApiRequestOptions, options?: any): Promise<IResponse> {
        return NotificationsApi.getNotificationList(apiRequestOptions);
    }

    imitateLoadResults = (requestOptions: NotificationRequestOptions): IPageDto<INotificationDto> => {
        return  {
            totalElements: 0,
            totalPages: 1,
            size: 0,
            number: 0,
            content: []
        };
    };

    markAsRead = async () => {
        if ( !(this.page && this.page.content && this.page.content.length) ) return;
        const res = await NotificationsApi.markAsRead(this.page.content[0].id);
        if (res.success) {
            this.page.content.forEach(x => x.isRead = true);
            this.unreadCount = 0;
        }
    }

    private calcUnreadCount = (): number => {
        return this.page && this.page.content && this.page.content.filter(x => !x.isRead).length;
    }
}

decorate(NotificationListStore, {...BaseListStoreDecorators,
    initialized: observable,
    unreadCount: observable,
    markAsRead: action,
});

export default NotificationListStore;