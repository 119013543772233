import {action, decorate, observable} from 'mobx';
import IPageDto from "../../api/_common/dto/IPageDto";
import {IResponse} from "../../api/ServerApi";
import BaseListStore, {BaseListStoreDecorators} from "../Common/BaseListStore";
import IApiRequestOptions from "../../api/_common/request/IApiRequestOptions";
import CompanyRequestOptions from "../../api/_common/request/CompanyRequestOptions";
import CompaniesApi from "../../api/CompaniesApi";
import ICompanyForGridDto from "../../api/_common/dto/company/ICompanyForGridDto";
import RootStoreModel from "../_root/RootStoreModel";

class AlertModalStore extends RootStoreModel{
    open: boolean = false;
    title: string;
    body: string;
    handleConfirm?: Function;
    handleCancel?: Function;

  openModal = (title: string, body: string, handleConfirm?: Function, handleCancel?: Function) => {
      if (this.open) {
          this.closeModal();
      }

      this.title = title;
      this.body = body;
      this.handleConfirm = handleConfirm;
      this.handleCancel = handleCancel;

      this.open = true;
  };

  closeModal = () => {
      this.open = false;

      this.title = '';
      this.body = '';
      this.handleConfirm = null;
      this.handleCancel = null;
  }
}

export const AlertModalStoreDecorators = {
    open: observable,
    title: observable,
    body: observable,
    openModal: action,
    closeModal: action,
};

decorate(AlertModalStore, AlertModalStoreDecorators);

export default AlertModalStore;