import IPlatformDto from './dto/IPlatformDto';
import ICreateEditPlatformRequestDto from './dto/ICreateEditPlatformRequestDto';
import ServerApi, {IResponse} from '../ServerApi';
import IEditWorkTimeRequestDto from '../_common/request/IEditWorkTimeRequestDto';
import IPageDto from '../_common/dto/IPageDto';
import IPhotoWithDescriptionDto from '../_common/dto/IPhotoWithDescriptionDto';
import IApiRequestOptions from "../_common/request/IApiRequestOptions";
import ITradeDto from "../_common/dto/trade/ITradeDto";
import ITradeReviewDto from "../_common/dto/trade/ITradeReviewDto";

export default class PlatformApi {

  static async getTradeList(
      apiRequestOptions: IApiRequestOptions,
      companyId: number
  ): Promise<IResponse<IPageDto<ITradeDto>>> {
    return ServerApi.getQuery(`companies/${companyId}/platforms`, {
      params: apiRequestOptions
    });
  }

  static async deleteMany(ids: number[]): Promise<IResponse<any>> {
    return ServerApi.postQuery('platforms/removeMultiple', null, {
      params: { platformIds: ids && ids.join(',') },
      headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
    }  );
  }
  static async deleteOne(id: number, description: string): Promise<IResponse<any>> {
    return ServerApi.postQuery(`platforms/${id}/remove`, null, {
      params: { description: description },
      headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
    });
  }

  static async getPlatform(id: number): Promise<IResponse<IPlatformDto>> {
    return ServerApi.getQuery(`platforms/${id}`);
  }
  static async createPlatform(
    body: ICreateEditPlatformRequestDto
  ): Promise<IResponse<IPlatformDto>> {
    return ServerApi.postQuery('platforms', body);
  }
  static async editPlatform(
    body: ICreateEditPlatformRequestDto
  ): Promise<IResponse<IPlatformDto>> {
    return ServerApi.postQuery(`platforms/${body.id}/settings`, body);
  }

  static async deletePlatform(platformId: number): Promise<IResponse> {
    return ServerApi.postQuery(`platforms/${platformId}`, {});
  }

  static async changePlatformWorkTime(
    platformId: number,
    body: IEditWorkTimeRequestDto
  ): Promise<IResponse<IPlatformDto>> {
    return ServerApi.postQuery(`platforms/${platformId}/workTimes`, body);
  }

  static async getPlatformImages(
    platformId: number
  ): Promise<IResponse<IPageDto<IPhotoWithDescriptionDto>>> {
    return ServerApi.getQuery(`platforms/${platformId}/images`);
  }

  static async addPlatformImage(
    platformId: number,
    file: File
  ): Promise<IResponse<IPhotoWithDescriptionDto>> {
    return ServerApi.uploadFileQuery(`platforms/${platformId}/images`, file);
  }

  static async deletePlatformImage(
    platformId: number,
    imageId: number
  ): Promise<IResponse<IPageDto<IPhotoWithDescriptionDto>>> {
    return ServerApi.deleteQuery(`platforms/${platformId}/images/${imageId}`);
  }

  static async markPlatformImageAsMain(
    platformId: number,
    imageId: number
  ): Promise<IResponse<IPhotoWithDescriptionDto>> {
    return ServerApi.postQuery(
      `platforms/${platformId}/images/${imageId}/makeMain`,
      {}
    );
  }

  static async changePlatformImageDescription(
    platformId: number,
    imageId: number,
    description: string
  ): Promise<IResponse<IPhotoWithDescriptionDto>> {
    return ServerApi.postQuery(
      `platforms/${platformId}/images/${imageId}/description`,
      { description }
    );
  }

  static async getReviews(platformId: number, apiRequestOptions?: IApiRequestOptions): Promise<IResponse<IPageDto<ITradeReviewDto>>> {
        return ServerApi.getQuery(`platforms/${platformId}/reviews`, {params: apiRequestOptions});
  }

}
