import React, { FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import Account from './Account';
import Notifications from './Notifications';
import Search from './Search';
import Settings from './Settings';
import useStore from 'src/context/RootStoreContext';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main
  },
  toolbar: {
    minHeight: 64,
    height: 64
  }
}));

interface ITopBarProps {
  onMobileNavOpen: () => void;
  className?: string;
  [key: string]: any;
}

const TopBar: FunctionComponent<ITopBarProps> = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const { authenticated } = useStore().sessionStore;
  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            // className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/app">
            <Logo topbar />
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        {/* <Search /> */}
        {/* <Contacts /> */}
        { <Notifications /> }
        {/* <Settings /> */}
        {authenticated && (
          <Box ml={2}>
            <Account />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
