import {action, decorate, observable} from 'mobx';
import RootStoreModel from "../_root/RootStoreModel";
import FrontApi from "../../api/FrontApi";
import {delay} from "q";
import {IResponse} from "../../api/ServerApi";

class ServiceTabsStore extends RootStoreModel{
  currentTab: string = 'food';

  setCurrentTab = (value: string) => {
      this.currentTab = value || 'food';
  }

}

decorate(ServiceTabsStore, {
    currentTab: observable,
    setCurrentTab: action,
});

export default ServiceTabsStore;