import ISignUpPartnerRequestDto from './request/ISignUpPartnerRequestDto';
import qs from 'query-string';
import ServerApi, { IResponse } from '../ServerApi';
import { AxiosRequestConfig } from 'axios';
import IAuthTokenDto from '../_common/dto/IAuthTokenDto';
import IResetPasswordRequest from './request/IResetPasswordRequest';

export default class SignInApi {
  static async signIn(
    email: string,
    password: string
  ): Promise<IResponse<IAuthTokenDto>> {
    const body = { email, password };
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };
    return ServerApi.postQuery('auth/sign', qs.stringify(body), config);
  }

  static async signUpPartner(
    body: ISignUpPartnerRequestDto
  ): Promise<IResponse> {
    return ServerApi.postQuery('auth/signUpPartner', body);
  }

  static async verifyAccount(token: string): Promise<IResponse> {
    return ServerApi.postQuery('auth/verifyAccount', { token });
  }

  static async getForgotPasswordToken(email: string): Promise<IResponse> {
    return ServerApi.postQuery('auth/forgotPassword', { email });
  }

  static async resetPassword(body: IResetPasswordRequest): Promise<IResponse> {
    return ServerApi.postQuery('auth/resetPassword', body);
  }
}
