import ServerApi, {IResponse} from '../ServerApi';
import IPageDto from '../_common/dto/IPageDto';
import IOrderDto from "../_common/dto/order/IOrderDto";
import IApiRequestOptions from "../_common/request/IApiRequestOptions";
import IOfferDto from "../_common/dto/offer/IOfferDto";
import ICreateEditOfferRequestDto from "./dto/ICreateEditOfferRequestDto";
import IPhotoWithDescriptionDto from "../_common/dto/IPhotoWithDescriptionDto";
import IChangeMenuDto from "./dto/IChangeMenuDto";
import IChangeGoodsDto from "./dto/IChangeGoodsDto";
import ITradeReviewDto from "../_common/dto/trade/ITradeReviewDto";

export default class OffersApi {
  static async getOfferList(companyId: number, apiRequestOptions: IApiRequestOptions): Promise<IResponse<IPageDto<IOrderDto>>> {
    let arr = [apiRequestOptions.search, `companyId,${companyId}`].filter(x => x);
    return ServerApi.getQuery('offer', {params: {...apiRequestOptions, search: arr.join(';')}});
  }
  static async deleteMany(companyId: number, ids: number[]): Promise<IResponse<any>> {
    return ServerApi.postQuery('offer/delete', {companyId: companyId, ids: ids});
  }
  static async deleteOne(companyId: number, id: number): Promise<IResponse<any>> {
    return this.deleteMany(companyId, [id]);
    /*return ServerApi.deleteQuery('offer/delete', {
      params: { companyId: companyId, id: id },
      headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
    });*/
  }

  static async getOffer(id: number): Promise<IResponse<IOfferDto>> {
    return ServerApi.postQuery(`offer/get/`, null, {
      params: { id: id },
      headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
    });
  }
  static async createEditOffer(
      body: ICreateEditOfferRequestDto
  ): Promise<IResponse<IOfferDto>> {
    return ServerApi.postQuery('offer/save', body);
  }

  static async getOfferImages(
      offerId: number
  ): Promise<IResponse<IPhotoWithDescriptionDto[]>> {
    return ServerApi.getQuery(`offer/${offerId}/images`);
  }

  static async addOfferImage(
      offerId: number,
      file: File
  ): Promise<IResponse<IPhotoWithDescriptionDto>> {
    return ServerApi.uploadFileQuery(`offer/${offerId}/images`, file);
  }

  static async deleteOfferImage(
      offerId: number,
      imageId: number
  ): Promise<IResponse<IPageDto<IPhotoWithDescriptionDto>>> {
    return ServerApi.deleteQuery(`offer/${offerId}/images/${imageId}`);
  }

  static async markOfferImageAsMain(
      offerId: number,
      imageId: number
  ): Promise<IResponse<IPhotoWithDescriptionDto>> {
    return ServerApi.postQuery(
        `offer/${offerId}/images/${imageId}/makeMain`,
        {}
    );
  }

  static async changeOfferImageDescription(
      offerId: number,
      imageId: number,
      description: string
  ): Promise<IResponse<IPhotoWithDescriptionDto>> {
    return ServerApi.postQuery(
        `offer/${offerId}/images/${imageId}/description`,
        { description }
    );
  }

  static async changeMenu(
      dto: IChangeMenuDto
  ): Promise<IResponse<IOfferDto>> {
    return ServerApi.postQuery(`offer/${dto.offerId}/food/set`, dto.foods);
  }
  static async changeGoods(
      dto: IChangeGoodsDto
  ): Promise<IResponse<IOfferDto>> {
    return ServerApi.postQuery(`offer/${dto.offerId}/goods/set`, dto.goods);
  }

  static async getReviews(offerId: number, apiRequestOptions?: IApiRequestOptions): Promise<IResponse<IPageDto<ITradeReviewDto>>> {
    return ServerApi.getQuery(`offer/${offerId}/reviews`, {params: apiRequestOptions});
  }
}
