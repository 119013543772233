import BaseRequestOptions from './BaseRequestOptions';

export default class NotificationRequestOptions extends BaseRequestOptions {

    constructor() {
        super();
        this.pageSize = 5;
        this.sortField = 'id,desc';
    }

}
