import React, {FunctionComponent} from 'react';
import {Box, Link, List, ListItem, Paper, Typography} from '@material-ui/core';

const CompanyInactiveView: FunctionComponent = () => {
  return (
      <Box display="flex" justifyContent="center" mt={6} alignContent={'center'}>
          <Paper elevation={3} style={{minHeight:200, minWidth: 300, padding: 20}}>
              <Box display="flex" justifyContent="center" mt={6} alignContent={'center'}>
                  <Typography  variant="h4" color="textPrimary">
                      Ваша компания неактивна.
                  </Typography>
              </Box>
              <Box display="flex" justifyContent="center" mt={3} alignContent={'center'}>
                  <Typography variant="body1" >
                      Это возможно по одной из следующих причин:
                      <List>
                          <ListItem key={1}>
                              1. Ваша компания еще находится на рассмотрении на предмет возможного партнерства.
                          </ListItem>
                          <ListItem key={2}>
                              2. Ваша компания заблокирована за нарушение правил сервиса.
                          </ListItem>

                      </List>
                      <br/>
                      Уточнить состояние Вашей компании вы  можете направив запрос на на почту &nbsp;
                      <Link href="mailto:info@cateringworld.ru">info@cateringworld.ru</Link>
                  </Typography>
              </Box>
          </Paper>
      </Box>
  );
};

export default CompanyInactiveView;
