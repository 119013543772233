import {decorate} from 'mobx';
import IPageDto from "../../../api/_common/dto/IPageDto";
import TradeRequestOptions from "../../../api/_common/request/TradeRequestOptions";
import ITradeDto from "../../../api/_common/dto/trade/ITradeDto";
import {IResponse} from "../../../api/ServerApi";
import BaseListStore, {BaseListStoreDecorators} from "../../Common/BaseListStore";
import IApiRequestOptions from "../../../api/_common/request/IApiRequestOptions";
import PlatformApi from "../../../api/PlatformApi";

class TradeListStore extends BaseListStore<ITradeDto, TradeRequestOptions> {

    protected getDefaultRequestOptions(): TradeRequestOptions {
        const res = new TradeRequestOptions();
        res.sortField = 'createdAt|desc';
        return res;
    }

    protected async deleteManyRoutine(ids: number[]): Promise<IResponse> {
        return PlatformApi.deleteMany(ids);
    }

    protected async deleteOneRoutine(id: number, description: string): Promise<IResponse> {
        return PlatformApi.deleteOne(id, description);
    }

    protected async getListRoutine(apiRequestOptions: IApiRequestOptions, options?: any): Promise<IResponse> {
        if (!options || !options.companyId) return null;
        return PlatformApi.getTradeList(apiRequestOptions, options.companyId);
    }

    load = async (requestOptions: TradeRequestOptions): Promise<IResponse> => {
        return this.loadList(requestOptions, {companyId: this.getRootStore().sessionStore.company.id});
    }

    imitateLoadResults = (requestOptions: TradeRequestOptions): IPageDto<ITradeDto> => {
        return  {
            totalElements: 0,
            totalPages: 1,
            size: 0,
            number: 0,
            content: []
        };
  };
}

decorate(TradeListStore, BaseListStoreDecorators);

export default TradeListStore;