import BaseRequestOptions from "./BaseRequestOptions";
import IApiRequestOptions from "./IApiRequestOptions";

export default class UserRequestOptions extends BaseRequestOptions {
    isEnabled?: boolean;

    asGetParams(): IApiRequestOptions {
    let res = super.asGetParams();

    let paramsArr: string[] = [];
    const filterParam = res.search;
    if (filterParam) paramsArr.push(filterParam);

    if (this.isEnabled !== null && this.isEnabled !== undefined) paramsArr.push("isEnabled," + this.isEnabled);

    if (paramsArr.length) res.search = paramsArr.join(";");

    return res;
  };

    protected makeSearchParam(): string {
        if (!this.filter) return null;
        return 'name,'+this.filter;
    }

}
