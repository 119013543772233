import {decorate} from 'mobx';
import IPageDto from "../../../api/_common/dto/IPageDto";
import {IResponse} from "../../../api/ServerApi";
import BaseListStore, {BaseListStoreDecorators} from "../../Common/BaseListStore";
import IApiRequestOptions from "../../../api/_common/request/IApiRequestOptions";
import FoodApi from "../../../api/ServicesApi/FoodApi";
import OfferRequestOptions from "../../../api/_common/request/OfferRequestOptions";
import IOfferForGridDto from "../../../api/_common/dto/offer/IOfferForGridDto";
import OffersApi from "../../../api/OffersApi";

class OfferListStore extends BaseListStore<IOfferForGridDto, OfferRequestOptions> {

    protected getDefaultRequestOptions(): OfferRequestOptions {
        const res = new OfferRequestOptions();
        res.sortField = 'name,asc';
        return res;
    }

    protected async deleteManyRoutine(ids: number[]): Promise<IResponse> {
        return OffersApi.deleteMany(this.getCompanyId(), ids);
    }

    protected async deleteOneRoutine(id: number): Promise<IResponse> {
        return OffersApi.deleteOne(this.getCompanyId(), id);
    }

    protected async getListRoutine(apiRequestOptions: IApiRequestOptions, options?: any): Promise<IResponse> {
        return OffersApi.getOfferList(this.getCompanyId(), apiRequestOptions);
    }


  imitateLoadResults = (requestOptions: OfferRequestOptions): IPageDto<IOfferForGridDto> => {
      return  {
          totalElements: 0,
          totalPages: 1,
          size: 0,
          number: 0,
          content: []
      };
  };

}

decorate(OfferListStore, BaseListStoreDecorators);

export default OfferListStore;