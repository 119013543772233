import {decorate} from 'mobx';
import IPageDto from "../../../api/_common/dto/IPageDto";
import {IResponse} from "../../../api/ServerApi";
import BaseListStore, {BaseListStoreDecorators} from "../../Common/BaseListStore";
import IApiRequestOptions from "../../../api/_common/request/IApiRequestOptions";
import ICompanyNewsForGridDto from "../../../api/_common/dto/companyNews/ICompanyNewsForGridDto";
import CompanyNewsRequestOptions from "../../../api/_common/request/CompanyNewsRequestOptions";
import CompanyNewsApi from "../../../api/CompanyNewsApi";

class CompanyNewsListStore extends BaseListStore<ICompanyNewsForGridDto, CompanyNewsRequestOptions> {

    protected getDefaultRequestOptions(): CompanyNewsRequestOptions {
        const res = new CompanyNewsRequestOptions();
        res.sortField = 'start,desc';
        return res;
    }

    protected async deleteManyRoutine(ids: number[]): Promise<IResponse> {
        return CompanyNewsApi.deleteMany(ids);
    }

    protected async deleteOneRoutine(id: number, description: string): Promise<IResponse> {
        return CompanyNewsApi.deleteOne(this.getCompanyId(), id);
    }

    protected async getListRoutine(apiRequestOptions: IApiRequestOptions, options?: any): Promise<IResponse> {
        return CompanyNewsApi.getNewsList(this.getCompanyId(), apiRequestOptions);
    }

    imitateLoadResults = (requestOptions: CompanyNewsRequestOptions): IPageDto<ICompanyNewsForGridDto> => {
        return  {
            totalElements: 0,
            totalPages: 1,
            size: 0,
            number: 0,
            content: []
        };
    };

    public getNewsById(id: number): ICompanyNewsForGridDto {
        return this.page && this.page.content && this.page.content.filter(x => x.id === id)[0];
    }
    public pushNewsDto(dto: ICompanyNewsForGridDto) {
        if (this.page && this.page.content) this.page.content.push(dto);
    }
}

decorate(CompanyNewsListStore, BaseListStoreDecorators);

export default CompanyNewsListStore;