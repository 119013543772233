import React from 'react';
import clsx from 'clsx';
import {Avatar, Box, Card, CircularProgress, Grid, Link, makeStyles, Tooltip, Typography} from '@material-ui/core';
import {Clipboard as ClipboardIcon} from 'react-feather';
import {Link as RouterLink} from "react-router-dom";
import ResultsTable from "../pages/Order/OrderListView/ResultsTable";
import Label from "../../components/Label";
import IOrderDto from "../../api/_common/dto/order/IOrderDto";
import {rub} from "../../utils/StringUtil";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  label: {
    marginLeft: theme.spacing(1),
    fontSize: '26px',
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: 48,
    width: 48
  }
}));

function calcMoney(orders: IOrderDto[]): number {
  let res = 0;
  if (!orders) return res;
  orders.forEach(order => {
    if (order.price && order.state !== "canceled") res += order.price;
  })
  return res;
}

function TodayOrderList({orders, ...rest}) {
  const classes = useStyles();
  const isLoading = orders === undefined;
  const loadingError = orders === null;

  const money = calcMoney(orders);
  //console.log('TodayOrderList');


  return (
    <Card
      className={clsx(classes.root, "")}
    >
      <Grid container={true} spacing={1} >
        <Grid item lg={2} md={12} xs={12}>
          <Grid container spacing={2} alignItems={"center"} justify={"center"}>
            <Grid item xs={8}  justify={"center"}>
              <Typography
                  component="h3"
                  variant="overline"
                  color="textSecondary"
                  style={{lineHeight: '2em'}}
              >
                Заказы сегодня
              </Typography>

            </Grid>
            <Grid item xs={4}>
              <Tooltip title="Перейти ко всем заказам">
                <Link
                    component={RouterLink}
                    to="/app/orders"
                >
                  <Avatar className={classes.avatar}>
                    <ClipboardIcon />
                  </Avatar>
                </Link>
              </Tooltip>
            </Grid>
            {(money > 0) && (<>
              <Grid item xs={12}>
                <Typography
                    component="h3"
                    gutterBottom
                    variant="overline"
                    color="textPrimary"
                    style={{lineHeight: '2em'}}
                >
                  Общая сумма
                </Typography>
              </Grid>
              <Grid item xs={12}>
                  <Label
                      className={classes.label}
                      color={'success'}
                      style={{whiteSpace: 'nowrap'}}
                  >
                    {rub(money)}
                  </Label>
              </Grid>
            </>)}
          </Grid>
        </Grid>

        <Grid item lg={10} md={12} xs={12}>
          {loadingError ?
              <span>Ошибка загрузки таблицы</span>
                :
              <ResultsTable orders={orders} isLoading={isLoading} hasPartnerName={false} className={""}/>
          }
        </Grid>
      </Grid>
    </Card>
  );
}

export default TodayOrderList;

