import React, {FunctionComponent, useCallback, useState} from 'react';
import useStore from "../../../context/RootStoreContext";
import ModalComponent, {ChangeCompanySubmitFuncType} from "./ModalComponent";
import {useSnackbar} from "notistack";
import LoadingScreen from "../../../components/LoadingScreen";

interface IProps {
  open: boolean;
  isLoading: boolean;
  onClose: () => void
}

const ChangeCompanyModal: FunctionComponent<IProps> = ({
  open,
  isLoading,
  onClose
}) => {

  const {sessionStore} = useStore();
  const currentCompany = sessionStore.company;

  const { enqueueSnackbar } = useSnackbar();
  const [changeCompanyOpen, setChangeCompanyOpen] = useState(false);

  const handleChangeCompanyClose = useCallback(() => {
    setChangeCompanyOpen(false);
    if (onClose) onClose();
  }, [setChangeCompanyOpen]);

  const handleChangeCompanySubmit = useCallback<ChangeCompanySubmitFuncType>(
      async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        setStatus({ success: true });
        resetForm();
        setChangeCompanyOpen(false);
        sessionStore.updateCompany2(sessionStore.availableCompanies.filter(x =>{return x.id == values.companyId})[0]);
        enqueueSnackbar('Выбрана компания ' + sessionStore.company.companyName, {
          variant: 'success'
        });
        setSubmitting(false);
        if (onClose) onClose();
        window.location.reload(false); // TODO: подумать
      },
      [setChangeCompanyOpen]
  );

  if (!open) return (<></>);
  if (isLoading) return (<LoadingScreen/>);

  return (
      <ModalComponent
          initialCompanyId={currentCompany.id}
          availableCompanies={sessionStore.availableCompanies}
          open={open}
          onClose={handleChangeCompanyClose}
          onSubmit={handleChangeCompanySubmit}
      />
  );
};

export default ChangeCompanyModal;
