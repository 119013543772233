import React, {useEffect, useRef, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
  Avatar, Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Popover,
  SvgIcon,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  Bell as BellIcon,
  MessageCircle as MessageIcon,
  Package as PackageIcon,
  Truck as TruckIcon
} from 'react-feather';
import useStore from "../../../context/RootStoreContext";
import {observer} from "mobx-react";
import htmlDecorate from "../../../utils/html-decorator";

const iconsMap = {
  order_placed: PackageIcon,
  new_message: MessageIcon,
  item_shipped: TruckIcon
};

const useStyles = makeStyles(theme => ({
  popover: {
    width: 500
  },
  iconNew: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    margin: 5
  },
  iconOld: {
    backgroundColor: theme.palette.secondary.contrastText,
    color: theme.palette.secondary.main,
    margin: 5
  },
  listItem: {
    padding: theme.spacing(2, 0),
    justifyContent: 'space-between'
  },
  list: {
    maxHeight: 500
  },
}));

function Notifications() {

  const classes = useStyles();

  const notificationListStore = useStore().notificationListStore;
  const notifications = notificationListStore.page && notificationListStore.page.content;
  const storeLoaded = !!notificationListStore.page;

  const ref = useRef(null);

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
     setOpen(true);

     setTimeout(() => {
       notificationListStore.markAsRead();
     }, 3000);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    notificationListStore.initialize();
  });

  const handleOpenNotification = (notification) => {

  }

  if (!storeLoaded) return (<></>);

  return (
    <>
      <Tooltip title="Уведомления">
        <IconButton
          color="inherit"
          ref={ref}
          onClick={handleOpen}
        >
          <Badge color="error" badgeContent={notificationListStore.unreadCount}>
            <SvgIcon>
              <BellIcon />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      { <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography
            variant="h5"
            color="textPrimary"
          >
            Уведомления
          </Typography>
        </Box>
        {!notifications || notifications.length === 0 ? (
          <Box p={2}>
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Нет уведомлений
            </Typography>
          </Box>
        ) : (
          <>
            <List
              className={classes.list}
              disablePadding
            >
              {notifications && notifications.slice(0, 5).map((notification) => {
                //const Icon = MessageIcon /*notification.type*/;

                return (
                  <ListItem
                    className={classes.listItem}
                    divider
                    key={notification.id}
                    onClick={() => {handleOpenNotification(notification)}}
                  >
                    <ListItemAvatar>
                      <Avatar
                        className={notification.isRead ? classes.iconOld : classes.iconNew}
                      >

                        <SvgIcon fontSize="small">
                          <MessageIcon />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={notification.title}
                      primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary' }}
                      secondary={(<div dangerouslySetInnerHTML={{__html:
                            htmlDecorate(notification.body)
                      }} /> )}
                    />
                  </ListItem>
                );
              })}
            </List>
            {/*<Box
              p={1}
              display="flex"
              justifyContent="center"
            >
              <Button
                component={RouterLink}
                size="small"
                to="#"
              >
                Пометить все как "прочитанные"
              </Button>
            </Box>*/}
          </>
        )}
      </Popover> }
    </>
  );
}

export default observer(Notifications);

