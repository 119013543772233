import {decorate} from 'mobx';
import IPageDto from "../../../api/_common/dto/IPageDto";
import {IResponse} from "../../../api/ServerApi";
import BaseListStore, {BaseListStoreDecorators} from "../../Common/BaseListStore";
import IApiRequestOptions from "../../../api/_common/request/IApiRequestOptions";
import IFoodDto from "../../../api/_common/dto/service/IFoodDto";
import FoodRequestOptions from "../../../api/_common/request/FoodRequestOptions";
import FoodApi from "../../../api/ServicesApi/FoodApi";
import DictionaryStore from "../../DictionaryStore";

class FoodListStore extends BaseListStore<IFoodDto, FoodRequestOptions> {

    public getDefaultRequestOptions(): FoodRequestOptions {
        const res = new FoodRequestOptions();
        res.sortField = 'name,asc';
        return res;
    }

    loadDics = async (): Promise<void> => {
        let dictRes = await DictionaryStore.initializeFoodCategories();
        if (!dictRes.success) {
            this.loadingError = dictRes.data;
            return;
        }
        dictRes = await DictionaryStore.initializeFoodKitchenTypes();
        if (!dictRes.success) {
            this.loadingError = dictRes.data;
            return;
        }
    }

    protected async deleteManyRoutine(ids: number[]): Promise<IResponse> {
        return FoodApi.deleteFood(this.getCompanyId(), ids);
    }

    protected async deleteOneRoutine(id: number): Promise<IResponse> {
        return this.deleteManyRoutine([id])
    }

    protected async getListRoutine(apiRequestOptions: IApiRequestOptions, options?: any): Promise<IResponse> {
        return FoodApi.getFoodList(this.getCompanyId(), apiRequestOptions);
    }


  imitateLoadResults = (requestOptions: FoodRequestOptions): IPageDto<IFoodDto> => {
      return  {
          totalElements: 0,
          totalPages: 1,
          size: 0,
          number: 0,
          content: []
      };
  };

  importFromFile = async (file: File): Promise<boolean> => {
      const companyId = this.getRootStore().sessionStore.company.id;
      const res = await FoodApi.importFromFile(companyId, file);
      if (res.success) {
          const res2 = await this.loadList(this.requestOptions);
          return res2.success;
      } else {
          return false;
      }
  };

}

decorate(FoodListStore, BaseListStoreDecorators);

export default FoodListStore;