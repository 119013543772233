import ServerApi, { IResponse } from '../ServerApi';
import IIdNameDto from '../_common/dto/IIdNameDto';
import IIdNameTreeDto from "../_common/dto/IIdNameTreeDto";

export default class DictionaryApi {
  static async getPaymentTypes(): Promise<IResponse<IIdNameDto[]>> {
    return ServerApi.getQuery('paymentTypes');
  }
  static async getEventTypes(): Promise<IResponse<IIdNameDto[]>> {
    return ServerApi.getQuery('eventTypes');
  }
  static async getFoodCategories(): Promise<IResponse<IIdNameTreeDto[]>> {
    return ServerApi.getQuery('food-category');
  }
  static async getGoodsCategories(): Promise<IResponse<IIdNameDto[]>> {
    return ServerApi.getQuery('goods-category');
  }
  static async getFoodKitchenTypes(): Promise<IResponse<IIdNameDto[]>> {
    return ServerApi.getQuery('kitchen');
  }
  static async getOwnershipTypes(): Promise<IResponse<IIdNameDto[]>> {
    return ServerApi.getQuery('ownershipTypes');
  }
  static async getEventFormats(): Promise<IResponse<IIdNameDto[]>> {
    return ServerApi.getQuery('eventFormats');
  }
  static async getCompanyTradeRelationTypes(): Promise<IResponse<IIdNameDto[]>> {
    return ServerApi.getQuery('relationshipTypes');
  }
  static async getTradeTypes(): Promise<IResponse<IIdNameDto[]>> {
    return ServerApi.getQuery('platformTypes');
  }
}
