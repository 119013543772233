import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography
} from "@material-ui/core";
import {observer} from "mobx-react";
import useStore from "../../context/RootStoreContext";
import {makeUserAnonymStr} from "../../api/_common/dto/user/IUserDto";
import PopoverAvatar from "../../components/PopoverAvatar";
import getFilePath from "../../utils/FileUtil";

function InfoDialog() {
  const userInfoModalStore = useStore().userInfoModalStore;
  const {open, user} = userInfoModalStore;

  const handleClose = () => {
    userInfoModalStore.closeModal();
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  if (!user) return (<></>);

  return (
        <Dialog
            maxWidth={"lg"}
            open={open}
            onClose={handleClose}
            scroll={'paper'}
            aria-labelledby="user-info-dialog-title"
            aria-describedby="user-info-dialog-description"
        >
          <DialogTitle id="user-info-dialog-title">Информация о пользователе</DialogTitle>
          <DialogContent>
            <DialogContentText id="user-info-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
            >
              <Box display={'flex'}>
                <Box>
                  <PopoverAvatar src={getFilePath(user.photo)}
                                 anchorOrigin={{
                                   vertical: 'top',
                                   horizontal: 'left',
                                 }}
                                 transformOrigin={{
                                   vertical: 'top',
                                   horizontal: 'right',
                                 }}
                  />
                </Box>
                <Box>
                  <Typography variant="h5" color="textPrimary">
                    {makeUserAnonymStr(user)}
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    <Link href={`mailto:${user.email}`}>{user.email}</Link>
                  </Typography>
                </Box>
              </Box>

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              Закрыть
            </Button>
          </DialogActions>
        </Dialog>
  );
}

export default observer(InfoDialog);