import ILocationDto from './response/ILocationDto';
import ServerApi, { IResponse } from '../ServerApi';

export default class LocationApi {
  static async getLocations(): Promise<IResponse<ILocationDto[]>> {
    return ServerApi.getQuery('locations?level=0');
  }
  static async getLocationsAll(): Promise<IResponse<ILocationDto[]>> {
    return ServerApi.getQuery('locations/all?level=0');
  }
}
