import ILocationDto from 'src/api/LocationApi/response/ILocationDto';
import IIdNameDto from 'src/api/_common/dto/IIdNameDto';
import LocationApi from 'src/api/LocationApi';
import DictionaryApi from 'src/api/DictionaryApi';
import { IResponse } from 'src/api/ServerApi';
import IIdNameTreeDto, {findInTree, makeBidirectionalArr} from "../api/_common/dto/IIdNameTreeDto";
import IIdDto from "../api/_common/dto/IIdDto";
import IFoodDto from "../api/_common/dto/service/IFoodDto";

class DictionaryStore {
  locations: ILocationDto[];
  locationsAll: ILocationDto[];
  paymentTypes: IIdNameDto[];
  eventTypes: IIdNameDto[];
  foodCategories: IIdNameTreeDto[];
  goodsCategories: IIdNameTreeDto[];
  foodKitchenTypes: IIdNameDto[];
  ownershipTypes: IIdNameDto[];
  eventFormats: IIdNameDto[];
  tradeTypes: IIdNameDto[];
  companyTradeRelationTypes: IIdNameDto[];

  // ьолько активные
  initializeLocations = async (): Promise<IResponse> => {
    if (this.locations) return { success: true };
    const res = await LocationApi.getLocations();
    if (res.success) {
      makeBidirectionalArr(res.data);
      this.locations = res.data;
    }
    return res;
  };

  initializeLocationsAll = async (): Promise<IResponse> => {
    if (this.locationsAll) return { success: true };
    const res = await LocationApi.getLocationsAll();
    if (res.success) {
      makeBidirectionalArr(res.data);
      this.locationsAll = res.data;
    }
    return res;
  };

  initializePaymentTypes = async (): Promise<IResponse> => {
    if (this.paymentTypes) return { success: true };
    const res = await DictionaryApi.getPaymentTypes();
    if (res.success) this.paymentTypes = res.data;
    return res;
  };

  initializeEventTypes = async (): Promise<IResponse> => {
    if (this.eventTypes) return { success: true };
    const res = await DictionaryApi.getEventTypes();
    if (res.success) this.eventTypes = res.data;
    return res;
  };

  initializeFoodCategories = async (): Promise<IResponse> => {
    if (this.foodCategories) return { success: true };
    const res = await DictionaryApi.getFoodCategories();
    if (res.success) {
      makeBidirectionalArr(res.data);
      this.foodCategories = res.data;
    }
    return res;
  };

  initializeGoodsCategories = async (): Promise<IResponse> => {
    if (this.goodsCategories) return { success: true };
    const res = await DictionaryApi.getGoodsCategories();
    if (res.success) {
      this.goodsCategories = res.data;
    }
    return res;
  };

  initializeFoodKitchenTypes = async (): Promise<IResponse> => {
    if (this.foodKitchenTypes) return { success: true };
    const res = await DictionaryApi.getFoodKitchenTypes();
    if (res.success) this.foodKitchenTypes = res.data;
    return res;
  };

  initializeOwnershipTypes = async (): Promise<IResponse> => {
    if (this.ownershipTypes) return { success: true };
    const res = await DictionaryApi.getOwnershipTypes();
    if (res.success) this.ownershipTypes = res.data;
    return res;
  };

  initializeEventFormats = async (): Promise<IResponse> => {
    if (this.eventFormats) return { success: true };
    const res = await DictionaryApi.getEventFormats();
    if (res.success) this.eventFormats = res.data;
    return res;
  };

  initializeCompanyTradeRelationTypes = async (): Promise<IResponse> => {
    if (this.companyTradeRelationTypes) return { success: true };
    const res = await DictionaryApi.getCompanyTradeRelationTypes();
    if (res.success) this.companyTradeRelationTypes = res.data;
    return res;
  };

  initializeTradeTypes = async (): Promise<IResponse> => {
    if (this.tradeTypes) return { success: true };
    const res = await DictionaryApi.getTradeTypes();
    if (res.success) this.tradeTypes = res.data;
    return res;
  };

  // ------

  makeFullFoodCategoryName = (dto: IFoodDto): string => {
    if (!dto || !dto.category) return '';
    return this.makeFullNameFromTree(this.foodCategories, dto.category.id);
  }

  makeFullLocationName = (dto: ILocationDto): string => {
    if (!dto) return '';
    return this.makeFullNameFromTree(this.locations, dto.id);
  }

  private makeFullNameFromTree(roots: IIdNameTreeDto[], id: number): string {
    if (!roots || !id) return '';
    for (var i = 0; i < roots.length; i++) {
      let node = findInTree(roots[i], id);
      if (node) {
        const res = [node.name];
        while (node.parent) {
          res.unshift(node.parent.name);
          node = node.parent;
        }
        return res.join(', ');
      }
    }
  }

}

export default new DictionaryStore();
