export type OrderStatusEnum = 'open' | 'await' | 'processing' | 'ready' | 'canceled' | 'deliver' | 'closed';

export const OrderStatusCollection: Array<{
  status: OrderStatusEnum,
  label: string,
  color: string,
  actionLabel: string;
}> = [
  { status: 'open', label: 'Создан', color: 'success', actionLabel: 'Открыть' },
  { status: 'await', label: "Ожидает подтверждения", color: 'warning', actionLabel: 'Ждать оплаты' },
  { status: 'processing', label: 'Подтвержен/Готовится', color: 'primary', actionLabel: 'Подтвердить' },
  { status: 'ready', label: "Готов к выдаче", color: 'primary', actionLabel: 'На выдачу' },
  { status: 'deliver', label: "Передан в доставку", color: 'primary', actionLabel: 'Передать в доставку' },
  { status: 'canceled', label: 'Отменен', color: 'error', actionLabel: 'Отменить' },
  { status: 'closed', label: 'Закрыт', color: '', actionLabel: 'Закрыть' },
];

// все кроме 'open'
export const orderEnumPartnerState = 'await-processing-ready-canceled-deliver-closed';
