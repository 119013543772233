import {action, decorate, observable} from 'mobx';

import RootStoreModel from '../../_root/RootStoreModel';
import ProposalsApi from 'src/api/ProposalsApi';
import {IResponse} from 'src/api/ServerApi';
import IProposalDto from "../../../api/_common/dto/proposal/IProposalDto";
import IPageDto from "../../../api/_common/dto/IPageDto";
import IQuestionDto from "../../../api/_common/dto/IQuestionDto";
import IQuestionCommentDto from "../../../api/_common/dto/IQuestionCommentDto";
import IOfferDto from "../../../api/_common/dto/offer/IOfferDto";
import IOfferForGridDto from "../../../api/_common/dto/offer/IOfferForGridDto";
import IProposalOfferDto from "../../../api/_common/dto/offer/IProposalOfferDto";

class ProposalProfileStore extends RootStoreModel {
  loadingError: string = null;
  proposal: IProposalDto;
  questionsPage: IPageDto<IQuestionDto>;
  questionsWindowState: {};
  id: number;
  attachedOffers: IProposalOfferDto[] = [];

  isLoading = false;

  initialize = async (id?: number): Promise<void> => {
    this.loadingError = null;
    this.id = id;
    if (!id) {
      this.proposal = null;
      return;
    }

    const resProfile = await ProposalsApi.getProposal(id);
    if (resProfile.success) {
      this.proposal = resProfile.data;
    } else {
      this.loadingError = resProfile.message;
      return;
    }

    const resQuestions = await ProposalsApi.getQuestions(id, {sort: 'createdAt,desc'});
    if (resQuestions.success) {
      this.questionsPage = resQuestions.data;
      this.questionsWindowState = {};
    } else {
      this.loadingError = resQuestions.message;
      return;
    }

    if (this.questionsPage && this.questionsPage.content && this.questionsPage.content.length > 0) {
      const resComments = await this.openQuestion(this.questionsPage.content[0].id);
      if (!resComments.success) {
        this.loadingError = resQuestions.message;
        return;
      }
    }
  };

  private insertQuestion = (q: IQuestionDto) => {
    this.performAsAction(() => {
      this.questionsPage.content.unshift(q);
    });
  };

  createQuestion = async(title: string, body: string): Promise<IResponse<IQuestionDto>> => {
    const res = await ProposalsApi.createQuestion(this.id, {title: title, body: body});
    if (res.success) {
      this.insertQuestion(res.data);
    }
    return res;
  };

  private insertComment = (questionId: number, c: IQuestionCommentDto) => {
    this.performAsAction(() => {
      this.questionsPage.content.forEach(q => {
        if (q.id === questionId) {
          if (!q.comments) q.comments = {content: [], size:0, number:0, totalElements:0, totalPages:0};
          q.comments.content.unshift(c);
        }
      })
    })
  };

  createComment = async(questionId: number, body: string): Promise<IResponse<IQuestionCommentDto>> => {
    const res = await ProposalsApi.createQuestionComment(this.id, questionId, {body: body});
    if (res.success) {
      this.insertComment(questionId, res.data);
    }
    return res;
  };

  private setQuestionComments = (questionId: number, page: IPageDto<IQuestionCommentDto>) => {
    this.performAsAction(() => {
      this.questionsPage.content.forEach(q => {
        if (q.id == questionId) {
          q.comments = page;
        }
      })
    });
  };

  openQuestion = async(questionId: number): Promise<IResponse<IPageDto<IQuestionCommentDto>>> => {
    const res = await ProposalsApi. getQuestionComments(this.id, questionId, {sort: 'createdAt,desc'});
    if (res.success) {
      this.setQuestionComments(questionId, res.data);
      this.questionsWindowState[questionId] = true;
    }
    return res;
  };

  closeQuestion = (questionId: number) => {
    this.questionsWindowState[questionId] = false;
  };

  loadMoreQuestions = async(): Promise<IResponse<IPageDto<IQuestionDto>>> => {
    const newPageNumber = (this.questionsPage.number || 0) + 1 + 1;
    const resQuestions = await ProposalsApi.getQuestions(this.id, {pageNumber: newPageNumber, sort: 'createdAt,desc'});
    if (resQuestions.success) {
      this.questionsPage.content = this.questionsPage.content.concat(resQuestions.data.content);
      this.questionsPage.number = newPageNumber;
    } else {
      this.loadingError = resQuestions.message;
    }
    return resQuestions;
  };

  loadMoreComments = async(questionId: number): Promise<IResponse<IPageDto<IQuestionCommentDto>>> => {
    const q = this.questionsPage.content.filter(x => x.id === questionId)[0];
    const newPageNumber = (q.comments.number || 0) + 1 + 1;
    const resComments = await ProposalsApi.getQuestionComments(this.id, questionId, {pageNumber: newPageNumber, sort: 'createdAt,desc'});
    if (resComments.success) {
      q.comments.content = q.comments.content.concat(resComments.data.content);
      q.comments.number = newPageNumber;
    } else {
      this.loadingError = resComments.message;
    }
    return resComments;
  };

  loadOffers = async (): Promise<IResponse> => {
    this.loadingError = null;
    let resp;
    let hasMore = true;
    let pageNumber = 1;
    const pageSize = 25;
    this.attachedOffers = [];
    do {
      resp = await ProposalsApi.getProposalOffers(this.id, {pageSize: pageSize, pageNumber: pageNumber});
      if (!resp.success) {
        this.loadingError = resp.message;
        return resp;
      }
      this.attachedOffers = this.attachedOffers.concat(resp.data.content);
      hasMore = resp.data.totalPages > pageNumber;
      pageNumber++;
    } while (hasMore);
    return resp;
  }

  offerIsAttached(offerId: number): boolean {
    return !!(this.attachedOffers && this.attachedOffers.find(x => (x.offer && x.offer.id === offerId) ));
  }

  attachOffer = async (offerDto: IOfferForGridDto): Promise<IResponse> => {
    this.isLoading = true;
    let resp = await ProposalsApi.sendOffer(this.id, offerDto.id);
    if (resp.success) {
      resp = await this.loadOffers();
      resp = await this.root().offerListStore.load(this.root().offerListStore.requestOptions);
    }
    this.loadingError = resp.message;
    this.isLoading = false;
    return resp;
  }
}

decorate(ProposalProfileStore, {
  loadingError: observable,
  proposal: observable,
  questionsPage: observable,
  questionsWindowState: observable,
  attachedOffers: observable,
  isLoading: observable,
  openQuestion: action,
  closeQuestion: action,
  loadOffers: action,
  attachOffer: action,
});

export default ProposalProfileStore;
