import ServerApi, { IResponse } from '../ServerApi';
import IPageDto from '../_common/dto/IPageDto';
import IApiRequestOptions from '../_common/request/IApiRequestOptions';
import IFoodDto from '../_common/dto/service/IFoodDto';
import ICreateEditFoodDto from '../_common/dto/service/ICreateEditFoodDto';
import IPhotoWithDescriptionDto from '../_common/dto/IPhotoWithDescriptionDto';
import axios from "../../utils/axios";

export default class FoodApi {

  static async getFoodList(
    companyId: number,
    apiRequestOptions: IApiRequestOptions
  ): Promise<IResponse<IPageDto<IFoodDto>>> {
    return ServerApi.getQuery(`food/${companyId}`, {
      params: apiRequestOptions
    });
  }
  static async deleteFood(
    companyId: number,
    ids: number[]
  ): Promise<IResponse<any>> {
    return ServerApi.postQuery('food/delete', {
      companyId: companyId,
      ids: ids
    });
  }
  static async deleteFoodFromView(
    companyId: number,
    id: number
  ): Promise<IResponse> {
    return this.deleteFood(companyId, [id]);
    //return ServerApi.deleteQuery(`food/delete?companyId=${companyId}&id=${id}`);
  }

  static async getFood(id: number): Promise<IResponse<IFoodDto>> {
    return ServerApi.postQuery(`food/get?id=${id}`, {});
  }

  static async saveFood(
    body: ICreateEditFoodDto
  ): Promise<IResponse<IFoodDto>> {
    return ServerApi.postQuery('food/save', body);
  }

  static async getFoodImages(
    platformId: number
  ): Promise<IResponse<IPhotoWithDescriptionDto[]>> {
    return ServerApi.getQuery(`food/${platformId}/images`);
  }

  static async addFoodImage(
    foodId: number,
    file: File
  ): Promise<IResponse<IPhotoWithDescriptionDto>> {
    return ServerApi.uploadFileQuery(`food/${foodId}/images`, file);
  }

  static async deleteFoodImage(
    foodId: number,
    imageId: number
  ): Promise<IResponse<IPageDto<IPhotoWithDescriptionDto>>> {
    return ServerApi.deleteQuery(`food/${foodId}/images/${imageId}`);
  }

  static async markFoodImageAsMain(
    foodId: number,
    imageId: number
  ): Promise<IResponse<IPhotoWithDescriptionDto>> {
    return ServerApi.postQuery(
      `food/${foodId}/images/${imageId}/makeMain`,
      {}
    );
  }

  static async changeFoodImageDescription(
    foodId: number,
    imageId: number,
    description: string
  ): Promise<IResponse<IPhotoWithDescriptionDto>> {
    return ServerApi.postQuery(
      `food/${foodId}/images/${imageId}/description`,
      { description }
    );
  }

  static async importFromFile(companyId: number, file: File) {
    const fd = new FormData();
    fd.append('companyId', companyId+'');
    fd.append('file', file);
    return ServerApi.postQuery('food/importFromFile', fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    });
  }
}
