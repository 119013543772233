import ServerApi, {IResponse} from '../ServerApi';
import IPageDto from '../_common/dto/IPageDto';
import IUserForGridDto from "../_common/dto/user/IUserForGridDto";
import IApiRequestOptions from "../_common/request/IApiRequestOptions";

export default class UsersApi {
  static async getUserList(apiRequestOptions: IApiRequestOptions): Promise<IResponse<IPageDto<IUserForGridDto>>> {
    return ServerApi.getQuery('users', {params: apiRequestOptions});
  }
  static async deleteOne(id: number): Promise<IResponse<any>> {
    return ServerApi.deleteQuery(`users/${id}`);
  }
  static async deleteMany(ids: number[]): Promise<IResponse<any>> {
    return ServerApi.postQuery('users/removeMultiple', null, {
      params: { userIds: ids && ids.join(',') },
      headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
    }  );
  }

  static async disableUser(id: number): Promise<IResponse<any>> {
    return ServerApi.postQuery(`users/${id}/disable`, {});
  }
  static async enableUser(id: number): Promise<IResponse<any>> {
    return ServerApi.postQuery(`users/${id}/enable`, {});
  }

}
