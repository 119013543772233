import RootStoreModel from 'src/store/_root/RootStoreModel';
import {action, decorate, observable} from 'mobx';
import IOrderDto from "../../../api/_common/dto/order/IOrderDto";
import OrdersApi from "../../../api/OrdersApi";
import {OrderStatusEnum} from "../../../api/_common/enum/OrderStatusEnum";
import {IResponse} from "../../../api/ServerApi";
import ICommentDto from "../../../api/_common/dto/ICommentDto";

class OrderProfileStore extends RootStoreModel {
  isLoading: boolean;
  loadingError: string = null;
  order: IOrderDto;
  id: number;
  availableStates: OrderStatusEnum[] = [];
  comments: ICommentDto[] = [];
  editingComment: ICommentDto;

  initialize = async (id?: number): Promise<void> => {
    this.loadingError = null;
    this.isLoading = true;
    this.id = id;

    const resp = await OrdersApi.getOrder(this.getCompanyId(), id);
    if (!resp.success) {
      this.loadingError = resp.message || 'Не удалось загрузить заказ';
      return;
    }
    this.order = resp.data;

    const resp2 = await this.getStates(/*this.order.state*/);

    this.isLoading = false;
  };

  getStates = async (): Promise<IResponse> => {
    this.loadingError = null;
    this.isLoading = true;
    const resp = await OrdersApi.getStates(this.id);
    if (!resp.success) {
      this.loadingError = resp.message || 'Не удалось получить возможные статусы';
    } else {
      this.availableStates = resp.data.map(x => x.name as OrderStatusEnum);
    }
    this.isLoading = false;
    return resp;
  }

  changeStatus = async (newStatus: OrderStatusEnum): Promise<IResponse> => {
    this.isLoading = true;
    this.loadingError = null;
    const oldState = this.order.state;
    this.order.state = undefined;
    const resp = await OrdersApi.changeStatus(this.id, newStatus);
    if (resp.success) {
      this.order.state = newStatus;
      //this.order = resp.data;
      //console.log('GOT STATE: ' + this.order.state);
      await this.getStates(/*newStatus*/);
    } else {
      this.loadingError = 'Не удалось изменить статус';
      this.order.state = oldState;
    }
    this.isLoading = false;
    return resp;
  }

  protected getCompany = () => {
    return this.getRootStore().sessionStore.company;
  }
  protected getCompanyId = () => {
    const company = this.getCompany();
    return company && company.id;
  }

  setCommentForEditing = (comment: ICommentDto) => {
    this.editingComment = comment;
  }

  loadComments = async (): Promise<IResponse> => {
    this.isLoading = true;
    this.loadingError = null;
    const resp = await OrdersApi.getComments(this.id);
    if (!resp.success) {
      this.loadingError = resp.message;
    } else {
      this.comments = resp.data.sort((x1, x2) => x2.id - x1.id);
    }
    this.isLoading = false;
    return resp;
  }

  saveComment = async (comment: ICommentDto): Promise<IResponse> => {
    this.isLoading = true;
    this.loadingError = null;
    const resp = await OrdersApi.saveComment(this.id, comment);
    if (!resp.success) {
      this.loadingError = resp.message;
    } else {
      //this.comments.unshift(resp.data);
      await this.loadComments();
    }
    this.isLoading = false;
    return resp;
  }

  deleteComment = async (comment: ICommentDto): Promise<IResponse> => {
    this.isLoading = true;
    this.loadingError = null;
    const resp = await OrdersApi.deleteComment(this.id, comment.id);
    if (!resp.success) {
      this.loadingError = resp.message;
    } else {
      //this.comments = this.comments. filter(x => x.id !== comment.id);
      await this.loadComments();
    }
    this.isLoading = false;
    return resp;
  }
}

decorate(OrderProfileStore, {
  isLoading: observable,
  loadingError: observable,
  order: observable,
  availableStates: observable,
  comments: observable,
  editingComment: observable,
  changeStatus: action,
  getStates: action,
  loadComments: action,
  saveComment: action,
  deleteComment: action,
  setCommentForEditing: action,
});

export default OrderProfileStore;
