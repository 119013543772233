import {IResponse} from 'src/api/ServerApi';
import RootStoreModel from 'src/store/_root/RootStoreModel';
import IGoodsDto from 'src/api/_common/dto/service/IGoodsDto';
import GoodsProfileFilesTabViewModel from './tab/GoodsProfileFilesTabViewModel';
import DictionaryStore from 'src/store/DictionaryStore';
import GoodsApi from 'src/api/ServicesApi/GoodsApi';
import ICreateEditGoodsDto from 'src/api/_common/dto/service/ICreateEditGoodsDto';
import {action, computed, decorate, observable} from 'mobx';

interface ISaveEntityResponse extends IResponse {
  id: number;
  isAdd: boolean;
}

class GoodsProfileStore extends RootStoreModel {
  loadingError: string = null;
  goods: IGoodsDto;
  id: number;

  images: GoodsProfileFilesTabViewModel;

  procesing = false;

  get mainImageId(): number {
    return (this.goods && this.goods.mainImage && this.goods.mainImage.id) || null;
  }

  initialize = async (id?: number): Promise<void> => {
    this.loadingError = null;
    this.id = id;

    let dictRes = await DictionaryStore.initializeGoodsCategories();
    if (!dictRes.success) {
      this.loadingError = dictRes.data;
      return;
    }

    if (!id) {
      this.goods = null;
      return;
    }

    const foodRes = await GoodsApi.getGoods(id);
    if (!foodRes.success) {
      this.loadingError = foodRes.message || 'Не удалось загрузить услугу';
      return;
    }
    this.goods = foodRes.data;
    this.images = new GoodsProfileFilesTabViewModel(this.root, this.goods.id);
  };

  save = async (body: ICreateEditGoodsDto): Promise<ISaveEntityResponse> => {
    const isAdd = this.goods ? false : true;
    if (this.goods) body.id = this.goods.id;
    const res = await GoodsApi.saveGoods(body);
    if (res.success) {
      this.goods = res.data;
    }
    return { ...res, id: res.data && res.data.id, isAdd };
  };

  editGoodsMainImage = async (id: number): Promise<IResponse> => {
    if (this.procesing) return;
    this.procesing = true;
    const goodsId = this.goods.id;
    const res = await GoodsApi.markGoodsImageAsMain(goodsId, id);
    this.procesing = false;
    if (res.success && goodsId === this.goods.id) {
      this.goods.mainImage = res.data;
    }
    return res;
  };
}

decorate(GoodsProfileStore, {
  loadingError: observable,
  goods: observable,
  mainImageId: computed,
  save: action,
  editGoodsMainImage: action
});

export default GoodsProfileStore;
