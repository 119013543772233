import RootStoreModel from "src/store/_root/RootStoreModel";
import IRootStore from "src/store/_root/type";

export default abstract class BaseTabViewModel extends RootStoreModel {
  #initialized = false;
  #id: number;
  loadingError: string;

  protected set initialized(initialized: boolean){
    this.#initialized = initialized;
  }
  protected get id(): number {
    return this.#id;
  }

  constructor(root: () => IRootStore, id: number) {
    super(root);
    this.#id = id;
  }

  async initialize(): Promise<void> {
    if (this.#initialized) return;
    await this.initializeTab();
  }

  protected abstract async initializeTab(): Promise<void>;
}