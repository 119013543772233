import BaseTabViewModel from 'src/store/Common/BaseTabViewModel';
import IPhotoWithDescriptionDto from 'src/api/_common/dto/IPhotoWithDescriptionDto';
import { IDropdownFileItem } from 'src/components/common-form/FilesDropdownForm/type';
import FoodApi from 'src/api/ServicesApi/FoodApi';
import { IResponse } from 'src/api/ServerApi';
import { decorate, observable, computed, action } from 'mobx';

class FoodProfileFilesTabViewModel extends BaseTabViewModel {
  foodImages: IPhotoWithDescriptionDto[] = [];
  processing = false;

  get images(): IDropdownFileItem[] {
    if (!this.foodImages) return [];
    return this.foodImages.map(x => ({
      file: x.fileInfo,
      description: x.description,
      id: x.id
    }));
  }

  protected async initializeTab(): Promise<void> {
    const res = await FoodApi.getFoodImages(this.id);
    if (res.success) {
      this.foodImages = res.data || [];
      this.initialized = true;
    } else this.loadingError = res.message;
  }

  addImage = async (file: File): Promise<IResponse> => {
    if (this.processing) return;
    this.processing = true;
    const res = await FoodApi.addFoodImage(this.id, file);
    this.processing = false;
    if (res.success) this.foodImages.push(res.data);
    return res;
  };

  deleteImage = async (id: number): Promise<IResponse> => {
    if (this.processing) return;
    this.processing = true;
    const res = await FoodApi.deleteFoodImage(this.id, id);
    this.processing = false;
    if (res.success) {
      this.foodImages = this.foodImages.filter(x => x.id !== id);
    }
    return res;
  };

  changeImageDescription = async (
    id: number,
    description: string
  ): Promise<IResponse> => {
    if (this.processing) return;
    this.processing = true;
    const res = await FoodApi.changeFoodImageDescription(
      this.id,
      id,
      description.trim()
    );
    this.processing = false;
    if (res.success) {
      this.foodImages.find(x => x.id === id).description = res.data.description;
    }
    return res;
  };
}

decorate(FoodProfileFilesTabViewModel, {
  foodImages: observable,
  images: computed,
  addImage: action,
  deleteImage: action,
  changeImageDescription: action
});

export default FoodProfileFilesTabViewModel;
