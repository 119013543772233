import ServerApi, {IResponse} from '../ServerApi';
import IUserCompanyDto from '../_common/dto/company/IUserCompanyDto';
import IPageDto from '../_common/dto/IPageDto';
import ICompanyDto from '../_common/dto/company/ICompanyDto';
import IFileDto from '../_common/dto/IFileDto';
import IEditCompanyRequestDto from './request/IEditCompanyRequesDtot';
import IApiRequestOptions from '../_common/request/IApiRequestOptions';
import IEditWorkTimeRequestDto from '../_common/request/IEditWorkTimeRequestDto';
import qs from 'qs';
import ITradeReviewDto from "../_common/dto/trade/ITradeReviewDto";

export default class CompaniesApi {
  static async getCompanyList(
    apiRequestOptions: IApiRequestOptions
  ): Promise<IResponse<IPageDto<ICompanyDto>>> {
    return ServerApi.getQuery(`companies`, { params: apiRequestOptions });
  }
  static async deleteOne(id: number, description: string): Promise<IResponse<any>> {
    return ServerApi.postQuery(`companies/${id}/remove`, { params: {description: description} });
  }
  static async deleteMany(ids: number[]): Promise<IResponse<any>> {
    return ServerApi.postQuery('companies/removeMultiple', null, {
      params: {
        companyIds: ids && ids.join(',')
      },
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    }  );
  }
  static async disableCompany(id: number): Promise<IResponse<any>> {
    return ServerApi.postQuery(`companies/${id}/disable`, {});
  }
  static async enableCompany(id: number): Promise<IResponse<any>> {
    return ServerApi.postQuery(`companies/${id}/enable`, {});
  }

  static async getCompany(id: number): Promise<IResponse<ICompanyDto>> {
    return ServerApi.getQuery(`companies/${id}`);
  }
  static async getCompanyWithMember(
    id: number
  ): Promise<IResponse<ICompanyDto | IUserCompanyDto>> {
    return ServerApi.postQuery(`companies/${id}/checkMember`, {});
  }
  static async getCompanyMembers(
    id: number
  ): Promise<IResponse<IPageDto<IUserCompanyDto>>> {
    return ServerApi.getQuery(`companies/${id}/members`);
  }
  static async changeCompany(
    companyId: number,
    body: IEditCompanyRequestDto
  ): Promise<IResponse<ICompanyDto>> {
    return ServerApi.postQuery(`companies/${companyId}/settings`, body);
  }
  static async changeCompanyBackground(
    companyId: number,
    photo: File
  ): Promise<IResponse<IFileDto>> {
    const fd = new FormData();
    fd.append('file', photo);
    return ServerApi.postQuery(`companies/${companyId}/background`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
  static async changeCompanyIcon(
    companyId: number,
    photo: File
  ): Promise<IResponse<IFileDto>> {
    const fd = new FormData();
    fd.append('file', photo);
    return ServerApi.postQuery(`companies/${companyId}/icon`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
  static async changeCompanyDescription(
    companyId: number,
    description: string
  ): Promise<IResponse<ICompanyDto>> {
    return ServerApi.postQuery(`companies/${companyId}/description`, {
      description
    });
  }
  static async changeCompanyWorkTime(
    companyId: number,
    body: IEditWorkTimeRequestDto
  ): Promise<IResponse<ICompanyDto>> {
    return ServerApi.postQuery(`companies/${companyId}/workTimes`, body);
  }
  static async inviteUser(
    companyId: number,
    email: string
  ): Promise<IResponse<IUserCompanyDto>> {
    return ServerApi.postQuery(`companies/${companyId}/members/newUser`, {
      email
    });
  }

  static async approveInvite(token: string): Promise<IResponse> {
    return ServerApi.postQuery('companies/approveInvite', { token });
  }

  static async approveInviteById(companyId: number): Promise<IResponse> {
    return ServerApi.postQuery(`companies/${companyId}/approveInvite`, null);
  }

  static async declineInviteById(companyId: number): Promise<IResponse> {
    return ServerApi.postQuery(`companies/${companyId}/declineInvite`, null);
  }

  static async removeMember(companyId: number, memberId: number): Promise<IResponse> {
        return ServerApi.deleteQuery(`companies/${companyId}/members/${memberId}`);
    }

  static async editServiceField(companyId: number, text: string): Promise<IResponse> {
    return ServerApi.postQuery(`companies/${companyId}/updateServiceField`, null, {
      params: {
        text: text
      },
      headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
    });
  }
  static async getServiceField(companyId: number): Promise<IResponse> {
    return ServerApi.getQuery(`companies/${companyId}/getServiceField`);
  }

  static async setMemberAsContact(companyId: number, userId: number, isContact: boolean) {
    const url = isContact ? `companies/${companyId}/members/${userId}/makeContactPerson`
        : `companies/${companyId}/clearContactPerson` ;
    return ServerApi.postQuery(url, null);
  }

  static async getReviews(companyIdId: number, apiRequestOptions?: IApiRequestOptions): Promise<IResponse<IPageDto<ITradeReviewDto>>> {
    return ServerApi.getQuery(`companies/${companyIdId}/reviews`, {params: apiRequestOptions});
  }
}
